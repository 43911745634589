<nz-card [nzActions]="[actionClose, actionActive]">
    <p> <strong> Sicuro di voler ri-attivare questo abbonamento?</strong></p>
    <p>L'abbonamento verrà ri-attivato senza inviare nessuna conferma al cliente!</p>
    <p>Questa azione è permanente.</p>
</nz-card>
<ng-template #actionClose>
    <button type="button" nz-button nzSize="large" (click)="onClose()">Annulla</button>
</ng-template>
<ng-template #actionActive>
    <button nz-button type="button" [nzSize]="'large'" class="home-button" [nzLoading]="loading" (click)="onAttiva()">Attiva</button>
</ng-template>