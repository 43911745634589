<ng-template #categorieTitle>        
    <div class="flex lg:flex-row flex-col gap-4 mb-4 items-center justify-between">
        <div class="lg:w-2/3 w-full">
            <h3 class="text-white mb-0 text-xl font-semibold">Categorie</h3>
        </div>
        <div class="lg:w-1/3 w-full">
            <button nz-button nzType="primary" nzSize="large" (click)="onAdd()" class="home-button w-full">Aggiungi categoria</button>
        </div>
    </div>            
    <input nz-input placeholder="Cerca Titolo categoria" nzSize="large" [(ngModel)]="titoloFilter" />    
</ng-template>
<nz-card [nzTitle]="categorieTitle">    
    <nz-table #categorieTable nzShowPagination class="tabella"
        [nzTotal]="(categorie | searchTitoloCategoria: titoloFilter).length"
        nzShowSizeChanger [nzPageSize]="10"
        [nzPageSizeOptions]="[5, 10, 25, 50, 100, 150, 200, 250, 300, 350, 400, 500, 750, 1000]"
        [nzData]="categorie | searchTitoloCategoria: titoloFilter"
        [nzBordered]="true" [nzNoResult]="'Nessuna categoria trovata'">
        <thead>
            <tr>
                <th [nzSortFn]="onOrdinamentoTitolo">Titolo</th>
                <th [nzSortFn]="onOrdinamentoDescrizione" class="text-start">Descrizione</th>                        
                <th class="!text-center">Azioni</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let categoria of categorieTable.data | searchTitoloCategoria: titoloFilter">
                <td>{{categoria.titolo}}</td>
                <td class="text-start">{{categoria.descrizione}}</td>                        
                <td class="text-center">
                    <nz-button-group>                                
                        <button nz-button nz-dropdown [nzDropdownMenu]="menu" nzPlacement="bottomRight">
                            <span nz-icon nzType="menu"></span>
                        </button>
                  </nz-button-group>
                  <nz-dropdown-menu #menu="nzDropdownMenu">
                    <ul nz-menu>
                      <li nz-menu-item class="!p-4" (click)="onEdit(categoria.id)">Modifica categoria</li>                              
                      <li nz-menu-item class="!p-4" (click)="onElimina(categoria.id)" *ngIf="categoria.id != 1">Elimina categoria</li>
                    </ul>
                  </nz-dropdown-menu>
                </td>
            </tr>
        </tbody>
    </nz-table>                
</nz-card>