import { Component, inject } from '@angular/core';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';
import { ApiService } from '../../../../services/api.service';

@Component({
  selector: 'app-elimina-immaggini-news',
  templateUrl: './elimina-immaggini-news.component.html',
  styleUrls: ['./elimina-immaggini-news.component.css']
})
export class EliminaImmagginiNewsComponent {

  constructor(private apiService: ApiService) { }

  loading: boolean = false;
  
  readonly #modal = inject(NzModalRef);
  readonly nzModalData = inject(NZ_MODAL_DATA);  

  onElimina() {
    this.loading = true;

    // Elimino la copertina della news
    this.apiService.deleteFile('copertina', 'news', this.nzModalData.nome_immagine!, '', this.nzModalData.id_news!).subscribe({
      next: (data) => this.apiService.setNews(),
      error: (err) => {
        this.loading = false
        this.apiService.error(err.error.message)
      },
      complete: () => {
        this.loading = false;
        this.#modal.destroy(true);
      }
    })

  }

  onClose() {
    this.#modal.destroy(false);
  }

}