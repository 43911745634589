<ng-template #newsTitle>        
    <div class="flex lg:flex-row flex-col items-center justify-between gap-4">
        <div class="lg:w-2/3 w-full">
            <h3 class="text-white mb-0 font-semibold text-xl">News</h3>
        </div>
        <div class="lg:w-1/3 w-full">
            <button nz-button nzType="primary" nzSize="large" (click)="onAdd()" class="home-button w-full">Aggiungi news</button>
        </div>
    </div>        
    <div class="flex lg:flex-row flex-col gap-4 mt-4">
        <div class="lg:w-1/2 w-full">
            <input nz-input placeholder="Cerca Titolo news" nzSize="large" [(ngModel)]="titoloFilter" />
        </div>              
        <div class="lg:w-1/2 w-full">
            <input nz-input placeholder="Cerca Data news" nzSize="large" [(ngModel)]="dataFilter" />                
        </div>                                   
    </div>
</ng-template>
<nz-card [nzTitle]="newsTitle">    
    <nz-table #newsTable nzShowPagination class="tabella"
        [nzTotal]="(notizie | searchTitoloNews: titoloFilter | searchDataNews: dataFilter).length"
        nzShowSizeChanger [nzPageSize]="10"
        [nzPageSizeOptions]="[5, 10, 25, 50, 100, 150, 200, 250, 300, 350, 400, 500, 750, 1000]"
        [nzData]="notizie | searchTitoloNews: titoloFilter | searchDataNews: dataFilter"
        [nzBordered]="true" [nzNoResult]="'Nessuna news trovata'">
        <thead>
            <tr>
                <th [nzSortFn]="onOrdinamentoTitolo">Titolo</th>
                <th [nzSortFn]="onOrdinamentoData" class="text-start">Data</th>                                                
                <th class="!text-center">Azioni</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let notizia of newsTable.data| searchTitoloNews: titoloFilter | searchDataNews: dataFilter">
                <td>{{notizia.titolo}}</td>
                <td class="text-start">{{notizia.data_creazione | date: 'dd/MM/YYYY'}}</td>                                                
                <td class="text-center">
                    <nz-button-group>                                
                        <button nz-button nz-dropdown [nzDropdownMenu]="menu" nzPlacement="bottomRight">
                            <span nz-icon nzType="menu"></span>
                        </button>
                  </nz-button-group>
                  <nz-dropdown-menu #menu="nzDropdownMenu">
                    <ul nz-menu>
                      <li nz-menu-item class="!p-4" (click)="onEdit(notizia.id)">Modifica news</li>                              
                      <li nz-menu-item class="!p-4" (click)="onEliminaImmagine(notizia.copertina, notizia.id)">Elimina copertina news</li>
                      <li nz-menu-item class="!p-4" (click)="onElimina(notizia.id)">Elimina news</li>
                    </ul>
                  </nz-dropdown-menu>
                </td>
            </tr>
        </tbody>
    </nz-table>                
</nz-card>