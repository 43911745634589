"use strict";
module.exports = function prettierBytes(num) {
    if (typeof num !== 'number' || Number.isNaN(num)) {
        throw new TypeError(`Expected a number, got ${typeof num}`);
    }
    const neg = num < 0;
    const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    if (neg) {
        num = -num;
    }
    if (num < 1) {
        return `${(neg ? '-' : '') + num} B`;
    }
    const exponent = Math.min(Math.floor(Math.log(num) / Math.log(1024)), units.length - 1);
    num = Number(num / 1024 ** exponent);
    const unit = units[exponent];
    if (num >= 10 || num % 1 === 0) {
        return `${(neg ? '-' : '') + num.toFixed(0)} ${unit}`;
    }
    return `${(neg ? '-' : '') + num.toFixed(1)} ${unit}`;
};
