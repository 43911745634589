import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { EliminaClienteBackofficeComponent } from './elimina-cliente-backoffice/elimina-cliente-backoffice.component';
import { ConfermaClientiBackofficeComponent } from './conferma-clienti-backoffice/conferma-clienti-backoffice.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ApiService } from '../../../services/api.service';
import { Cliente } from '../../../shared/interfacce.model';

@Component({
  selector: 'app-clienti-backoffice',
  templateUrl: './clienti-backoffice.component.html',
  styleUrls: ['./clienti-backoffice.component.css']
})
export class ClientiBackofficeComponent implements OnInit {

  clienti: Cliente[] = [];

  clienteFilter: any = '';
  emailFilter: any = '';

  constructor(private apiService: ApiService, private router: Router, private modal: NzModalService, private viewContainerRef: ViewContainerRef) { }

  ngOnInit(): void {

    // Recupero i clienti
    this.apiService.setClienti();
    this.apiService.clienteChanged.subscribe({
      next: (data) => this.clienti = data,
      error: (err) => this.apiService.error(err.error.message),
      complete: () => { }
    })
  }

  onAdd() {
    this.router.navigate(['/backoffice/clienti/add']);
  }

  onEdit(id: number) {
    this.router.navigate(['/backoffice/clienti/' + id])
  }

  onEditPassword(id: number) {
    this.router.navigate(['/backoffice/clienti/' + id + '/password'])
  }

  onElimina(id: number) {
    // Creo il modal per l'eliminazione del clietne
    this.modal.create({
      nzTitle: 'Conferma',
      nzContent: EliminaClienteBackofficeComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzData: {
        id_cliente: id
      },
      nzFooter: null,
      nzCentered: true,
      nzWidth: 750,
      nzBodyStyle: { 'padding': '0px' }
    });
  }

  onConferma(id: number) {
    // Creo il modal per la conferma dell'account del cliente
    this.modal.create({
      nzTitle: 'Conferma',
      nzContent: ConfermaClientiBackofficeComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzData: {
        id_cliente: id
      },
      nzFooter: null,
      nzCentered: true,
      nzWidth: 750,
      nzBodyStyle: { 'padding': '0px' }
    });
  }

  onOrdinamentoCliente(a: Cliente, b: Cliente) {
    return a.cognome.localeCompare(b.cognome)
  }

  onOrdinamentoEmail(a: Cliente, b: Cliente) {
    return a.email.localeCompare(b.email)
  }

  onOrdinamentoStato(a: Cliente, b: Cliente) {
    return a.stato.localeCompare(b.stato)
  }


}
