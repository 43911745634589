import { defineCustomElement } from '../chunks/vidstack-CRlI3Mh7.js';
import { MediaLayoutElement, MediaControlsElement, MediaControlsGroupElement, MediaAnnouncerElement, MediaTooltipElement, MediaTooltipTriggerElement, MediaTooltipContentElement, MediaGoogleCastButtonElement, MediaToggleButtonElement, MediaSliderElement, MediaAudioGainSliderElement, MediaSpeedSliderElement, MediaQualitySliderElement, MediaSliderChaptersElement, MediaSliderStepsElement, MediaSliderVideoElement, MediaMenuPortalElement, MediaAudioGainRadioGroupElement, MediaChaptersRadioGroupElement, MediaRadioGroupElement, MediaGestureElement, MediaCaptionsElement, MediaTitleElement, MediaChapterTitleElement, MediaSpinnerElement } from '../chunks/vidstack-DGY-5qpR.js';
import { MediaPlayButtonElement, MediaMuteButtonElement, MediaCaptionButtonElement, MediaFullscreenButtonElement, MediaPIPButtonElement, MediaSeekButtonElement, MediaAirPlayButtonElement, MediaVolumeSliderElement, MediaTimeSliderElement, MediaSliderPreviewElement, MediaSliderValueElement, MediaSliderThumbnailElement, MediaMenuElement, MediaMenuButtonElement, MediaMenuItemsElement, MediaMenuItemElement, MediaAudioRadioGroupElement, MediaCaptionsRadioGroupElement, MediaSpeedRadioGroupElement, MediaQualityRadioGroupElement, MediaRadioElement, MediaThumbnailElement, MediaLiveButtonElement, MediaTimeElement } from '../chunks/vidstack-DvuMxVXP.js';
import { MediaPosterElement } from '../chunks/vidstack-QR8zGkwr.js';
import '../chunks/vidstack-CCNf7j4j.js';
import '../chunks/vidstack-Cpte_fRf.js';
import '../chunks/vidstack-DWQEfrPg.js';
import '@floating-ui/dom';
import '../chunks/vidstack-Dfy6aedI.js';
import '../chunks/vidstack-BOTZD4tC.js';
import '../chunks/vidstack-CP9ACpRU.js';
import '../chunks/vidstack-A9j--j6J.js';
import '../chunks/vidstack-DwhHIY5e.js';
import '../chunks/vidstack-lwuXewh7.js';
import '../chunks/vidstack-DXXgp8ue.js';
import '../chunks/vidstack-Dihypf8P.js';
import '../chunks/vidstack-C9vIqaYT.js';
import 'lit-html';
import '../chunks/vidstack-CwTj4H1w.js';
import '../chunks/vidstack-DGstw8fa.js';

defineCustomElement(MediaLayoutElement);
defineCustomElement(MediaControlsElement);
defineCustomElement(MediaControlsGroupElement);
defineCustomElement(MediaPosterElement);
defineCustomElement(MediaAnnouncerElement);
defineCustomElement(MediaTooltipElement);
defineCustomElement(MediaTooltipTriggerElement);
defineCustomElement(MediaTooltipContentElement);
defineCustomElement(MediaPlayButtonElement);
defineCustomElement(MediaMuteButtonElement);
defineCustomElement(MediaCaptionButtonElement);
defineCustomElement(MediaFullscreenButtonElement);
defineCustomElement(MediaPIPButtonElement);
defineCustomElement(MediaSeekButtonElement);
defineCustomElement(MediaAirPlayButtonElement);
defineCustomElement(MediaGoogleCastButtonElement);
defineCustomElement(MediaToggleButtonElement);
defineCustomElement(MediaSliderElement);
defineCustomElement(MediaAudioGainSliderElement);
defineCustomElement(MediaVolumeSliderElement);
defineCustomElement(MediaTimeSliderElement);
defineCustomElement(MediaSpeedSliderElement);
defineCustomElement(MediaQualitySliderElement);
defineCustomElement(MediaSliderChaptersElement);
defineCustomElement(MediaSliderStepsElement);
defineCustomElement(MediaSliderPreviewElement);
defineCustomElement(MediaSliderValueElement);
defineCustomElement(MediaSliderThumbnailElement);
defineCustomElement(MediaSliderVideoElement);
defineCustomElement(MediaMenuElement);
defineCustomElement(MediaMenuButtonElement);
defineCustomElement(MediaMenuPortalElement);
defineCustomElement(MediaMenuItemsElement);
defineCustomElement(MediaMenuItemElement);
defineCustomElement(MediaAudioRadioGroupElement);
defineCustomElement(MediaCaptionsRadioGroupElement);
defineCustomElement(MediaSpeedRadioGroupElement);
defineCustomElement(MediaAudioGainRadioGroupElement);
defineCustomElement(MediaQualityRadioGroupElement);
defineCustomElement(MediaChaptersRadioGroupElement);
defineCustomElement(MediaRadioGroupElement);
defineCustomElement(MediaRadioElement);
defineCustomElement(MediaGestureElement);
defineCustomElement(MediaThumbnailElement);
defineCustomElement(MediaCaptionsElement);
defineCustomElement(MediaLiveButtonElement);
defineCustomElement(MediaTimeElement);
defineCustomElement(MediaTitleElement);
defineCustomElement(MediaChapterTitleElement);
defineCustomElement(MediaSpinnerElement);
