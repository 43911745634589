import { Component, inject } from '@angular/core';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';
import { ApiService } from '../../../../services/api.service';

@Component({
  selector: 'app-conferma-clienti-backoffice',
  templateUrl: './conferma-clienti-backoffice.component.html',
  styleUrls: ['./conferma-clienti-backoffice.component.css']
})
export class ConfermaClientiBackofficeComponent {

  constructor(private apiService: ApiService) { }

  loading: boolean = false;

  readonly #modal = inject(NzModalRef)
  readonly nzModalData = inject(NZ_MODAL_DATA);

  onConferma() {
    this.loading = true;

    // Confermo il cliene / abilitazione all'accesso
    this.apiService.sendConfermaCliente(this.nzModalData.id_cliente!).subscribe({
      next: (data) => this.apiService.setClienti(),
      error: (err) => {
        this.loading = false;
        this.apiService.error(err.error.message);
      },
      complete: () => {
        this.#modal.destroy();
        this.loading = false;
      }
    })

  }

  onClose() {
    this.#modal.destroy();
  }

}