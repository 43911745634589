import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../../../services/api.service';
import { Cliente } from '../../../../shared/interfacce.model';

@Component({
  selector: 'app-modifica-cliente-backoffice',
  templateUrl: './modifica-cliente-backoffice.component.html',
  styleUrls: ['./modifica-cliente-backoffice.component.css']
})
export class ModificaClienteBackofficeComponent implements OnInit {

  constructor(private apiService: ApiService, private router: Router, private route: ActivatedRoute, private datePipe: DatePipe) { }

  formCliente!: FormGroup;

  id: number = 0;
  cliente?: Cliente;

  loading: boolean = false;
  alertMessage: string = '';
  alertType: "success" | "info" | "warning" | "error" = 'success';
  alertVisible: boolean = false;

  ngOnInit(): void {

    // Creo il form con i controlli
    this.formCliente = new FormGroup({
      email: new FormControl(null),
      nome: new FormControl(null),
      cognome: new FormControl(null),
      data_nascita: new FormControl(null),
      luogo_nascita: new FormControl(null),
      codice_fiscale: new FormControl(null),
      telefono: new FormControl(null),
      newsletter: new FormControl(null)
    });

    // Recupero i parametri passati nell'url
    this.route.params.subscribe(params => {
      this.id = params['id'];

      // Recupero il cliente
      this.apiService.getCliente(this.id).subscribe({
        next: (data) => this.cliente = data,
        error: (err) => this.router.navigate(['/backoffice/clienti']),
        complete: () => {

          // Setto i dati del cliente nel form
          this.formCliente.setValue({
            email: this.cliente?.email,
            nome: this.cliente?.nome,
            cognome: this.cliente?.cognome,
            data_nascita: this.cliente?.data_nascita,
            luogo_nascita: this.cliente?.luogo_nascita,
            codice_fiscale: this.cliente?.codice_fiscale,
            telefono: this.cliente?.telefono,
            newsletter: this.cliente?.newsletter ? true : false
          })
        }
      })

    });
  }

  onClose() {
    this.router.navigate(['/backoffice/clienti']);
  }

  onError(message: string) {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    this.alertMessage = message;
    this.alertVisible = true;
    this.alertType = 'error';
    this.loading = false;
  }

  onSubmit() {

    // Formo l'oggetto per la richiesta api
    let cliente = {
      email: this.formCliente.get('email')?.value,
      nome: this.formCliente.get('nome')?.value,
      cognome: this.formCliente.get('cognome')?.value,
      data_nascita: this.datePipe.transform(this.formCliente.get('data_nascita')?.value, 'yyyy-MM-dd'),
      luogo_nascita: this.formCliente.get('luogo_nascita')?.value,
      codice_fiscale: this.formCliente.get('codice_fiscale')?.value,
      telefono: this.formCliente.get('telefono')?.value,
      newsletter: this.formCliente.get('newsletter')?.value || false
    }

    this.loading = true;

    // Aggiorno il cliente nel db
    this.apiService.updateCliente(this.id, cliente).subscribe({
      next: (data) => this.apiService.setClienti(),
      error: (err) => this.onError(err.error.message),
      complete: () => {
        this.loading = false;
        this.router.navigate(['/backoffice/clienti']);
      }
    })

  }

}
