<ng-template #abbonamentoTitle>        
    <h3 class="text-white mb-0 text-xl font-semibold">Abbonamenti</h3>        
    <div class="flex lg:flex-row flex-col gap-4 mt-4">            
        <div class="lg:w-1/2 w-full">
            <input nz-input placeholder="Cerca Cliente abbonamento" nzSize="large" [(ngModel)]="clienteFilter" />
        </div>              
        <div class="lg:w-1/2 w-full">                        
            <input nz-input placeholder="Cerca Stato abbonamento" nzSize="large" [(ngModel)]="statoFilter" />
        </div>                                   
    </div>
</ng-template>
<nz-card [nzTitle]="abbonamentoTitle">        
    <nz-table #abbonamentiTable nzShowPagination class="tabella"
        [nzTotal]="(abbonamenti | searchClienteAbbonamento: clienteFilter | searchStatoAbbonamento: statoFilter).length"
        nzShowSizeChanger [nzPageSize]="10"
        [nzPageSizeOptions]="[5, 10, 25, 50, 100, 150, 200, 250, 300, 350, 400, 500, 750, 1000]"
        [nzData]="abbonamenti | searchClienteAbbonamento: clienteFilter | searchStatoAbbonamento: statoFilter"
        [nzBordered]="true" [nzNoResult]="'Nessun abbonamento trovato'">
        <thead>
            <tr>
                <th [nzSortFn]="onOrdinamentoCliente">Cliente</th>
                <th [nzSortFn]="onOrdinamentoStato" class="text-center">Stato</th>                        
                <th [nzSortFn]="onOrdinamentoDataCreazione" class="text-start">Data creazione</th>
                <th [nzSortFn]="onOrdinamentoDataScadenza" class="text-start">Data scadenza</th>
                <th class="!text-center">Azioni</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let abbonamento of abbonamentiTable.data| searchClienteAbbonamento: clienteFilter | searchStatoAbbonamento: statoFilter">
                <td>{{abbonamento.cliente}}</td>
                <td class="text-center">{{abbonamento.stato}}</td>                        
                <td class="text-start">{{abbonamento.data_creazione | date: 'dd/MM/YYYY'}}</td>                        
                <td class="text-start">{{abbonamento.stripe_scadenza | date: 'dd/MM/YYYY'}}</td>                        
                <td class="text-center">
                    <nz-button-group>                                
                        <button nz-button nz-dropdown [nzDropdownMenu]="menu" nzPlacement="bottomRight">
                            <span nz-icon nzType="menu"></span>
                        </button>
                  </nz-button-group>
                  <nz-dropdown-menu #menu="nzDropdownMenu">
                    <ul nz-menu>
                      <li nz-menu-item class="!p-4" (click)="onSospendi(abbonamento.stripe_sub)" *ngIf="abbonamento.stato == 'attivo'">Sospendi Abbonamento</li>                              
                      <li nz-menu-item class="!p-4" (click)="onAttiva(abbonamento.id, abbonamento.stripe_sub)" *ngIf="abbonamento.stato == 'sospeso'">Attiva Abbonamento</li>
                      <li nz-menu-item class="!p-4" (click)="onElimina(abbonamento.id)">Elimina abbonamento</li>
                    </ul>
                  </nz-dropdown-menu>
                </td>
            </tr>
        </tbody>
    </nz-table>                    
</nz-card>