import { Component, inject } from '@angular/core';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';
import { ApiService } from '../../../../services/api.service';

@Component({
  selector: 'app-elimina-news-backoffice',
  templateUrl: './elimina-news-backoffice.component.html',
  styleUrls: ['./elimina-news-backoffice.component.css']
})
export class EliminaNewsBackofficeComponent {

  constructor(private apiService: ApiService) { }

  loading: boolean = false;  

  readonly #modal = inject(NzModalRef)
  readonly nzModalData = inject(NZ_MODAL_DATA);

  onElimina() {
    this.loading = true;

    // Elimino la news
    this.apiService.deleteNews(this.nzModalData.id_news!).subscribe({
      next: (data) => this.apiService.setNews(),
      error: (err) => {
        this.loading = false;
        this.apiService.error(err.error.message);
      }, complete: () => {
        this.loading = false;
        this.#modal.destroy();
      }

    })
  }

  onClose() {
    this.#modal.destroy();
  }
}