import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-annullo-pagamento',
  templateUrl: './annullo-pagamento.component.html',
  styleUrls: ['./annullo-pagamento.component.css']
})
export class AnnulloPagamentoComponent implements OnInit {

  constructor(private modalService: NzModalService, private router: Router) { }

  ngOnInit(): void {    

    this.modalService.error({
      nzTitle: 'Pagamento annullato',
      nzContent: "<br>Il pagamento è stato annullato!. Per tornare al tua area personale premi il pulsante qua sottostante.",
      nzClosable: false,
      nzOkText: 'Ok',
      nzCentered: true,
      nzOnOk: () => this.router.navigate(['/profilo/']),      
    });
  

  }
}
