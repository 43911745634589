<div class="lg:px-container px-8">

  <!-- LOGO MOBILE -->
  <div class="flex justify-center lg:py-4 py-8">
    <img class="lg:hidden" src="../../../assets/images/Logo bianco.png" height="40px" alt="Logo 7live">
  </div>
  <!-- LOGO MOBILE -->

  <!-- TITOLO PAGINA -->
  
  <h3 class="text-white font-bold lg:text-5xl text-3xl mt-8 mb-4">On Demand</h3>
  <p class="text-white text-xl mb-8">Scopri i video On Demand e guardali quando vuoi</p>  
  <!-- TITOLO ON DEMAND -->

  <!-- BANNER -->

  <!-- NO ABBONAMENTO || NO LOGIN -->  
  <div class="avviso py-12 lg:px-0 px-8 bg-dark rounded flex justify-center flex-col gap-4" *ngIf="abbonamentoScaduto">
    <h3 class="text-white text-center lg:text-2xl text-xl">{{user?.id ? 'Sembra che tu non abbia nessun abbonamento attivo...' : 'Per visualizzare questo video On Demand accedi o registrati prima.'}}</h3>
    <p class="text-white text-center">{{user?.id ? 'Abbonati ora per poter visionare il video On Demand!' : "Dopo aver effettuato la registrazione o l'accesso, se non sei ancora abbonato a Seven Live Web TV, effettualo per poter visionare il video On Demand."}}</p>
    <button class="bottone" nzType="primary" nz-button [routerLink]="user?.id ? '/profilo/abbonamento' : '/login'">{{user?.id ? 'Abbonati ora': 'Login'}}</button>
  </div>  
  <!-- NO ABBONAMENTO || NO LOGIN -->

  <!-- BANNER SELEZIONE VIDEO -->  
  <div class="avviso py-12 bg-dark rounded flex justify-center flex-col" *ngIf="!video && !abbonamentoScaduto">
    <h3 class="text-white text-center lg:text-2xl text-xl">Premi o clicca su un video On Demand per poterlo vedere</h3>
  </div>  
  <!-- BANNER SELEZIONE VIDEO -->
  
  <!-- BANNER -->
  
  <!-- PLAYER -->    
  <app-player *ngIf="video && !loading && !abbonamentoScaduto"></app-player>    
  <!-- PLAYER -->    

  <!-- TITOLO ON DEMAND -->  
  <h4 class="text-white font-bold lg:text-4xl text-2xl my-8">Rivivi i nostri momenti</h4>  
  <!-- TITOLO ON DEMAND -->

  <!-- FILTRI -->
  <div class="flex lg:flex-row flex-col gap-2">
    <div class="lg:w-4/12 w-full">
      <nz-input-group [nzSuffix]="suffixIconSearch" class="h-full ricerca pl-4" nzSize="large">
        <input id="queryFiltroVideo" nz-input type="text" [formControl]="titoloFilter" (ngModelChange)="onFiltro()" placeholder="Cerca...">
      </nz-input-group>
      <ng-template #suffixIconSearch>
        <span nz-icon nzType="search"></span>
      </ng-template>
    </div>
    <div class="lg:w-3/12 w-full">      
      <select name="canale" class="h-full ricerca ricerca-select focus-visible:outline-none px-4 py-1.5" (ngModelChange)="onFiltro()" [formControl]="canaleFilter">
        <option class="px-4" value="-1" selected>Tutti i canali</option>
        <option class="px-4" *ngFor="let canale of canali" [value]="canale.id">{{canale.nome}}</option>
      </select>
    </div>
    <div class="lg:w-3/12 w-full">
      <select name="canale" class="h-full ricerca ricerca-select focus-visible:outline-none px-4 py-1.5" (change)="onFiltro()" [formControl]="categoriaFilter">
        <option class="px-4" value="-1" selected>Tutte le categorie</option>
        <option class="px-4" *ngFor="let categoria of categorie" [value]="categoria.id">{{categoria.titolo}}</option>
      </select>
    </div>
    <div class="lg:w-2/12 w-full">
      <button nz-button nzSize="large" class="bottone px-4 !w-full py-1.5 whitespace-break-spaces" type="button" (click)="onClearFiltri()">Pulisci i filtri</button>
    </div>
  </div>
  <!-- FILTRI -->

  <!-- LOADER -->
  <app-video-loading class="flex items-center justify-center my-8" *ngIf="loading"></app-video-loading>  
  <!-- LOADER -->

  <ng-container *ngIf="!loading">

    <!-- NESSUN RISULTATO -->    
    <h5 class="mt-8 flex justify-center items-center text-white font-semibold lg:text-2xl text-xl" *ngIf="ondemands_filtrati.length < 1">La ricerca non ha prodotto nessun risultato</h5>    
    <!-- NESSUN RISULTATO -->

    <!-- ON DEMANDS -->
    <div class="grid lg:grid-cols-3 md:grid-cols-2 gap-2 gap-y-4 my-8" *ngIf="ondemands_filtrati.length > 0">
      <ng-container *ngFor="let ondemand of ondemands_filtrati.slice(0,num_ondemands);">
        <app-video-thumbnail (click)="onOndemand(ondemand.id)" [ondemand]="ondemand" ></app-video-thumbnail>
      </ng-container>
    </div>
    <!-- ON DEMANDS -->    

    <!-- BUTTON ON DEMAND -->
    <div class="flex flex-row" *ngIf="ondemands_filtrati.length > 0">

      <div [ngClass]="{'w-1/2 pe-0': num_ondemands > 9, 'w-full': num_ondemands <= 9}" *ngIf="num_ondemands < ondemands_filtrati.length">
        <nz-divider class="text-white ondemand-divider !mt-0" [nzOrientation]="num_ondemands <= 9 ? 'center' : 'right'" [nzText]="text">
          <ng-template #text>
            <div class="text-white button-divider" (click)="onMostra(6)">
              <span class="align-middle" nz-icon nzType="down" nzTheme="outline"></span>
            </div>          
          </ng-template>
        </nz-divider>
      </div>
      <div [ngClass]="{'w-1/2 ps-0': num_ondemands > 9 && num_ondemands < ondemands_filtrati.length, 'w-full': num_ondemands > ondemands_filtrati.length}" *ngIf="num_ondemands > 9">
        <nz-divider class="text-white ondemand-divider !mt-0" [nzOrientation]="num_ondemands > ondemands_filtrati.length ? 'center' : 'left'" [nzText]="text">
          <ng-template #text>
            <div class="text-white button-divider" (click)="onMostra(-6)">
              <span class="align-middle" nz-icon nzType="up" nzTheme="outline"></span>
            </div>          
          </ng-template>
        </nz-divider>
      </div>

    </div>
    <!-- BUTTON ON DEMAND -->

  </ng-container>

</div>