import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-contattaci',
  templateUrl: './contattaci.component.html',
  styleUrls: ['./contattaci.component.css']
})
export class ContattaciComponent implements OnInit {

  constructor(private apiService: ApiService, private recaptchaV3Service: ReCaptchaV3Service) { }

  formContatti!: FormGroup;

  loading: boolean = false;
  alertMessage: string = '';
  alertType: "success" | "info" | "warning" | "error" = 'success';
  alertVisible: boolean = false;

  ngOnInit(): void {
    this.formContatti = new FormGroup({
      nominativo: new FormControl(null),
      telefono: new FormControl(null),
      azienda: new FormControl(null),
      messaggio: new FormControl(null),
      email: new FormControl(null),
    });
  }

  onError(message: string) {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    this.alertMessage = message;
    this.alertVisible = true;
    this.alertType = 'error';
    this.loading = false;
  }

  onLogin() {
    let contatto = {
      nome_cognome: this.formContatti.get('nominativo')?.value,
      telefono: this.formContatti.get('telefono')?.value,
      azienda: this.formContatti.get('azienda')?.value,
      messaggio: this.formContatti.get('messaggio')?.value,
      email: this.formContatti.get('email')?.value,
    }

    this.loading = true

    this.apiService.contatti(contatto).subscribe({      
      error: (err) => this.onError(err.error.message),
      complete: () => {
        this.alertMessage = "Un'email contenente i dati inseriti è stata inviata all'indirizzo di contatto!";
        this.alertVisible = true;
        this.alertType = 'success';
        this.loading = false;

        this.formContatti.reset();
      }

    })
  }


}
