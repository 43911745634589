import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';
import { EliminaCanaleBackofficeComponent } from './elimina-canale-backoffice/elimina-canale-backoffice.component';
import { EliminaImmagginiCanaleComponent } from './elimina-immaggini-canale/elimina-immaggini-canale.component';
import { ApiService } from '../../../services/api.service';
import { StorageService } from '../../../services/storage.service';
import { Canale, UserBackoffice } from '../../../shared/interfacce.model';

@Component({
  selector: 'app-canali-backoffice',
  templateUrl: './canali-backoffice.component.html',
  styleUrls: ['./canali-backoffice.component.css']
})
export class CanaliBackofficeComponent implements OnInit {

  canali: Canale[] = [];

  titoloFilter: any = '';
  descrizioneFilter: any = ''

  user?: UserBackoffice;

  constructor(private apiService: ApiService, private storageService: StorageService, private router: Router, private modal: NzModalService, private viewContainerRef: ViewContainerRef) { }

  ngOnInit(): void {

    // Recupero l'utente loggato nel backoffice
    this.user = this.storageService.getUserBackoffice();

    // Recupero i canali
    this.apiService.setCanali();
    this.apiService.canaliChanged.subscribe({
      next: (data) => {

        if (this.user?.tipo == 'Amministratore')
          this.canali = data;
        else
          this.router.navigate(['/backoffice/canali/' + this.user?.id_canale])

      },
      error: (err) => this.apiService.error(err.error.message),
      complete: () => { }
    })
  }

  onAdd() {
    this.router.navigate(['/backoffice/canali/add']);
  }

  onEdit(id: number) {
    this.router.navigate(['/backoffice/canali/' + id])
  }

  onEliminaCanale(id: number) {

    // Formo il modal per l'eliminazione del canale
    this.modal.create({
      nzTitle: 'Conferma',
      nzContent: EliminaCanaleBackofficeComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzData: {
        id_canale: id
      },
      nzFooter: null,
      nzCentered: true,
      nzWidth: 750,
      nzBodyStyle: { 'padding': '0px' }
    });
  }

  onEliminaImmagine(tipo: string, nome_immagine: string, id: number) {

    // Formo il modal per l'eliminazione dell'immagine o icona del canale
    this.modal.create({
      nzTitle: 'Conferma',
      nzContent: EliminaImmagginiCanaleComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzData: {
        tipo: tipo,
        nome_immagine: nome_immagine,
        id_canale: id
      },
      nzFooter: null,
      nzCentered: true,
      nzWidth: 750,
      nzBodyStyle: { 'padding': '0px' }
    });
  }

  onOrdinamentoTitolo(a: Canale, b: Canale) {
    return a.nome.localeCompare(b.nome)
  }

  onOrdinamentoDescrizione(a: Canale, b: Canale) {
    return a.descrizione.localeCompare(b.descrizione)
  }

}
