import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../../../services/api.service';
import { Ticket } from '../../../../shared/interfacce.model';

@Component({
  selector: 'app-modifica-ticket-backoffice',
  templateUrl: './modifica-ticket-backoffice.component.html',
  styleUrls: ['./modifica-ticket-backoffice.component.css']
})
export class ModificaTicketBackofficeComponent implements OnInit {

  constructor(private apiService: ApiService, private router: Router, private route: ActivatedRoute) { }

  formTicket!: FormGroup;

  id: number = 0;

  ticket?: Ticket;

  loading: boolean = false;
  alertMessage: string = '';
  alertType: "success" | "info" | "warning" | "error" = 'success';
  alertVisible: boolean = false;

  ngOnInit(): void {

    // Creo il formo con i controlli
    this.formTicket = new FormGroup({
      categoria: new FormControl(null),
      titolo: new FormControl(null),
      messaggio: new FormControl(null),
      stato: new FormControl(null),
    })

    // Recupero i parametri passati nell'url
    this.route.params.subscribe(params => {
      this.id = params['id'];

      // Recupero il ticket
      this.apiService.getTicket(this.id).subscribe({
        next: (data) => this.ticket = data,
        error: (err) => this.router.navigate(['/backoffice/tickets']),
        complete: () => {

          // Setto i dati del ticket nel form
          this.formTicket.setValue({
            categoria: this.ticket?.categoria,
            titolo: this.ticket?.titolo,
            messaggio: this.ticket?.messaggio,
            stato: this.ticket?.stato,
          })
        }
      })

    });
  }

  onClose() {
    this.router.navigate(['/backoffice/tickets']);
  }

  onError(message: string) {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    this.alertMessage = message;
    this.alertVisible = true;
    this.alertType = 'error';
    this.loading = false;
  }

  onSubmit() {

    // Formo l'oggetto per la richiesta dell'api
    let ticket = {
      categoria: this.formTicket.get('categoria')?.value,
      titolo: this.formTicket.get('titolo')?.value,
      messaggio: this.formTicket.get('messaggio')?.value,
      stato: this.formTicket.get('stato')?.value,
    }

    this.loading = true;

    // Aggiorno il ticket nel db
    this.apiService.updateTicket(this.id, ticket).subscribe({
      next: (data) => this.apiService.setTickets(),
      error: (err) => this.onError(err.error.message),
      complete: () => {
        this.loading = false;
        this.router.navigate(['/backoffice/tickets']);
      }
    })

  }

}
