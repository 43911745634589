import { AfterViewInit, Component, HostListener, Input, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { animate, style, transition, trigger } from '@angular/animations';
import { environment } from '../../../environments/environment.development';
import { Live } from '../../shared/interfacce.model';
declare let Twitch: any;

@Component({
  selector: 'app-player-live-home',
  templateUrl: './player-live-home.component.html',
  styleUrls: ['./player-live-home.component.css'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate(500, style({ opacity: 0 }))
      ])
    ])
  ]
})
export class PlayerLiveHomeComponent implements OnInit, AfterViewInit {

  @HostListener('document:mousemove', ['$event'])
  onMouseStop(e: MouseEvent) {

    let doc: any = window.document;
    this.isFScreen = !doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement ? false : true;

    clearTimeout(this.timer);

    if (this.isFScreen) {

      let cursor = document.body.style.cursor;

      if (cursor == 'none') {
        document.body.style.cursor = "auto";
        let controlli = document.getElementById('controls');
        controlli?.classList.remove('fullscreen');
      }

      this.timer = setTimeout(function () {

        if (cursor == '' || cursor == 'auto') {
          document.body.style.cursor = "none";
          let controlli = document.getElementById('controls');
          controlli?.classList.add('fullscreen');
        }

      }, 3000);

    } else {
      let cursor = document.body.style.cursor;

      if (cursor == 'none') {

        document.body.style.cursor = "auto";
        let controlli = document.getElementById('controls');
        controlli?.classList.remove('fullscreen');
      }
    }
  }  

  @Input() id!: number;

  constructor(private deviceService: DeviceDetectorService) { }

  currentVideo: { titolo: string } = { titolo: '' };

  volume: number = 100;
  timer: any = 0;
  
  isFScreen: boolean = false;
  loading: boolean = false;
  controlli: boolean = false;
  firstTap: boolean = true;  

  player: any;
  embed: any;

  lives: Live[] = [];  

  url: string = environment.apiUrl

  ngOnInit(): void {
    let videonord: any = { titolo: "VideoNord", icona_file: this.url +  "immagine/icona_canale_20240229_110505.png", selected: true }
    this.lives.push(videonord)    
  }

  ngAfterViewInit(): void {    
    this.embed = new Twitch.Embed("twitch-player_" + this.id, {
      width: '100%',
      height: '100%',
      channel: "VideoNord",
      layout: "video",
      autoplay: false,
      controls: false,
      parent: ["localhost", "7live-webtv.lamptest.ipsnet.it", "7live.tv"]
    });      

    this.embed.addEventListener(Twitch.Embed.VIDEO_READY, () => {      

      this.player = this.embed.getPlayer();
      this.player.setVolume(1);
      this.controlli = true;
      this.currentVideo = { titolo: "VideoNord" };
      this.player.pause();      
      
    }); 
  }

  onVideoChange(live: Live){

  }

  onMute() {
    // Se il video era mutato lo smuto e viceversa.
    if (this.player.muted) {
      this.player.muted = false;
      this.player.setVolume(this.volume);
    } else {
      this.player.muted = true;
      this.player.setVolume(0)
    }
  }

  onVolume(volume: any) {
    this.player.setVolume(volume.target.valueAsNumber / 100);
    this.volume = volume.target.valueAsNumber;
    this.volume < 1 ? this.player.muted = true : this.player.muted = false;
  }

  triggerPlay() {
    if (this.firstTap) {
      this.togglePause();
      this.firstTap = false;
    }
  }

  onFullScreen() {

    if (this.deviceService.os == 'iOS') {
      let videoPlayer: any = document.getElementById('video');
      videoPlayer.webkitEnterFullScreen();
    }

    let doc: any = window.document;
    let docEl: any = document.getElementById('player-twitch');

    let requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.webkitbeginfullscreen || docEl.msRequestFullscreen;
    let cancelFullScreen = doc.exitFullscreen || doc?.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen;

    if (!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement && !doc.webkitbeginfullscreen) {
      requestFullScreen.call(docEl);
      this.isFScreen = true;
    }
    else {
      cancelFullScreen.call(doc);
      this.isFScreen = false;
    }
  }

  onRightClick(e: any) {
    return false;
  }

  togglePause() {
    if (this.player.isPaused()) {
      this.player.play();
    }
    else {
      this.player.pause();
    }
  }
}
