import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ApiService } from '../../services/api.service';


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  constructor(private apiService: ApiService, private router: Router, private route: ActivatedRoute, private modalService: NzModalService) { }

  formReset!: FormGroup;

  token: string = '';
  id: number = 0;

  hide: boolean = false;
  hide_confirm: boolean = false;

  loading: boolean = false;
  alertMessage: string = '';
  alertType: "success" | "info" | "warning" | "error" = 'success';
  alertVisible: boolean = false;

  ngOnInit(): void {
    this.formReset = new FormGroup({
      password: new FormControl(null),
      confirm_password: new FormControl(null),
    })

    this.route.queryParams.subscribe(params => {
      this.token = params['token'];
      if (this.token) {
        this.apiService.checkTokenReset(this.token).subscribe({
          next: (data) => {
            if (!data.id) {
              this.router.navigate(['/login']);
            } else {
              this.id = data.id;
            }
          },
          error: (err) => this.router.navigate(['/login']),
          complete: () => { }
        })
      } else {
        this.router.navigate(['/login']);
      }
    })
  }

  onError(message: string) {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    this.alertMessage = message;
    this.alertVisible = true;
    this.alertType = 'error';
    this.loading = false;
  }

  onSubmit() {

    this.loading = true;

    let reset = {
      password: this.formReset.get('password')?.value,
      confirm_password: this.formReset.get('confirm_password')?.value
    }

    this.apiService.reset(reset.password, reset.confirm_password, this.id).subscribe({
      next: (data) => { },
      error: (err) => this.onError(err.error.message),
      complete: () => {

        this.modalService.success({
          nzTitle: 'Esito',
          nzContent: "<br> La password è stata aggiornata con successo! Torna al Login premendo il pulsante sottostante.",
          nzClosable: false,
          nzOkText: 'Vai al login',
          nzCentered: true,
          nzOnOk: () => this.router.navigate(['/login'])
        });

      }

    })

  }

}
