import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { ApiService } from "../services/api.service";
import { StorageService } from "../services/storage.service";


@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {

  constructor(private router: Router, private api: ApiService, private storageService: StorageService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return new Observable<boolean>(obs => {

      // Recupero i dati dell'utente loggato, salvato nello storage
      const user = this.storageService.getUser();

      // Controllo se il token dell'utente è ancora attivo
      this.api.autorizzazione().subscribe({
        next: (data) => {

          // Se non è valido esco dalla sezione profilo
          if (!data) {
            this.storageService.clearUser();
            this.router.navigateByUrl("/login");
            return obs.next(false);
          }
          else {
            // Controllo se il cliente esiste
            this.api.getCliente(user.id).subscribe({
              next: (data) => {

                // Se esiste tutto ok procedo
                if (data) {
                  return obs.next(true);
                } else {
                  this.storageService.clearUser();
                  this.router.navigateByUrl("/login");
                  return obs.next(false);
                }
              },
              error: (err) => {
                this.storageService.clearUser();
                this.router.navigateByUrl("/login");
                return obs.next(false);
              }
            })
          }
        },
        error: (err) => this.router.navigateByUrl("/login")
      })
    });
  }
}