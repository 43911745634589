<div class="bg-image reset flex items-center lg:flex-row">                  
    <div class="lg:w-1/2 w-full lg:flex hidden"></div>
    <div class="lg:w-1/2 w-full h-full lg:px-16 p-8 flex items-center bg-light reset">
        <div class="form w-full">
                    
            <h3 class="text-4xl font-bold mb-4">Reset Password</h3>
            <p class="text-muted mb-4">Inserisci la tua nuova password!</p>
            
            <form [formGroup]="formReset" (ngSubmit)="onSubmit()" class="flex flex-col gap-4">
                <nz-alert [nzType]="alertType" nzShowIcon [nzMessage]="alertMessage" *ngIf="alertVisible"></nz-alert>      
                <div class="w-full forms-inputs"> 
                    <span>* Password</span>
                    <nz-input-group [nzSuffix]="suffixTemplate">
                        <input [type]="hide ? 'text' : 'password'" nz-input formControlName="password" placeholder="Nuova password" autocomplete />
                    </nz-input-group>
                    <ng-template #suffixTemplate> <span nz-icon [nzType]="hide ? 'eye-invisible' : 'eye'" (click)="hide = !hide"></span></ng-template>
                </div>
                <div class="w-full forms-inputs"> 
                    <span>* Conferma Password</span>
                    <nz-input-group [nzSuffix]="suffixTemplateConfirm">
                        <input [type]="hide_confirm ? 'text' : 'password'" nz-input formControlName="confirm_password" placeholder="Conferma password" autocomplete />
                    </nz-input-group>
                    <ng-template #suffixTemplateConfirm> <span nz-icon [nzType]="hide_confirm ? 'eye-invisible' : 'eye'" (click)="hide_confirm = !hide_confirm"></span></ng-template>
                </div>
                <div class="mt-4 text-center">
                    <button class="lg:w-2/3 w-full bottone" nzType="primary" nz-button type="submit" [nzLoading]="loading" [disabled]="!formReset.valid">Reset</button>
                    <nz-divider class="text-black" nzText="oppure"></nz-divider>
                    <button class="lg:w-2/3 w-full bottone-secondario" nz-button type="button" routerLink="/login">Torna al Login</button>
                </div>                
            </form>                                

        </div>
    </div>
</div>