import { Input, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { StorageService } from '../../services/storage.service';
import { Canale, Categoria, Ondemand, User } from '../../shared/interfacce.model';
import { environment } from '../../../environments/environment.development';


@Component({
  selector: 'app-on-demand',
  templateUrl: './on-demand.component.html',
  styleUrls: ['./on-demand.component.css']
})
export class OnDemandComponent implements OnInit {

  constructor(private apiService: ApiService, private route: ActivatedRoute, private router: Router, private storageService: StorageService) { }

  @Input() abbonamentoScaduto: boolean = false;

  url: string = environment.apiUrl;

  loading: boolean = true;
  video: boolean = false;

  ondemands_filtrati: Ondemand[] = [];
  ondemands: Ondemand[] = [];

  ondemand?: Ondemand;

  canali: Canale[] = [];
  categorie: Categoria[] = [];

  num_ondemands: number = 9;

  titoloFilter = new FormControl();
  canaleFilter = new FormControl("-1");
  categoriaFilter = new FormControl("-1");

  user?: User;

  ngOnInit(): void {

    // Recupero l'utente loggato
    this.user = this.storageService.getUser();

    // Recupero i canali
    this.apiService.getCanaliHome().subscribe({
      next: (data) => this.canali = [...data.filter(canale => canale.videos > 0)],
      error: (err) => {
        this.loading = false;
        this.apiService.error("<p><strong>I Canali non sono stati caricati correttamente! </strong></p> <p>Ricaricare la pagina oppure aspettare qualche secondo. </p><p>In caso di errore continuo contattaci a <strong><a href='mailto:webtv@7live.tv'>webtv@7live.tv</a></strong>!</p>")
      },
      complete: () => {

        // Recupero le categorie
        this.apiService.getCategorieHome().subscribe({
          next: (data) => this.categorie = [...data],
          error: (err) => {
            this.loading = false;
            this.apiService.error("<p><strong>Le Categorie non sono state caricate correttamente! </strong></p> <p>Ricaricare la pagina oppure aspettare qualche secondo. </p><p>In caso di errore continuo contattaci a <strong><a href='mailto:webtv@7live.tv'>webtv@7live.tv</a></strong>!</p>")
          },
          complete: () => {

            // Recupero i video ondemand
            this.apiService.getOndemandsHome().subscribe({
              next: (data) => this.ondemands = [...data.filter(ondemand => ondemand.cloudflare_mp4_url != null)].sort(function (a, b) { return (a.abbonamento === b.abbonamento) ? 0 : a.abbonamento ? 1 : -1; }),
              error: (err) => {
                this.loading = false;
                this.apiService.error("<p><strong>I video Ondemand non sono stati caricati correttamente! </strong></p> <p>Ricaricare la pagina oppure aspettare qualche secondo. </p><p>In caso di errore continuo contattaci a <strong><a href='mailto:webtv@7live.tv'>webtv@7live.tv</a></strong>!</p>")
              },
              complete: () => {
                this.ondemands_filtrati = this.ondemands;
                this.loading = false;

                // Recupero i parametri passati nell'url
                this.route.params.subscribe(params => {
                  let id = params['id'];

                  this.video = id ? true : false;
                })

              }
            })

          }
        })
      }
    })

  }

  onOndemand(id: number) {    
    this.router.navigate(['/ondemand/' + btoa("id_ondemand=" + id)]);
  }

  onMostra(num: number) {
    this.num_ondemands += num;
  }

  onClosePlayer() {
    this.router.navigate(['/ondemand']);
  }

  onClearFiltri() {
    this.canaleFilter.setValue('-1');
    this.categoriaFilter.setValue('-1')
    this.titoloFilter.setValue('');
  }

  onFiltro() {

    // Recupero i dati dentro ai filtri
    let canale = this.canaleFilter.value !== '-1' ? this.canaleFilter.value : '';
    let categoria = this.categoriaFilter.value !== '-1' ? this.categoriaFilter.value : '';
    let titolo = this.titoloFilter.value ? this.titoloFilter.value.toLowerCase() : '';

    // Filtro gli ondemand in base ai filtri inseriti
    this.ondemands_filtrati = this.ondemands.filter(video => video.titolo.toLowerCase().indexOf(titolo) >= 0 && (video.id_canale).indexOf(canale || '') >= 0 && (video.id_categoria).indexOf(categoria || '') >= 0)
    this.num_ondemands = 9;

  }
}
