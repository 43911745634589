import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '../../../services/api.service';
import { StorageService } from '../../../services/storage.service';

@Component({
  selector: 'app-login-backoffice',
  templateUrl: './login-backoffice.component.html',
  styleUrls: ['./login-backoffice.component.css']
})
export class LoginBackofficeComponent implements OnInit {

  constructor(private apiService: ApiService, private storageService: StorageService, private router: Router) { }

  formLogin!: FormGroup;

  hide: boolean = false;
  error: boolean = false;

  error_messages = {
    'username': [
      { type: 'required', message: 'Inserire un username per accedere all`area di backoffice' },
    ],
    'password': [
      { type: 'required', message: 'Inserire una password per accedere all`area di backoffice' },
    ],
  }

  ngOnInit(): void {

    // Creo il form con i controlli
    this.formLogin = new FormGroup({
      username: new FormControl(null, Validators.required),
      password: new FormControl(null, Validators.required)
    })
  }

  onSubmit() {
    const username = this.formLogin.get('username')?.value;
    const password = this.formLogin.get('password')?.value;

    // Effettuo il login
    this.apiService.loginBackoffice(username, password).subscribe({
      next: (data) => {

        // Salvo nello storage i dati dell'utente 
        this.storageService.setTokenBackoffice(data.token);
        this.storageService.setUserBackoffice(data.data);
      },
      error: (err) => this.error = true,
      complete: () => this.router.navigate(['/backoffice/home'])

    })
  }

}
