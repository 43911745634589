import { Component, inject } from '@angular/core';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';
import { ApiService } from '../../../../services/api.service';

@Component({
  selector: 'app-elimina-utente-backoffice',
  templateUrl: './elimina-utente-backoffice.component.html',
  styleUrls: ['./elimina-utente-backoffice.component.css']
})
export class EliminaUtenteBackofficeComponent {

  constructor(private apiService: ApiService) { }

  loading: boolean = false;

  readonly #modal = inject(NzModalRef)
  readonly nzModalData = inject(NZ_MODAL_DATA);

  onElimina() {
    this.loading = true;

    // Elimino l'utente
    this.apiService.deleteUtente(this.nzModalData.id_utente!).subscribe({
      next: (data) => this.apiService.setUtenti(),
      error: (err) => {
        this.loading = false;
        this.apiService.error(err.error.message);
      },
      complete: () => {
        this.loading = false;
        this.#modal.destroy();
      }
    })

  }

  onClose() {
    this.#modal.destroy();
  }

}
