<nz-card [nzActions]="[actionClose, actionDelete]">
    <p> <strong> Sicuro di voler eliminare questo video Live?</strong></p>
    <p>Questa azione è permanente.</p>
</nz-card>
<ng-template #actionClose>
    <button type="button" nz-button nzSize="large" (click)="onClose()">Annulla</button>
</ng-template>
<ng-template #actionDelete>
    <button type="button" nz-button nzSize="large" [nzLoading]="loading" class="home-button"
        (click)="onElimina()">Elimina</button>
</ng-template>