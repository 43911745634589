import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment.development';
import { style, animate, transition, trigger } from '@angular/animations';
import { ApiService } from '../../services/api.service';
import { StorageService } from '../../services/storage.service';
import { CurrentVideo, Live } from '../../shared/interfacce.model';

import 'vidstack/player';
import 'vidstack/player/layouts/default';
import 'vidstack/player/ui';

@Component({
  selector: 'app-player-evidenza',
  templateUrl: './player-evidenza.component.html',
  styleUrls: ['./player-evidenza.component.css'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate(500, style({ opacity: 0 }))
      ])
    ])
  ]
})
export class PlayerEvidenzaComponent implements OnInit {

  constructor(private apiService: ApiService, private storageService: StorageService, private cdr: ChangeDetectorRef) { }

  url: string = environment.apiUrl;

  lives: Live[] = [];
  currentVideo?: CurrentVideo;

  carica: boolean = true;

  ngOnInit(): void {
    this.getData();
  }

  getData() {

    // Recupero le live del giorno
    this.apiService.getLivesHome().subscribe({
      next: (data) => {
        // Recupero le live raggruppate per id canale
        this.lives = [...new Map(data.map(live => [live['id_canale'], live])).values()];
      },
      error: (err) => this.apiService.error("<p><strong>I video live non sono stati caricati correttamente! </strong></p> <p>Ricaricare la pagina oppure aspettare qualche secondo. </p><p>In caso di errore continuo contattaci a <strong><a href='mailto:webtv@7live.tv'>webtv@7live.tv</a></strong>!</p>"),
      complete: () => {

        this.carica = false;

        if (this.lives.length > 0) {

          // Controllo se ci sono ancora live per questo canale
          let live_canale = this.lives.filter(live => live.id_canale == this.currentVideo?.id_canale);

          // Se è stato selezionato un canale e ci sono ancora live, continuo a far vedere live di questo canale          
          if (this.currentVideo?.id_canale != 0 && live_canale.length > 0) {

            // Recupero i dati per far partire l'hls
            this.getDataVideo(live_canale[0])

          } else {

            // Recupero i dati per far partire l'hls
            this.getDataVideo(this.lives[0])

          }
        }

      }

    })

  }

  async getDataVideo(live: Live) {

    // Seleziono come selezionata la live
    live.selected = true;

    // Recupero le informazioni e le imposto per il live in esposizione    
    this.currentVideo = { id: live.id, src: live.cloudflare_video_url, poster: live.cloudflare_image_url, durata: live.durata, titolo: live.titolo, id_canale: live.id_canale, canale: live.canale };

    this.cdr.detectChanges();

  }

  async onVideoChange(live: Live) {



     
    // Se il live non è stato selezionato procedo
    if (!live.selected) {

      // Ciclo per le live per cambiare il flag 'selected'
      this.lives.map(_live => {
        _live.selected = live.id === _live.id
      })

      // Recupero i dati del video
      await this.getDataVideo(live);
    }
  }

}