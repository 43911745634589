import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../../../services/api.service';
import { Categoria } from '../../../../shared/interfacce.model';

@Component({
  selector: 'app-modifica-categoria-backoffice',
  templateUrl: './modifica-categoria-backoffice.component.html',
  styleUrls: ['./modifica-categoria-backoffice.component.css']
})
export class ModificaCategoriaBackofficeComponent implements OnInit {

  constructor(private apiService: ApiService, private router: Router, private route: ActivatedRoute) { }

  formCategoria!: FormGroup;

  id: number = 0;
  categoria!: Categoria;

  loading: boolean = false;
  alertMessage: string = '';
  alertType: "success" | "info" | "warning" | "error" = 'success';
  alertVisible: boolean = false;

  ngOnInit(): void {

    // Creo il form con i controlli
    this.formCategoria = new FormGroup({
      titolo: new FormControl(null),
      descrizione: new FormControl(null),
      home: new FormControl(null)
    })

    // Recupero i parametri passati nell'url
    this.route.params.subscribe(params => {
      this.id = params['id'];

      // Recupero la categoria
      this.apiService.getCategoria(this.id).subscribe({
        next: (data) => this.categoria = data,
        error: (err) => this.router.navigate(['/backoffice/categorie']),
        complete: () => {

          // Setto i dati della categoria nel form
          this.formCategoria.setValue({
            titolo: this.categoria.titolo,
            descrizione: this.categoria.descrizione,
            home: this.categoria.home ? true : false
          })

        }
      })

    });

  }

  onAnnulla() {
    this.router.navigate(['/backoffice/categorie']);
  }

  onError(message: string) {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    this.alertMessage = message;
    this.alertVisible = true;
    this.alertType = 'error';
    this.loading = false;
  }

  onSubmit() {

    // Formo l'oggetto per la richiesta dell'api
    let categoria = {
      titolo: this.formCategoria.get('titolo')?.value,
      descrizione: this.formCategoria.get('descrizione')?.value,
      home: this.formCategoria.get('home')?.value || false,
      update: true
    }

    this.loading = true;

    // Aggiorno la categoria nel db
    this.apiService.updateCategoria(this.id, categoria).subscribe({
      next: (data) => this.apiService.setCategorie(),
      error: (err) => this.onError(err.error.message),
      complete: () => {
        this.loading = false;
        this.router.navigate(['/backoffice/categorie']);
      }
    })
  }

}
