import { Component, OnInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { style, animate, transition, trigger } from '@angular/animations';
import { Ondemand } from '../../shared/interfacce.model';
import { environment } from '../../../environments/environment.development';
import { ApiService } from '../../services/api.service';
import { MediaPlayerElement } from 'vidstack/elements';
import { VidstackPlayer, VidstackPlayerLayout } from 'vidstack/global/player';

@Component({
  selector: 'app-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.css'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(250, style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate(250, style({ opacity: 0 }))
      ])
    ])
  ]
})
export class PlayerComponent implements OnInit {

  constructor(private apiService: ApiService, private route: ActivatedRoute, private deviceService: DeviceDetectorService) { }

  currentVideo: { src: string, poster: string, titolo: string, canale: string, descrizione: string, categoria: string, data: Date } = { src: '', descrizione: '', poster: '', titolo: '', canale: '', categoria: '', data: new Date() };

  isMobile: boolean = false;
  isApple: boolean = false;

  loading: boolean = true;

  url: string = environment.apiUrl;  

  ondemand?: Ondemand;

  player?: MediaPlayerElement;

  ngOnInit(): void {
    
    // Recupero i parametri passati nell'url
    this.route.params.subscribe(params => {

      const string = params['id'];      
      const id = atob(string).split('id_ondemand=')[1];      
      
      if (id) {
        // Recupero i dati del video ondemand passato
        this.apiService.getOndemandHome(+id).subscribe({
          next: (data) => this.ondemand = data,
          error: () => this.apiService.error("<p><strong>Il video Ondemand non è stato caricato correttamente! </strong></p> <p>Ricaricare la pagina oppure aspettare qualche secondo. </p><p>In caso di errore continuo contattaci a <strong><a href='mailto:webtv@7live.tv'>webtv@7live.tv</a></strong>!</p>"),
          complete: () => {
            this.getDataVideo(this.ondemand!)
          }
        })   
      }   
    })
        

  }

  async getDataVideo(ondemand: Ondemand) {
  
    // Recupero il tipo di dispositivo
    this.isMobile = this.deviceService.isMobile();
    this.isApple = this.deviceService.os == 'iOS';

    // Recupero le informazioni e le imposto per il live in esposizione    
    this.currentVideo = { descrizione: ondemand.descrizione, src: ondemand.cloudflare_video_url, poster: ondemand.cloudflare_image_url, titolo: ondemand.titolo, canale: ondemand.canale, categoria: ondemand.categoria, data: ondemand.data_aggiornamento };    
    
    await this.onPlayer(this.currentVideo.titolo, this.currentVideo.src, this.currentVideo.poster)
    this.loading = false;

  }

  async onPlayer(titolo: string, src: string, poster: string) {
    
    if (this.player) {
      
      this.player.src = src;
      this.player.poster = poster;
      this.player.title = titolo;      

    } else {

      this.player = await VidstackPlayer.create({
        target: '#target',
        src: src,
        viewType: 'video',
        streamType: 'on-demand',
        crossOrigin: true,
        playsInline: true,        
        title: titolo,
        poster: poster,          
        loop: true,
        layout: new VidstackPlayerLayout({
          thumbnails: poster          
        }),
      });

      
    }

  }

}
