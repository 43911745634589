import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ApiService } from '../../../services/api.service';


@Component({
  selector: 'app-pagamento-profilo',
  templateUrl: './pagamento-profilo.component.html',
  styleUrls: ['./pagamento-profilo.component.css']
})
export class PagamentoProfiloComponent implements OnInit {

  constructor(private apiService: ApiService, private modalService: NzModalService, private router: Router, private route: ActivatedRoute, private datePipe: DatePipe) { }

  session: string = '';
  token: string = '';

  check?: { stripe_id: string, stripe_cus: string, stripe_scadenza: number, stripe_status: string, stripe_total: number, id_cliente: number }

  ngOnInit(): void {

    // Recupero i parametri passati nell'url
    this.route.queryParams.subscribe(params => {

      // Recupero la sessione e il token di pagamento
      this.session = params['session_id'];
      this.token = params['token']

      // Se entrambi i dati sono presenti procedo
      if (this.session && this.token) {

        // Controllo che il token sia corretto
        this.apiService.checkPagamento(this.token, this.session).subscribe({
          next: (data) => this.check = data,
          error: (err) => {
            this.modalService.error({
              nzTitle: 'Esito',
              nzContent: "<br>Un errore è stato riscontrato nella procedura! Aspettare qualche minuto e riprovare. <br><br><strong>" + err.error.message + "</strong>.",
              nzClosable: false,
              nzOkText: 'Ok',
              nzCentered: true,
              nzOnOk: () => this.router.navigate(['/profilo/abbonamento'])
            });
          },
          complete: () => {

            // Controllo dai dati che ho recuperato dal token siano presenti l'id cliente e che lo stato sia 'paid'
            if (this.check?.id_cliente && this.check?.stripe_status === 'paid') {

              // Formo l'oggetto per la richiesta dell'api
              let abbonamento = {
                prezzo: this.check?.stripe_total,
                id_cliente: this.check?.id_cliente,
                stripe_id: this.check?.stripe_cus,
                stripe_sub: this.check?.stripe_id,
                stripe_scadenza: this.check?.stripe_scadenza
              }

              // Aggiungo l'abbonamento al db
              this.apiService.addAbbonamento(abbonamento).subscribe({
                next: (data) => { },
                error: (err) => {
                  this.modalService.error({
                    nzTitle: 'Esito',
                    nzContent: "<br>Un errore è stato riscontrato nella procedura! Aspettare qualche minuto e riprovare. <br><br><strong>" + err.error.message + "</strong>.",
                    nzClosable: false,
                    nzOkText: 'Ok',
                    nzCentered: true,
                    nzOnOk: () => this.router.navigate(['/profilo/abbonamento'])
                  });
                },
                complete: () => {
                  this.modalService.success({
                    nzTitle: 'Esito',
                    nzContent: "<br> Grazie per esserti abbonato a Seven Live Web TV. <br><br> Il tuo abbonamento partirà in data odierna fino al giorno <strong>" + this.datePipe.transform(new Date(this.check!.stripe_scadenza * 1000).toISOString().replace(/T/, ' ').replace(/\..+/, ''), 'dd/MM/YYYY HH:mm:ss') + "</strong>. <br><br> Ora puoi vedere i nostri video On Demand.",
                    nzClosable: false,
                    nzOkText: 'Vai ai video On Demand',
                    nzCentered: true,
                    nzOnOk: () => this.router.navigate(['/ondemand'])
                  });
                }
              })
            } else {
              this.router.navigate(['/profilo/abbonamento'])
            }
          }

        })
      } else {
        this.router.navigate(['/profilo/abbonamento'])
      }

    })
  }


}
