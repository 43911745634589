<div class="lg:px-container px-8">

  <!-- LOGO MOBILE -->
  <div class="lg:hidden flex justify-center py-8">
    <img src="../../assets/images/Logo bianco.png" height="40px" alt="Logo Seven Live">
  </div>
  <!-- LOGO MOBILE -->

</div>
<!-- LIVE  -->
<div class="liveContainer flex items-center">
  <!-- BG GRADIENT  -->
  <div class="gradiente"></div>
  <!-- BG GRADIENT  -->

  <!-- PLAYER -->
  <div class="lg:px-container px-8 home-player lg:absolute lg:top-[40%] lg:left-[50%] w-full lg:translate-x-[-50%] lg:translate-y-[-40%]">

    <h3 class="text-white font-bold lg:text-5xl text-3xl mb-4">Live TV</h3>
    <p class="text-white text-xl mb-8">Sfoglia il palinsesto dei canali live</p>    

    <app-player-evidenza></app-player-evidenza>
  </div>
  <!-- PLAYER -->
</div>
<!-- LIVE  -->