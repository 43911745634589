import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { SwiperContainer } from 'swiper/element';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate(500, style({ opacity: 0 }))
      ])
    ])
  ]
})
export class SliderComponent implements OnInit {

  constructor(private cdr: ChangeDetectorRef) { }

  @Input('loop') loop: boolean = false;
  @Input('navigation') navigation: boolean = false;
  @Input('grabCursor') grabCursor: boolean = false;
  @Input('slidesPerView') slidesPerView: number = 1;
  @Input('spaceBetween') spaceBetween: string = '0px';
  @Input('breakpoints') breakpoints: any = {};
  @Input('sliderLength') sliderLength: number = 0;
  @Input('initialSlide') initialSlide: number = 0;

  @Input('evidenza') evidenza: boolean = false;

  @ViewChild('swiper') swiper!: ElementRef<SwiperContainer>;  

  index: number = 0;
  slides: number | "auto" | undefined = 0;

  ngOnInit(): void {    
  }

  ngAfterViewInit(): void {

    // Recupero il breakpoint
    const currentBreakpoint = this.swiper?.nativeElement.swiper.currentBreakpoint;

    // Se ho un breakpoint recupero le slide per view che ho impostato
    if (currentBreakpoint && this.swiper.nativeElement.swiper.params.breakpoints) {
      this.slides = this.swiper.nativeElement.swiper.params.breakpoints[currentBreakpoint].slidesPerView;
    } else {
      this.slides = this.swiper.nativeElement.swiper.params.slidesPerView as number;
    }

    // Setto l'index a quante slide sono presenti
    this.index = +this.slides! || 0;

    // Controllo quando viene effettuato un cambio slide tramite il drag
    this.swiper.nativeElement.swiper.on('slideChange', (swiper: any) => {

      // Recupero l'indice precedente
      let prevIndex = this.index - +this.slides! || 0;
      // Recupero il nuovo indice
      let currentIndex = swiper.activeIndex;

      // Se il nuovo indice è maggiore rispetto a quello vecchio sta andando avanti
      if (currentIndex > prevIndex) {
        this.index = this.index + (currentIndex - prevIndex);
      } else {
        this.index = this.index - (prevIndex - currentIndex);
      }

      // Segnalo al DOM che ci sono stati dei cambiamenti e quindi deve aggiornarsi
      this.cdr.detectChanges();

    });      

    this.cdr.detectChanges();
  }

  onNext() {
    this.swiper.nativeElement.swiper.slideNext();
  }

  onPrev() {
    this.swiper.nativeElement.swiper.slidePrev();
  }

}
