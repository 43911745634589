<div class="header-placeholder" *ngIf="!isCollapsed"></div>
<header class="header" *ngIf="!isCollapsed">
  <div class="flex h-full lg:px-container px-8 mx-auto header-content justify-between">
    <img src="../../../assets/images/Logo bianco.png" class="logo-image" routerLink="/home">
    <menu>
      <ul>
        <div class="pagine flex h-full">
          <li class="links" routerLinkActive="active-link" routerLink="/home">Home</li>
          <li class="links" routerLinkActive="active-link" routerLink="/live">Live</li>          
          <li class="links" routerLinkActive="active-link" [ngClass]="{'active-link': ondemandActive.isActive || abbonamentoActive.isActive, 'active-links': !ondemandActive.isActive}" routerLink="/ondemand">
            On Demand
          </li>
          <li class="links !hidden" routerLink="/ondemand" routerLinkActive #ondemandActive="routerLinkActive"></li>
          <li class="links !hidden" routerLink="/abbonamento" routerLinkActive #abbonamentoActive="routerLinkActive"></li>
          <li class="links" routerLinkActive="active-link" [routerLink]="user?.id ? '/profilo' : '/login'">{{user?.id ? 'Ciao, ' + user?.nome : 'Accedi'}}</li>          
        </div>                
      </ul>
    </menu>
  </div>
</header>

<div class="nav-mobile" *ngIf="isCollapsed">
  <div class="nav-item" routerLink="/live" routerLinkActive="active">
    <i class="material-icons">live_tv</i>
    <span>Live</span>
  </div>

  <div class="nav-item" routerLink="/ondemand" routerLinkActive="active" [ngClass]="{'active': ondemandMobileActive.isActive || abbonamentoMobileActive.isActive, 'active-links': !ondemandMobileActive.isActive }">
    <i class="material-icons">ondemand_video</i>
    <span>On Demand</span>
  </div>
  
  <div class="nav-item !hidden" routerLink="/ondemand" routerLinkActive #ondemandMobileActive="routerLinkActive"></div>
  <div class="nav-item !hidden" routerLink="/abbonamento" routerLinkActive #abbonamentoMobileActive="routerLinkActive"></div>

  <div class="nav-item" routerLink="/home" routerLinkActive="active">
    <i class="material-icons">home</i>
    <span>Home</span>
  </div>      
  <div class="nav-item" routerLink="/news" routerLinkActive="active">
    <i class="material-icons">newspaper</i>
    <span>News</span>
  </div>
  <div class="nav-item" routerLink="profilo" routerLinkActive="active">
    <i class="material-icons">person_pin</i>
    <span>Profilo</span>
  </div>
</div>
<div id="contenuto">
    <router-outlet></router-outlet>
    <app-cookies></app-cookies>
</div>
<app-footer></app-footer>