import { Component, inject } from '@angular/core';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';
import { ApiService } from '../../../../services/api.service';

@Component({
  selector: 'app-elimina-abbonamento-backoffice',
  templateUrl: './elimina-abbonamento-backoffice.component.html',
  styleUrls: ['./elimina-abbonamento-backoffice.component.css']
})
export class EliminaAbbonamentoBackofficeComponent {

  constructor(private apiService: ApiService) { }

  loading: boolean = false;  

  readonly #modal = inject(NzModalRef);
  readonly nzModalData = inject(NZ_MODAL_DATA);

  onElimina() {
    this.loading = true;

    // Elimino l'abbonamento
    this.apiService.deleteAbbonamento(this.nzModalData.id_abbonamento!).subscribe({
      next: (data) => this.apiService.setAbbonamenti(),
      error: (err) => {
        this.loading = false;
        this.apiService.error(err.error.message);
      }, complete: () => {
        this.loading = false;
        this.#modal.destroy();
      }
    })

  }

  onClose() {
    this.#modal.destroy();
  }

}
