import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '../../../../services/api.service';

@Component({
  selector: 'app-aggiungi-cliente-backoffice',
  templateUrl: './aggiungi-cliente-backoffice.component.html',
  styleUrls: ['./aggiungi-cliente-backoffice.component.css']
})
export class AggiungiClienteBackofficeComponent implements OnInit {

  constructor(private apiService: ApiService, private router: Router, private datePipe: DatePipe) { }

  formCliente!: FormGroup;
  hide: boolean = false;

  loading: boolean = false;
  alertMessage: string = '';
  alertType: "success" | "info" | "warning" | "error" = 'success';
  alertVisible: boolean = false;

  ngOnInit(): void {

    // Creo il cliente con i controlli
    this.formCliente = new FormGroup({
      email: new FormControl(null),
      password: new FormControl(null),
      nome: new FormControl(null),
      cognome: new FormControl(null),
      data_nascita: new FormControl(null),
      luogo_nascita: new FormControl(null),
      codice_fiscale: new FormControl(null),
      telefono: new FormControl(null),
      newsletter: new FormControl(null)
    });

  }

  onClose() {
    this.router.navigate(['/backoffice/clienti']);
  }

  onError(message: string) {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    this.alertMessage = message;
    this.alertVisible = true;
    this.alertType = 'error';
    this.loading = false;
  }

  onSubmit() {

    // Creo l'oggetto per la richiesta api
    let cliente = {
      email: this.formCliente.get('email')?.value,
      password: this.formCliente.get('password')?.value,
      nome: this.formCliente.get('nome')?.value,
      cognome: this.formCliente.get('cognome')?.value,
      data_nascita: this.datePipe.transform(this.formCliente.get('data_nascita')?.value, 'yyyy-MM-dd'),
      luogo_nascita: this.formCliente.get('luogo_nascita')?.value,
      codice_fiscale: this.formCliente.get('codice_fiscale')?.value,
      telefono: this.formCliente.get('telefono')?.value,
      newsletter: this.formCliente.get('newsletter')?.value || false
    }

    this.loading = true;

    // Aggiungo il cliente al db
    this.apiService.addCliente(cliente).subscribe({
      next: (data) => this.apiService.setClienti(),
      error: (err) => this.onError(err.error.message),
      complete: () => {
        this.loading = false;
        this.router.navigate(['/backoffice/clienti']);
      }
    })

  }

}
