<ng-template #liveTitle>        
    <div class="flex lg:flex-row flex-col items-center justify-between gap-4">
        <div class="lg:w-2/3 w-full">            
            <h3 class="text-white mb-0 text-xl font-semibold">Live</h3>
        </div>
        <div class="lg:w-1/3 w-full">
            <button nz-button nzType="primary" nzSize="large" (click)="onAdd()" class="home-button w-full">Aggiungi live</button>             
        </div>
    </div>        
    <div class="flex lg:flex-row flex-col gap-4 mt-4">            
        <div class="lg:w-1/2 w-full">
            <input nz-input placeholder="Cerca Titolo live" nzSize="large" [(ngModel)]="titoloFilter" />
        </div>              
        <div class="lg:w-1/2 w-full">
            <input nz-input placeholder="Cerca Canale live" nzSize="large" [(ngModel)]="canaleFilter" />
        </div>                                   
    </div>
</ng-template>
<nz-card [nzTitle]="liveTitle">    
    <nz-table #liveTable nzShowPagination class="tabella"
        [nzTotal]="(live | searchTitoloLive: titoloFilter | searchCanaleLive: canaleFilter).length"
        nzShowSizeChanger [nzPageSize]="10"
        [nzPageSizeOptions]="[5, 10, 25, 50, 100, 150, 200, 250, 300, 350, 400, 500, 750, 1000]"
        [nzData]="live | searchTitoloLive: titoloFilter | searchCanaleLive: canaleFilter"
        [nzBordered]="true" [nzNoResult]="'Nessuna live trovata'">
        <thead>
            <tr>                        
                <th [nzSortFn]="onOrdinamentoCanale" class="text-start">Canale</th>                        
                <th [nzSortFn]="onOrdinamentoTitolo">Titolo</th>
                <th class="!text-center">Azioni</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let live of liveTable.data | searchTitoloLive: titoloFilter | searchCanaleLive: canaleFilter">                        
                <td class="text-start">{{live.canale}}</td>                                                
                <td>{{live.titolo}}</td>
                <td class="!text-center">
                    <nz-button-group>                                
                        <button nz-button nz-dropdown [nzDropdownMenu]="menu" nzPlacement="bottomRight">
                            <span nz-icon nzType="menu"></span>
                        </button>
                    </nz-button-group>
                    <nz-dropdown-menu #menu="nzDropdownMenu">
                        <ul nz-menu>
                            <li nz-menu-item class="!p-4" (click)="onEdit(live.id)">Modifica live</li>                                      
                            <li nz-menu-item class="!p-4" (click)="onElimina(live.id)">Elimina live</li>
                        </ul>
                    </nz-dropdown-menu>
                </td>
            </tr>
        </tbody>
    </nz-table>                
</nz-card>