<div class="flex lg:flex-row flex-col gap-8 mt-3">
    <div class="lg:w-1/3 w-full my-auto">
        <img class="copertina" #img [src]="notizia.copertina_file.length > 0 ? notizia.copertina_file : '../../../assets/images/default_copertina.png'" (error)="img.src = '../../../assets/images/default_copertina.png'" alt="">
    </div>
    <div class="lg:w-2/3 w-full my-auto flex flex-col gap-2">
        <span class="w-fit inline-flex items-center rounded-md bg-accent px-2 py-1 font-medium ring-1 ring-inset ring-gray-500/10">{{notizia.data_creazione | date: 'dd/MM/yyyy'}}</span>
        <div class="titolo-news">
            <h4 class="inline lg:block text-white"><strong>{{notizia.titolo}}</strong></h4>
        </div>
        <p [ngStyle]="{'-webkit-line-clamp': notizia.espandi ? '99' : '3'}" [ngClass]="{'descrizione-news':notizia.descrizione.length > 400 && !notizia.espandi}" class="news-desc text-gray-400">
            {{ (notizia.descrizione.length > 400 && !notizia.espandi ) ? ( notizia.descrizione | slice: 0 : 400) + ' ...' : notizia.descrizione }}
        </p>
        <span class="lg:inline text-white" *ngIf="notizia.descrizione.length > 400">
            <a (click)="onExpand(notizia)" class="show-more-news">{{!notizia.espandi ? "Mostra di più" : "Mostra di meno"}}</a>
        </span>
    </div>
</div>
<nz-divider *ngIf="(index + 1) <= 5 && (index + 1) < notizie.length" class="my-8 news-divider"></nz-divider>