import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { ApiService } from '../../../../services/api.service';

@Component({
  selector: 'app-aggiungi-news-backoffice',
  templateUrl: './aggiungi-news-backoffice.component.html',
  styleUrls: ['./aggiungi-news-backoffice.component.css']
})
export class AggiungiNewsBackofficeComponent implements OnInit {

  constructor(private apiService: ApiService, private router: Router, private datePipe: DatePipe) { }

  formNews!: FormGroup;

  copertina: string = '';

  imageList: NzUploadFile[] = [];

  loading: boolean = false;
  alertMessage: string = '';
  alertType: "success" | "info" | "warning" | "error" = 'success';
  alertVisible: boolean = false;

  ngOnInit(): void {

    // Creo il form con i controlli
    this.formNews = new FormGroup({
      titolo: new FormControl(null),
      copertina: new FormControl(null),
      descrizione: new FormControl(null),
      data: new FormControl(null)
    })
  }

  onClose() {
    this.router.navigate(['/backoffice/news']);
  }

  beforeImageUpload = (file: any): boolean => {
    this.imageList = this.imageList.concat(file);

    // Controllo se l'immagine caricata ha il formato JPG o PNG
    const isJpgOrPng = this.imageList.length > 0 ? this.imageList[0]!.type === 'image/jpeg' || this.imageList[0]!.type === 'image/png' : true;

    // Se non ha il formato corretto scarto il file
    if (!isJpgOrPng) {
      this.imageList = [];
      this.onError("L'immagine deve essere di tipo JPG, PNG o JPEG")
      return false;
    } else {

      // Trasformo l'immagina caricata in base64 e lo salvo nel form
      const reader = new FileReader();
      reader.onload = () => {
        this.alertVisible = false;
        this.copertina = reader.result as string;
        this.formNews.patchValue({ copertina: file.name });
      }
      reader.readAsDataURL(file);

      return false;
    }
  };

  removeImageUpload = (file: any): boolean => {
    // Rimuovo il file dal form
    this.copertina = '';
    this.formNews.patchValue({ copertina: '' });
    this.imageList = [];
    return false;
  }

  onError(message: string) {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    this.alertMessage = message;
    this.alertVisible = true;
    this.alertType = 'error';
    this.loading = false;
  }

  onSubmit() {

    // Formo l'oggetto per la richiesta dell'api
    let news = {
      titolo: this.formNews.get('titolo')?.value,
      descrizione: this.formNews.get('descrizione')?.value,
      data: this.datePipe.transform(this.formNews.get('data')?.value, 'yyyy-MM-dd'),
      copertina: this.formNews.get('copertina')?.value,
      copertina_file: this.copertina,
    }

    this.loading = true;

    // Aggiungo la news al db
    this.apiService.addNews(news).subscribe({
      next: (data) => this.apiService.setNews(),
      error: (err) => this.onError(err.error.message),
      complete: () => {
        this.loading = false;
        this.router.navigate(['/backoffice/news']);
      }
    })

  }

}
