import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../../../services/api.service';

@Component({
  selector: 'app-modifica-password-cliente-backoffice',
  templateUrl: './modifica-password-cliente-backoffice.component.html',
  styleUrls: ['./modifica-password-cliente-backoffice.component.css']
})
export class ModificaPasswordClienteBackofficeComponent implements OnInit {

  constructor(private apiService: ApiService, private router: Router, private route: ActivatedRoute) { }

  formPassword!: FormGroup;
  hide: boolean = false;
  hide_confirm: boolean = false;

  id: number = 0;

  loading: boolean = false;
  alertMessage: string = '';
  alertType: "success" | "info" | "warning" | "error" = 'success';
  alertVisible: boolean = false;

  ngOnInit(): void {

    // Creo il form con i controlli
    this.formPassword = new FormGroup({
      password: new FormControl(null),
      confirm_password: new FormControl(null)
    });

    // Recupero i parametri passati nell'url
    this.route.params.subscribe(params => {
      this.id = params['id'];
    });
  }

  onClose() {
    this.router.navigate(['/backoffice/clienti']);
  }

  onError(message: string) {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    this.alertMessage = message;
    this.alertVisible = true;
    this.alertType = 'error';
    this.loading = false;
  }

  onSubmit() {

    // Formo l'oggetto per la richiesta api
    let password = {
      password: this.formPassword.get('password')?.value || '',
      confirm_password: this.formPassword.get('confirm_password')?.value || ''
    }

    this.loading = true;

    // Aggiorno la password del cliente
    this.apiService.updatePasswordCliente(this.id, password).subscribe({
      next: (data) => this.apiService.setClienti(),
      error: (err) => this.onError(err.error.message),
      complete: () => {
        this.loading = false;
        this.router.navigate(['/backoffice/clienti']);
      }
    })

  }

}
