import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { EliminaAbbonamentoBackofficeComponent } from './elimina-abbonamento-backoffice/elimina-abbonamento-backoffice.component';
import { SospendiAbbonamentoBackofficeComponent } from './sospendi-abbonamento-backoffice/sospendi-abbonamento-backoffice.component';
import { AttivaAbbonamentoBackofficeComponent } from './attiva-abbonamento-backoffice/attiva-abbonamento-backoffice.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Abbonamento } from '../../../shared/interfacce.model';
import { ApiService } from '../../../services/api.service';

@Component({
  selector: 'app-abbonamenti-backoffice',
  templateUrl: './abbonamenti-backoffice.component.html',
  styleUrls: ['./abbonamenti-backoffice.component.css']
})
export class AbbonamentiBackofficeComponent implements OnInit {

  abbonamenti: Abbonamento[] = [];    

  clienteFilter: any = '';
  statoFilter: any = '';

  constructor(private apiService: ApiService, private modal: NzModalService, private viewContainerRef: ViewContainerRef) { }

  ngOnInit(): void {      
    
    // Recupero gli abbonamenti
    this.apiService.setAbbonamenti();  
    this.apiService.abbonamentiChanged.subscribe({
      next: (data) => this.abbonamenti = data,
      error: (err) => this.apiService.error(err.error.message),
      complete: () => {}      
    });

  }
  
  onElimina(id: number) {        
    // Creo il modal per l'eliminazione dell'abbonamento
    this.modal.create({
      nzTitle: 'Conferma',
      nzContent: EliminaAbbonamentoBackofficeComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzData: {
        id_abbonamento: id
      },
      nzFooter: null,
      nzCentered: true,
      nzWidth: 750,
      nzBodyStyle: {'padding': '0px'}
    });
  }

  onAttiva(id: number, stripe_sub: string) {
    // Creo il modal per l'attivazione dell'abbonamento
    this.modal.create({
      nzTitle: 'Conferma',
      nzContent: AttivaAbbonamentoBackofficeComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzData: {
        id_abbonamento: id,
        stripe_sub: stripe_sub
      },
      nzFooter: null,
      nzCentered: true,
      nzWidth: 750,
      nzBodyStyle: {'padding': '0px'}
    });    
  }

  onSospendi(stripe_sub: string) {
    // Creo il modal per la sospensione dell'abbonamento
    this.modal.create({
      nzTitle: 'Conferma',
      nzContent: SospendiAbbonamentoBackofficeComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzData: {        
        stripe_sub: stripe_sub
      },
      nzFooter: null,
      nzCentered: true,
      nzWidth: 750,
      nzBodyStyle: {'padding': '0px'}
    });    
  }

  onOrdinamentoCliente(a: Abbonamento, b: Abbonamento) {    
    return a.cliente.localeCompare(b.cliente)
  }

  onOrdinamentoStato(a: Abbonamento, b: Abbonamento) {    
    return a.stato.localeCompare(b.stato)
  }

  onOrdinamentoDataCreazione(a: Abbonamento, b: Abbonamento) {    
    return a.data_creazione.toString().localeCompare(b.data_creazione.toString())
  }

  onOrdinamentoDataScadenza(a: Abbonamento, b: Abbonamento) {    
    return a.stripe_sub.toString().localeCompare(b.stripe_sub.toString())
  }


}
