import { Component, OnInit, Input, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { Ondemand } from '../../shared/interfacce.model';
import { environment } from '../../../environments/environment.development';

@Component({
  selector: 'app-video-thumbnail',
  templateUrl: './video-thumbnail.component.html',
  styleUrls: ['./video-thumbnail.component.css']
})
export class VideoThumbnailComponent implements OnInit, AfterViewInit {

  @ViewChild('img') img?: ElementRef<HTMLImageElement>;  
  @Input() ondemand!: Ondemand;  
  hover: boolean = false;

  url: string = environment.apiUrl;
  copertina_url: string = "";

  constructor() { }

  ngOnInit(): void {

    this.copertina_url = this.ondemand.copertina ? this.url + 'immagine/' + this.ondemand.copertina : this.ondemand.cloudflare_image_url;    

    let descLength = 64;
    if (this.ondemand.descrizione?.length > descLength) {
      this.ondemand.descrizione = this.ondemand.descrizione.slice(0, descLength) + '...';
    }
  }

  ngAfterViewInit(): void {
    this.img!.nativeElement.src = this.hover ? this.ondemand.cloudflare_preview_url : this.copertina_url;
  }


  onMouse(hover: boolean) {    
    this.hover = hover;
    this.img!.nativeElement.src = hover ? this.ondemand.cloudflare_preview_url : this.copertina_url;
  }

  getTime(sec: number) {    
    var hours: any   = Math.floor(sec / 3600);
    var minutes: any = Math.floor((sec - (hours * 3600)) / 60);
    var seconds: any = Math.floor(sec - (hours * 3600) - (minutes * 60));

    if (hours   < 10) {hours   = hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}
    return (hours != 0 ? hours+':' : '')+ (hours != 0 || minutes != 0 ? minutes+':' : '' )+seconds;
  }

}
