import { h } from 'preact';
import { UIPlugin } from '@uppy/core';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore We don't want TS to generate types for the package.json
const packageJson = {
  "version": "4.0.0"
};
// set default options, must kept in sync with @uppy/react/src/ProgressBar.js
const defaultOptions = {
  fixed: false,
  hideAfterFinish: true
};
/**
 * Progress bar
 *
 */
export default class ProgressBar extends UIPlugin {
  constructor(uppy, opts) {
    super(uppy, {
      ...defaultOptions,
      ...opts
    });
    this.id = this.opts.id || 'ProgressBar';
    this.title = 'Progress Bar';
    this.type = 'progressindicator';
    this.render = this.render.bind(this);
  }
  render(state) {
    const progress = state.totalProgress || 0;
    // before starting and after finish should be hidden if specified in the options
    const isHidden = (progress === 0 || progress === 100) && this.opts.hideAfterFinish;
    return h("div", {
      className: "uppy uppy-ProgressBar",
      style: {
        position: this.opts.fixed ? 'fixed' : 'initial'
      },
      "aria-hidden": isHidden
    }, h("div", {
      className: "uppy-ProgressBar-inner",
      style: {
        width: `${progress}%`
      }
    }), h("div", {
      className: "uppy-ProgressBar-percentage"
    }, progress));
  }
  install() {
    const {
      target
    } = this.opts;
    if (target) {
      this.mount(target, this);
    }
  }
  uninstall() {
    this.unmount();
  }
}
ProgressBar.VERSION = packageJson.version;