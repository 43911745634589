<ng-template #canaleTitle>
  <h3 class="text-white mb-0 text-xl font-semibold">Modifica live</h3>
</ng-template>
<nz-card [nzTitle]="canaleTitle" [nzActions]="[actionClose, actionEdit]">

  <form [formGroup]="formLive" (ngSubmit)="onSubmit()" id="formLive" class="flex flex-col gap-4">

    <nz-alert [nzType]="alertType" nzShowIcon [nzMessage]="alertMessage" *ngIf="alertVisible"></nz-alert>

    <div class="w-full">
      <nz-form-item>
        <nz-form-label nzRequired class="text-start">Canale</nz-form-label>
        <nz-form-control>
          <nz-select nzShowSearch nzAllowClear formControlName="id_canale" nzSize="large" class="w-full" (ngModelChange)="onCanale($event)">
            <nz-option *ngFor="let canale of canali" [nzValue]="canale.id" [nzLabel]="canale.nome"></nz-option>
            <nz-option [nzValue]="'0'" [nzLabel]="'Altro'" *ngIf="user.tipo == 'Amministratore'"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div class="w-full">
      <nz-form-item>
        <nz-form-label nzRequired class="tooltip-label" nzTooltipTitle="Seleziona prima un canale e poi un video On Demand che vuoi mostrare nel live!">Video</nz-form-label>
        <nz-form-control>
          <nz-select nzShowSearch nzAllowClear formControlName="id_ondemand" nzSize="large" class="w-full" [nzDisabled]="!formLive.get('id_canale')?.value">
            <nz-option *ngFor="let ondemand of ondemands" [nzValue]="ondemand.id" [nzLabel]="ondemand.titolo + ' | Durata: ' + toHHMMSS(ondemand.durata)"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div class="w-full">
      <nz-form-item>
        <nz-form-label class="text-start">Posizione</nz-form-label>
        <nz-form-control>
          <nz-select nzShowSearch nzAllowClear formControlName="posizione" nzSize="large" class="w-full">
            <nz-option nzValue="1" nzLabel="Mostra per prima"></nz-option>
            <nz-option *ngFor="let live of lives" [nzValue]="live.posizione" [nzLabel]="live.posizione + ' - ' + live.titolo"></nz-option>
            <nz-option nzValue="ultimo" nzLabel="Mostra per ultima"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
  </form>
</nz-card>

<ng-template #actionClose>
  <button nz-button nzSize="large" type="button" (click)="onClose()">Annulla</button>
</ng-template>
<ng-template #actionEdit>
  <span nzTooltipTitle="Prima di modificare il live, controllare che il giorno inserito sia maggiore o uguale alla data odierna!" nz-tooltip nzTooltipPlacement="top">
    <button nz-button nzSize="large" type="submit" class="home-button" form="formLive" [disabled]="!formLive.valid" [nzLoading]="loading">Modifica</button>
  </span>
</ng-template>