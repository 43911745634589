<ng-template #canaleTitle>
    <h3 class="text-white mb-0 font-semibold text-xl">Modifica On Demand</h3>
</ng-template>
<nz-card [nzTitle]="canaleTitle" [nzActions]="[actionClose, actionEdit]">

    <form [formGroup]="formOndemand" (ngSubmit)="onSubmit()" id="formOndemand" class="flex flex-col gap-4">

        <nz-alert nzType="error" nzShowIcon nzMessage="Per ragioni di compatibilità, assicurati che i video siano in formato mp4 (h264/aac)" [nzIcon]="iconAlert"></nz-alert>
        <ng-template #iconAlert><span nz-icon nzType="warning" nzTheme="outline"></span></ng-template>
        <nz-alert [nzType]="alertType" nzShowIcon [nzMessage]="alertMessage" *ngIf="alertVisible"></nz-alert>

        <div class="w-full">            
            <nz-form-item>
                <nz-form-label nzRequired>Titolo</nz-form-label>
                <nz-form-control>
                    <input nz-input nzSize="large" formControlName="titolo" required />
                </nz-form-control>
            </nz-form-item>            
        </div>
        <div class="w-full">            
            <nz-form-item>
                <nz-form-label>Descrizione</nz-form-label>
                <nz-form-control>
                    <textarea nz-input nzSize="large" formControlName="descrizione"></textarea>
                </nz-form-control>
            </nz-form-item>            
        </div>
        <div class="flex lg:flex-row flex-col gap-4">
            <div class="lg:w-1/2 w-full">
                <nz-form-item>
                    <nz-form-label nzRequired>Canale</nz-form-label>
                    <nz-form-control>
                        <nz-select nzShowSearch nzAllowClear formControlName="id_canale" nzMode="multiple" nzSize="large" class="w-full" required>
                            <nz-option *ngFor="let canale of canali" [nzValue]="canale.id" [nzLabel]="canale.nome"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div class="lg:w-1/2 w-full">
                <nz-form-item>
                    <nz-form-label nzRequired>Categoria</nz-form-label>
                    <nz-form-control>
                        <nz-select nzShowSearch nzAllowClear formControlName="id_categoria" nzMode="multiple" nzSize="large" class="w-full" required>
                            <nz-option *ngFor="let categoria of categorie" [nzValue]="categoria.id" [nzLabel]="categoria.titolo"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>        
        
        <label nz-checkbox formControlName="abbonamento" class="checkbox flex justify-center" nzTooltipTitle="Se selezionato, il video On Demand sarà disponibile alla visione solo tramite l'abbonamento!" nz-tooltip nzTooltipPlacement="top">Visione tramite abbonamento</label>        

        <div class="w-full">            
            <nz-tabset>
                <nz-tab nzTitle="* Video"> 
                    <div class="flex flex-col items-center gap-4">
                        <uppy-dashboard class="my-uppy-dashboard my-auto text-center w-full" [uppy]="uppy" [props]="{inline: true, theme: 'dark', width: '100%', height: 350, proudlyDisplayPoweredByUppy: false, hideUploadButton: true}"></uppy-dashboard>                    
                        <button nz-button nzSize="large" type="button" class="home-button" (click)="visible = !visible">{{visible ? 'Nascondi video caricato' : 'Mostra video caricato' }}</button>                    
                        <video [src]="ondemand?.cloudflare_mp4_url" [poster]="ondemand?.cloudflare_image_url" class="w-full aspect-[16/9]" [controls]="true" [autoplay]="false" *ngIf="visible"></video>                            
                    </div>
                </nz-tab>
                <nz-tab nzTitle="* Copertina">                        
                    <div class="flex flex-col items-center gap-4">
                        <nz-upload [(nzFileList)]="imageList" [nzShowButton]="imageList.length <= 0" [nzBeforeUpload]="beforeImageUpload" nzAccept="image/*" [nzRemove]="removeImageUpload" class="my-auto">
                            <button nzSize="large" nz-button type="button" class="home-button">
                                <span class="align-middle" nz-icon nzType="upload"></span>
                                Carica copertina
                            </button>
                        </nz-upload>  
                        <img #img [src]="copertina.length > 0 ? copertina : copertina_url" (error)="img.src = ondemand!.cloudflare_image_url" alt="Copertina" class="w-full" *ngIf="ondemand?.copertina || copertina">
                    </div>                                          
                                    
                </nz-tab>
            </nz-tabset>
        </div>        
    </form>
</nz-card>

<ng-template #actionClose>
    <button nz-button nzSize="large" type="button" (click)="onClose()">Annulla</button>
</ng-template>
<ng-template #actionEdit>
    <button nz-button nzSize="large" [nzLoading]="loading" type="submit" class="home-button"
        form="formOndemand">Modifica</button>
</ng-template>