<ng-template #clienteTitle>
    <h3 class="text-white mb-0 font-semibold text-xl">Aggiungi Cliente</h3>
</ng-template>    
<nz-card [nzTitle]="clienteTitle" [nzActions]="[actionClose, actionAdd]">    
    <form [formGroup]="formCliente" (ngSubmit)="onSubmit()" id="formCliente" class="flex flex-col gap-4">

        <nz-alert [nzType]="alertType" nzShowIcon [nzMessage]="alertMessage" *ngIf="alertVisible"></nz-alert>
        <div class="flex lg:flex-row flex-col gap-4">
            <div class="lg:w-1/2 w-full">
                <nz-form-item >
                    <nz-form-label nzRequired>Cognome</nz-form-label>
                    <nz-form-control>
                        <input nz-input nzSize="large" formControlName="cognome" required />                 
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div class="lg:w-1/2 w-full">
                <nz-form-item>
                    <nz-form-label nzRequired>Cognome</nz-form-label>
                    <nz-form-control>
                        <input nz-input nzSize="large" formControlName="nome" required/>               
                    </nz-form-control>
                </nz-form-item>                    
            </div>
        </div>
        <div class="flex lg:flex-row flex-col gap-4">
            <div class="lg:w-1/2 w-full">
                <nz-form-item>
                    <nz-form-label nzRequired>Email</nz-form-label>
                    <nz-form-control>
                        <input nz-input nzSize="large" formControlName="email" type="email" required />           
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div class="lg:w-1/2 w-full">
                <nz-form-item>
                    <nz-form-label nzRequired>Password</nz-form-label>
                    <nz-form-control>
                        <nz-input-group [nzSuffix]="passwordTemplate" nzSize="large">
                            <input [type]="hide ? 'text' : 'password'" nz-input formControlName="password" required />
                        </nz-input-group>
                        <ng-template #passwordTemplate>
                            <span nz-icon [nzType]="hide ? 'eye-invisible' : 'eye'" (click)="hide = !hide"></span>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>
        <div class="flex lg:flex-row flex-col gap-4">
            <div class="lg:w-1/2 w-full">
                <nz-form-item>
                    <nz-form-label nzRequired>Data di nascita</nz-form-label>
                    <nz-form-control>
                        <nz-date-picker formControlName="data_nascita" [nzMode]="'date'" nzSize="large" [nzFormat]="'dd/MM/yyyy'" class="w-full"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div class="lg:w-1/2 w-full">  
                <nz-form-item>
                    <nz-form-label>Luogo di nascita</nz-form-label>
                    <nz-form-control>
                        <input nz-input nzSize="large" formControlName="luogo_nascita" />
                    </nz-form-control>
                </nz-form-item>
            </div>                
        </div>
        <div class="flex lg:flex-row flex-col gap-4">
            <div class="lg:w-1/2 w-full">
                <nz-form-item>
                    <nz-form-label>Codice fiscale</nz-form-label>
                    <nz-form-control>
                        <input nz-input nzSize="large" formControlName="codice_fiscale" />                                   
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div class="lg:w-1/2 w-full">
                <nz-form-item>
                    <nz-form-label>Telefono</nz-form-label>
                    <nz-form-control>
                        <input nz-input nzSize="large" formControlName="telefono" type="tel" />                                   
                    </nz-form-control>
                </nz-form-item>                                       
            </div>
        </div>                        
        <label nz-checkbox formControlName="newsletter" class="checkbox flex justify-center" nzTooltipTitle="Se selezionato, il cliente accetta di ricevere di ricevere mail dalla Newsletter!" nz-tooltip nzTooltipPlacement="top">Newsletter</label>
    </form>
</nz-card>    
<ng-template #actionClose>
    <button type="button" nz-button nzSize="large" (click)="onClose()">Annulla</button>
</ng-template>
<ng-template #actionAdd>
    <button nz-button nzSize="large" [nzLoading]="loading" type="submit" class="home-button" form="formCliente" [disabled]="!formCliente.valid">Aggiungi</button>        
</ng-template>