
<nz-card [nzActions]="[actionClose, actionDelete]">
    <p> <strong> Sicuro di voler {{nzModalData.tipo == 'icona' ? "l'icona" : "la copertina"}} di questo canale?</strong></p>        
    <p>Questa azione è permanente.</p>
</nz-card>
<ng-template #actionClose>
    <button type="button" nz-button nzSize="large" (click)="onClose()">Annulla</button>
</ng-template>
<ng-template #actionDelete>
    <button nz-button type="button" [nzSize]="'large'" class="home-button" [nzLoading]="loading" (click)="onElimina()">Elimina</button>
</ng-template>