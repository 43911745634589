<div class="flex lg:flex-row flex-col">
    <div class="lg:w-2/3 w-full">        
        <div id="player-twitch" class="player flex relative z-[1] h-full">
            <div class="controls" id="controls" *ngIf="controlli" [@fadeInOut] (click)="triggerPlay()" (dblclick)="onFullScreen()">                
                <h3 class="mb-0 ms-4 mt-4 text-white testo crop" [attr.title]="currentVideo.titolo">{{currentVideo.titolo}}</h3>                
                <div class="fondo">
                    <div class="flex justify-between items-center">
                        <div class="sx flex items-center">
                            <div class="stamp">
                                <span class="badge bg-danger">LIVE</span>
                            </div>
                            <div class="flex items-center ms-4">
                                <i class="bi fs-4" [class.bi-play-fill]="player.isPaused()" [class.bi-pause-fill]="player.isPaused() == false" (click)="togglePause()"></i>
                            </div>
                            <div class="volume flex items-center">
                                <span class="ms-3 me-2"><i class="bi fs-4" (click)="onMute()" [class.bi-volume-mute-fill]="player.muted" [class.bi-volume-up-fill]="!player.muted"></i></span>
                                <div class="volume_wrapper flex items-center relative w-[100px]">
                                    <input class="volume_slider" type="range" min="0" max="100" (input)="onVolume($event)" (change)="onVolume($event)" [value]="volume">
                                    <div class="progress" [style.width]="volume + '%'"></div>
                                    <div class="back"></div>
                                </div>
                            </div>
                        </div>
                        <div class="dx flex items-center">
                            <div class="tasti flex items-center">
                                <span class="ms-3 me-1"><i class="bi fs-5" [ngClass]="{'bi-fullscreen':!isFScreen,'bi-fullscreen-exit':isFScreen}" (click)="onFullScreen()"></i></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="twitch-player_{{id}}" class="w-full"></div>
        </div>                                                      
    </div>
    <div class="lg:w-1/3 w-full lista lg:block hidden">
        <ul class="player-list">
            <p>In diretta ora:</p>
            <li *ngFor="let live of lives;" [ngClass]="{'selected': live.selected}">
                <div class="flex lg:flex-row flex-col">
                    <div class="lg:w-1/3 w-full">
                        <img #icona [src]="live.icona ? url + 'immagine/' + live.icona : '../../../assets/images/Logo bianco.png'" (error)="icona.src = '../../../assets/images/Logo bianco.png'" class="watermark" alt="Icona">
                    </div>
                    <div class="lg:w-2/3 w-full my-auto" [attr.title]="live.titolo">
                        {{ live.titolo }}
                    </div>
                </div>
            </li>

            <li *ngIf="lives.length <= 0">Nessun live presente</li>
        </ul>
    </div>
</div>
<div class="lives-list lg:hidden block" *ngIf="lives.length > 0">
    <div class="playerlist-icon" *ngFor="let live of lives" (click)="onVideoChange(live)" [ngClass]="{'selected-mobile': live.selected}">
        <img #icona_mobile [src]="live.icona ? url + 'immagine/' + live.icona : '../../../assets/images/Logo bianco.png'" (error)="icona_mobile.src = '../../../assets/images/Logo bianco.png'" class="watermark" alt="Icona">
    </div>
</div>