<ng-template #clienteTitle>
    <h3 class="text-white mb-0 font-semibold text-xl">Modifica password</h3>
</ng-template>    
<nz-card [nzTitle]="clienteTitle" [nzActions]="[actionClose, actionEdit]">

    <form [formGroup]="formPassword" (ngSubmit)="onSubmit()" id="formPassword" class="flex flex-col gap-4">
        <nz-alert [nzType]="alertType" nzShowIcon [nzMessage]="alertMessage" *ngIf="alertVisible"></nz-alert>

        <div class="flex lg:flex-row flex-col gap-4">                
            <div class="lg:w-1/2 w-full">
                <nz-form-item>
                    <nz-form-label nzRequired>Nuova Password</nz-form-label>
                    <nz-form-control>
                        <nz-input-group [nzSuffix]="passwordTemplate" nzSize="large">
                            <input [type]="hide ? 'text' : 'password'" nz-input formControlName="password" required />
                        </nz-input-group>
                        <ng-template #passwordTemplate>
                            <span nz-icon [nzType]="hide ? 'eye-invisible' : 'eye'" (click)="hide = !hide"></span>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div class="lg:w-1/2 w-full">
                <nz-form-item>
                    <nz-form-label nzRequired>Conferma Password</nz-form-label>
                    <nz-form-control>
                        <nz-input-group [nzSuffix]="passwordTemplate" nzSize="large">
                            <input [type]="hide_confirm ? 'text' : 'password'" nz-input formControlName="confirm_password" required />
                        </nz-input-group>
                        <ng-template #passwordTemplate>
                            <span nz-icon [nzType]="hide_confirm ? 'eye-invisible' : 'eye'" (click)="hide_confirm = !hide_confirm"></span>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>       
    </form>
</nz-card>
<ng-template #actionClose>
    <button type="button" nz-button nzSize="large" (click)="onClose()">Annulla</button>
</ng-template>
<ng-template #actionEdit>
    <button nz-button nzSize="large" [nzLoading]="loading" type="submit" class="home-button" form="formPassword">Modifica</button>        
</ng-template>