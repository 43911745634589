<div class="registrazione flex items-center lg:flex-row bg-image">    
    <div class="lg:w-1/2 lg:flex hidden"></div>
    <div class="lg:w-1/2 w-full h-full flex items-center lg:px-16 p-8 bg-light registrazione">
        <div class="form mx-auto">

            <h3 class="text-4xl font-bold mb-4">Registrati</h3>
            <p class="text-muted mb-8">Registrati a Seven Live Web TV per poter accedere ai nostri fantastici video!</p>                    

            <form [formGroup]="formRegistrazione" (ngSubmit)="onRegistrazione()" class="flex flex-col gap-4">
                <nz-alert [nzType]="alertType" nzShowIcon [nzMessage]="alertMessage" *ngIf="alertVisible"></nz-alert>                        
                <div class="w-full forms-inputs"> 
                    <span>* Email</span> 
                    <input nz-input type="text" type="email" formControlName="email">
                </div>      
                <div class="flex lg:flex-row flex-col gap-4">
                    <div class="lg:w-1/2 w-full forms-inputs">
                        <span>* Password</span>
                        <nz-input-group [nzSuffix]="suffixTemplate">
                            <input [type]="hide ? 'text' : 'password'" nz-input formControlName="password" />
                        </nz-input-group>
                        <ng-template #suffixTemplate> <span nz-icon [nzType]="hide ? 'eye-invisible' : 'eye'" (click)="hide = !hide"></span></ng-template>                                    
                    </div>
                    <div class="lg:w-1/2 w-full forms-inputs">
                        <span>* Conferma Password</span>
                        <nz-input-group [nzSuffix]="suffixTemplateConfirm">
                            <input [type]="hide_confirm ? 'text' : 'password'" nz-input formControlName="confirm_password" />
                        </nz-input-group>
                        <ng-template #suffixTemplateConfirm> <span nz-icon [nzType]="hide_confirm ? 'eye-invisible' : 'eye'" (click)="hide_confirm = !hide_confirm"></span></ng-template>                                    
                    </div>
                </div>
                <div class="flex lg:flex-row flex-col gap-4">
                    <div class="lg:w-1/2 w-full forms-inputs">
                        <span>* Cognome</span>
                        <input nz-input formControlName="cognome">
                    </div>
                    <div class="lg:w-1/2 w-full forms-inputs">
                        <span>* Nome</span> 
                        <input nz-input formControlName="nome">
                    </div>
                </div>
                <div class="flex lg:flex-row flex-col gap-4">
                    <div class="lg:w-1/2 w-full flex flex-col forms-inputs">                        
                        <span>Data di nascita <i>(opzionale)</i></span>
                        <nz-date-picker name="data_nascita" formControlName="data_nascita" [nzPlaceHolder]="'gg/mm/aaaa'" [nzDisabledDate]="disabledDate" nzFormat="dd/MM/yyyy"></nz-date-picker>
                    </div>
                    <div class="lg:w-1/2 w-full forms-inputs">
                        <span>Luogo di nascita <i>(opzionale)</i></span> 
                        <input nz-input placeholder="Torino" formControlName="luogo_nascita">                                    
                    </div>
                </div>
                <div class="flex lg:flex-row flex-col gap-4">
                    <div class="lg:w-1/2 w-full forms-inputs">
                        <span>Codice fiscale <i>(opzionale)</i></span> 
                        <input nz-input formControlName="codice_fiscale">                                    
                    </div>
                    <div class="lg:w-1/2 w-full forms-inputs">
                        <span>Telefono <i>(opzionale)</i></span> 
                        <input nz-input formControlName="telefono">                                    
                    </div>
                </div>                            
                <div class="checkbox-container">                                            
                    <label nz-checkbox formControlName="newsletter" class="checkbox">Accetto di ricevere mail dalla NewsLetter</label> 
                </div>
                <div class="checkbox-container">                                            
                    <label nz-checkbox formControlName="privacy" class="checkbox cursor-pointer">Accetto i termini e uso della <strong routerLink="/privacy" class="underline">Privacy & Policy</strong> di Seven Live Web TV</label> 
                </div>                            
                <div class="mt-4 text-center">
                    <button class="lg:w-2/3 w-full bottone" nz-button type="submit" nzSize="large" [disabled]="!formRegistrazione.valid" [nzLoading]="loading">Registrati</button>
                    <nz-divider class="text-black" nzText="oppure"></nz-divider>
                    <button class="lg:w-2/3 w-full bottone-secondario" nz-button type="button" routerLink="/login">Accedi</button>
                </div>                        
            </form>                                    
        
        </div>
    </div>
</div>