<ng-template #canaleTitle>
    <h3 class="text-white mb-0 text-xl font-semibold">Modifica canale</h3>
</ng-template>    
<nz-card [nzTitle]="canaleTitle" [nzActions]="[actionClose, actionEdit]">        
    <form [formGroup]="formCanale" (ngSubmit)="onSubmit()" id="formCanale" class="flex flex-col gap-4">
        <nz-alert [nzType]="alertType" nzShowIcon [nzMessage]="alertMessage" *ngIf="alertVisible"></nz-alert>
        
        <nz-form-item>
            <nz-form-label nzRequired>Nome</nz-form-label>
            <nz-form-control>
                <input nz-input nzSize="large" formControlName="nome" required />
            </nz-form-control>
        </nz-form-item>                
        <nz-form-item>
            <nz-form-label>Descrizione</nz-form-label>
            <nz-form-control>
                <textarea nz-input nzSize="large" formControlName="descrizione"></textarea>
            </nz-form-control>
        </nz-form-item>                                            
        <label nz-checkbox formControlName="evidenza" class="checkbox flex justify-center" nzTooltipTitle="Se selezionato, il canale verrà inserito in Home Page nella lista dei canli in evidenza!" nz-tooltip nzTooltipPlacement="top">Canale in evidenza</label>                     
        <nz-tabset>
            <nz-tab nzTitle="Icona">
                <div class="flex lg:flex-row flex-col gap-4">
                    <div class="my-auto text-center" [ngClass]="{'lg:w-1/2': canale?.icona, 'w-full': !canale?.icona}">
                        <nz-upload [(nzFileList)]="fileIconaList" [nzShowButton]="fileIconaList.length <= 0" [nzBeforeUpload]="beforeIconaUpload" nzAccept="image/*" [nzRemove]="removeIconaUpload">
                            <button nzSize="large" nz-button type="button" class="home-button">
                                <span class="align-middle" nz-icon nzType="upload"></span> Carica icona
                            </button>
                        </nz-upload>                                                                                                       
                    </div>
                    <div class="lg:w-1/2 w-full text-center" *ngIf="canale?.icona">
                        <button nz-button [nzSize]="'large'" type="button" class="home-button" [nzLoading]="loading" (click)="onDelete('icona', canale!.icona)">Rimuovi icona</button>
                    </div>
                </div>         
                <div class="flex justify-center w-full">
                    <img [src]="icona.length > 0 ? icona : icona_url" *ngIf="canale?.icona || icona" alt="Icona" class="mt-4 w-[200px] h-auto my-auto text-center">                
                </div>                             
            </nz-tab>
            <nz-tab nzTitle="Copertina">
                <div class="flex lg:flex-row flex-col">
                    <div class="my-auto text-center" [ngClass]="{'lg:w-1/2': canale?.copertina, 'w-full': !canale?.copertina}">
                        <nz-upload [(nzFileList)]="fileCopertinaList" [nzShowButton]="fileCopertinaList.length <= 0" [nzBeforeUpload]="beforeCopertinaUpload" nzAccept="image/*" [nzRemove]="removeCopertinaUpload">
                            <button nzSize="large" nz-button type="button" class="home-button">
                                <span class="align-middle" nz-icon nzType="upload"></span>
                                Carica copertina
                            </button>
                        </nz-upload>
                    </div>
                    <div class="lg:w-1/2 w-full text-center" *ngIf="canale?.copertina">
                        <button nz-button [nzSize]="'large'" type="button" class="home-button" [nzLoading]="loading" (click)="onDelete('copertina', canale!.copertina)">Rimuovi copertina</button>
                    </div>
                </div>   
                <div class="flex justify-center w-full">
                    <img [src]="copertina.length > 0 ? copertina : copertina_url" *ngIf="canale?.copertina || copertina" alt="Copertina" class="mt-4 w-full my-auto">                
                </div>                                         
            </nz-tab>                            
        </nz-tabset>
    </form>
</nz-card>
<ng-template #actionClose>
    <button nz-button nzSize="large" type="button" (click)="onAnnulla()">Annulla</button>
</ng-template>
<ng-template #actionEdit>
    <button nz-button nzSize="large" type="submit" class="home-button" form="formCanale" [nzLoading]="loading">Modifica</button>
</ng-template>      