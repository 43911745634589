import { Component, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { ApiService } from '../../../../services/api.service';

@Component({
  selector: 'app-modal-aggiungi-canale-backoffice',
  templateUrl: './modal-aggiungi-canale-backoffice.component.html',
  styleUrls: ['./modal-aggiungi-canale-backoffice.component.css']
})
export class ModalAggiungiCanaleBackofficeComponent implements OnInit {

  constructor(private apiService: ApiService) { }

  readonly #modal = inject(NzModalRef);

  formCanale!: FormGroup;

  hide: boolean = false;
  icona: string = '';
  copertina: string = ''

  fileIconaList: NzUploadFile[] = [];
  fileCopertinaList: NzUploadFile[] = [];

  loading: boolean = false;
  alertMessage: string = '';
  alertType: "success" | "info" | "warning" | "error" = 'success';
  alertVisible: boolean = false;

  ngOnInit(): void {

    // Formo il form con i canali
    this.formCanale = new FormGroup({
      nome: new FormControl(null),
      descrizione: new FormControl(null),
      icona: new FormControl(null),
      copertina: new FormControl(null),
      evidenza: new FormControl(null)
    })

  }

  beforeIconaUpload = (file: any): boolean => {
    this.fileIconaList = this.fileIconaList.concat(file);

    // Controllo se l'immagine caricata ha il formato JPG o PNG
    const isJpgOrPng = this.fileIconaList.length > 0 ? this.fileIconaList[0]!.type === 'image/jpeg' || this.fileIconaList[0]!.type === 'image/png' : true;

    // Se non ha il formato corretto scarto il file
    if (!isJpgOrPng) {
      this.fileIconaList = [];
      this.onError("L'immagine di copertina deve essere di tipo JPG, PNG o JPEG")
      return false;
    } else {

      // Trasformo l'immagina caricata in base64 e lo salvo nel form
      const reader = new FileReader();
      reader.onload = () => {
        this.alertVisible = false;
        this.icona = reader.result as string;
        this.formCanale.patchValue({ icona: file.name });
      }
      reader.readAsDataURL(file);

      return false;
    }
  };

  beforeCopertinaUpload = (file: any): boolean => {
    this.fileCopertinaList = this.fileCopertinaList.concat(file);

    // Controllo se l'immagine caricata ha il formato JPG o PNG
    const isJpgOrPng = this.fileCopertinaList.length > 0 ? this.fileCopertinaList[0]!.type === 'image/jpeg' || this.fileCopertinaList[0]!.type === 'image/png' : true;

    // Se non ha il formato corretto scarto il file
    if (!isJpgOrPng) {
      this.fileCopertinaList = [];
      this.onError("L'immagine di copertina deve essere di tipo JPG, PNG o JPEG")
      return false;
    } else {

      // Trasformo l'immagina caricata in base64 e lo salvo nel form
      const reader = new FileReader();
      reader.onload = () => {
        this.alertVisible = false;
        this.copertina = reader.result as string;
        this.formCanale.patchValue({ copertina: file.name });
      }
      reader.readAsDataURL(file);

      return false;
    }
  };

  removeIconaUpload = (file: any): boolean => {
    // Rimuovo il file dal form
    this.formCanale.patchValue({ icona: '' });
    this.fileIconaList = [];
    this.icona = '';
    return false;
  }

  removeCopertinaUpload = (file: any): boolean => {
    // Rimuovo il file dal form
    this.formCanale.patchValue({ copertina: '' });
    this.copertina = '';
    this.fileCopertinaList = [];
    return false;
  }

  onAnnulla() {
    this.#modal.destroy();
  }

  onError(message: string) {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    this.alertMessage = message;
    this.alertVisible = true;
    this.alertType = 'error';
    this.loading = false;
  }

  onSubmit() {

    // Creo l'oggetto per la richiesta api
    let canale = {
      nome: this.formCanale.get('nome')?.value,
      descrizione: this.formCanale.get('descrizione')?.value,
      icona: this.formCanale.get('icona')?.value,
      icona_file: this.icona,
      copertina: this.formCanale.get('copertina')?.value,
      copertina_file: this.copertina,
      evidenza: this.formCanale.get('evidenza')?.value
    }

    this.loading = true;

    // Aggiungo il canale al db
    this.apiService.addCanale(canale).subscribe({
      next: (data) => this.apiService.setCanali(),
      error: (err) => {
        this.loading = false;
        this.onError(err.error.message)
      },
      complete: () => {
        this.loading = false;
        this.#modal.destroy();
      }
    })
  }

}
