<nz-card [nzActions]="[actionClose, actionSuspend]">
    <p> <strong> Sicuro di voler sospendere questo abbonamento?</strong></p>
    <p>Il cliente potrà ancora usufruire dei servizi On Demand ma alla scadenza del contratto, l'abbonamento verrà eliminato!</p>
    <p>Questa azione è permanente.</p>
</nz-card>
<ng-template #actionClose>
    <button type="button" nz-button nzSize="large" (click)="onClose()">Annulla</button>
</ng-template>
<ng-template #actionSuspend>
    <button nz-button type="button" [nzSize]="'large'" class="home-button" [nzLoading]="loading" (click)="onSospendi()">Sospendi</button>
</ng-template>