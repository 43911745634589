<div class="contenuto lg:px-container p-8 flex-col">
    <div class="privacy text-justify">
        <h3 class="text-center font-bold uppercase text-2xl my-8">INFORMATIVA PRIVACY</h3>
        <h5>Nel seguente documento è riportata l’informativa privacy per gli utenti registrati ai
            servizi Seven Live Web TV.</h5>
        <p>L’informativa viene redatta sulla base del principio della trasparenza e di tutti gli elementi richiesti dal
            GDPR (Garante per la Protezione dei Dati personali).</p>
        <p>Il Titolare del trattamento (chi decide “perché” e “come” devono essere trattati i dati personali raccolti) è
            Seven Live Web TV SRL con sede legale in Giaveno (TO) , Via del Parco Abbaziale, 15.  Ai sensi dell’art. 38,
            par. 4, hai la possibilità
            di contattare Seven Live Web TV SRL, per tutte le questioni relative al trattamento dei tuoi dati personali
            e all’esercizio dei tuoi diritti previsti dal GDPR, a questo indirizzo di posta elettronica: <a
                href="mailto:infoprivacy@7live.tv">infoprivacy&#64;7live.tv</a>.</p>
        <p>I tuoi dati personali verranno trattati per le seguenti finalità:</p>
        <ul>
            <li>a) registrazione ai servizi Seven Live Web TV;</li>
            <li>b) suggerimenti e assistenza tecnica al Servizio. In alcuni casi per ricevere suggerimenti o
                segnalazioni dagli utenti su dei malfunzionamenti dei nostri prodotti acquisiremo informazione tecniche
                come: il Mac Address o ID del device,
                l’indirizzo IP da cui navighi i nostri siti o utilizzi le applicazioni mobile, il sistema operativo, la
                tipologia del tuo device e l’email;</li>
            <li>c) invio di contenuti multimediali (foto, video, audio, ecc.) inviati attraverso siti, app mobile,
                social, community, redazioni, email, form, ecc.;</li>
            <li>d) partecipazione ad attività editoriali web e televisive:  invio di commenti a trasmissioni. invio di
                commenti sui siti, app e social;  invio di mail, richiesta informazioni tramite form;  partecipazione a
                sondaggi in forma anonima</li>
            <li>e) gestione di eventuali reclami e contestazioni.</li>
        </ul>
        <p>La raccolta dei tuoi dati è necessaria per il conseguimento delle finalità di cui sopra. La tua mancata,
            parziale o inesatta trasmissione dei dati potrebbe avere come conseguenza l’impossibilità di attivare e
            fornire il servizio richiesto.</p>
        <h5>A quali soggetti potranno essere comunicati i tuoi Dati Personali?</h5>
        <p>I tuoi dati personali potranno essere gestiti, per conto del Titolare, esclusivamente da personale
            espressamente autorizzato al trattamento (ai sensi
            dell’art. 29 GDPR) e da soggetti terzi espressamente nominati quali Responsabili del trattamento (ai sensi
            dell’art. 28 GDPR) al fine di svolgere correttamente tutte le attività di Trattamento necessarie a
            perseguire le finalità di
            cui alla presente Informativa. I tuoi dati personali potranno essere comunicati ad enti pubblici o
            all’autorità giudiziaria, ove richiesto per legge o per prevenire o reprimere la commissione di un reato. I
            tuoi dati personali verranno
            conservati e gestiti nei nostri sistemi digitali e nei nostri archivi per il tempo strettamente necessario
            al conseguimento delle finalità di cui sopra, fatto salvo un ulteriore periodo di conservazione che potrà
            essere imposto da
            obblighi di legge.
        </p>
        <p>I tuoi Dati Personali saranno gestiti da Seven Live Web TV SRL all’interno del territorio dell’Unione
            Europea. Ti ricordiamo che potrai esercitare i tuoi diritti previsti dal GDPR e in particolare di ottenere:
        </p>
        <ul>
            <li>la conferma che sia o meno in corso un trattamento dei tuoi dati personali e di ottenere l’accesso ai
                dati e alle seguenti informazioni: finalità del Trattamento, categorie di Dati personali, destinatari
                e/o categorie di destinatari
                a cui i dati sono stati e/o saranno comunicati e periodo di conservazione; </li>
            <li>la rettifica dei tuoi dati personali inesatti e/o l’integrazione dei Dati personali incompleti, anche
                fornendo una dichiarazione integrativa; </li>
            <li>la cancellazione dei tuoi dati personali, nei casi previsti dal GDPR;</li>
            <li>la limitazione al trattamento nelle ipotesi previste dalla Normativa Privacy vigente;</li>
            <li>la portabilità dei tuoi dati personali ed, in particolare, richiedere i dati personali forniti al
                Titolare e/o richiedere la trasmissione diretta dei tuoi dati ad un altro titolare del trattamento;
            </li>
            <li>l’opposizione al trattamento in qualsiasi momento, per motivi connessi alla tua situazione particolare,
                al trattamento dei tuoi dati personali nel pieno rispetto della Normativa Privacy Vigente.</li>
        </ul>
        <p>Potrai esercitare i tuoi diritti rivolgendoti alla seguente casella di posta elettronica <a
                href="mailto:infoprivacy@7live.tv">infoprivacy&#64;7live.tv</a> allegando copia del documento
            d’identità.</p>
        <p>In ogni caso avrai sempre diritto di proporre reclamo all’autorità di controllo competente (Garante per la
            Protezione dei Dati personali), ai sensi dell’art. 77 GDPR, qualora ritieni che il trattamento dei tuoi dati
            personali sia contrario
            alla Normativa Privacy vigente.</p>
        <p>Le informazioni vengono utilizzate per migliorare i servizi che ti forniamo rendendoli più semplici da
            utilizzare, proponendoti contenuti che ti possono interessare, mostrandoti informazioni e pubblicità più
            vicine ai tuoi interessi e
            meno ripetitive, tenendo sempre conto delle tue opinioni e delle indicazioni che ci vorrai dare per produrre
            programmi per te più utili e interessanti. Quindi, ai sensi e per gli effetti di quanto disposto dall’art.
            13 del Regolamento
            Europeo 2016/679 in materia di protezione dei dati personali (“GDPR”) e della Normativa Privacy nazionale
            (di seguito “Normativa Privacy vigente”), ti informiamo che i tuoi dati personali verranno trattati
            attraverso strumenti elettronici
            e manuali, in Italia e all’estero, anche attraverso l’utilizzo dei Social Network</p>
        <p>I fondamenti giuridici del trattamento dei tuoi dati personali sono:</p>
        <ul>
            <li>il Consenso da te rilasciato per il trattamento dei tuoi dati nell’accedere e/o usufruire dei servizi di
                Seven Live Web TV.</li>
            <li>il contratto, per l’adempimento e l’esecuzione dei relativi obblighi</li>
            <li>l’adempimento degli obblighi normativi, inclusi quelli contabili, amministrativi e fiscali </li>
            <li>il legittimo interesse del Titolare Informazioni sulle finalità relative a come verranno trattati i tuoi
                dati per i servizi di Seven Live Web TV</li>
        </ul>
        <p>1) I tuoi dati personali verranno trattati, in forza del legittimo interesse del Titolare, nonché di obblighi
            legali o necessità contrattuali, per le seguenti finalità</p>
        <ul>
            <li>a) registrazione ai Servizi dei siti e applicazioni mobile;</li>
            <li>b) partecipazione a eventuali concorsi, contatto con i vincitori e gestione degli adempimenti relativi
                es. notifica e invio premio;</li>
            <li>c) richiesta e/o partecipazione a casting, a programmi a premio, come pubblico, ecc.; </li>
            <li>d) partecipazione ad attività editoriali web e televisive: i. invio di commenti a trasmissioni tv; invio
                di commenti sui siti, app e social; invio di mail, richiesta informazioni tramite form;  partecipazione
                ai sondaggi web e app
                mobile;  voto via web, app mobile, social, sms;</li>
            <li>e) gestione di eventuali reclami e contestazioni;</li>
            <li>f) al fine di migliorare la tua scelta, di natura esclusivamente editoriale, all’interno dei siti Seven
                Live Web TV ti proponiamo la personalizzazione dei relativi contenuti attraverso l’elaborazione delle
                informazioni raccolte, compresi
                i dati di visione, anche attraverso il motore di raccomandazione e di ricerca.</li>
            <li>g) analisi tecniche di utilizzo dei servizi in modalità anonima attraverso l’utilizzo di strumenti quali
                Facebook Analytics e Google Fabric/Crashlytics; </li>
            <li>h) suggerimenti e assistenza tecnica al Servizio.</li>
        </ul>
        <p>In alcuni casi per ricevere suggerimenti o segnalazioni dagli utenti su dei malfunzionamenti dei nostri
            prodotti acquisiremo informazioni relativi al Mac Address o ID del device, indirizzo IP, sistema operativo,
            tipologia del device e
            email;  invio di newsletter Se in più hai scelto di attivare uno dei servizi a pagamento offerti da Seven
            Live Web TV SRL (es. sez. ON DEMAND) i tuoi dati saranno raccolti – in forza delle medesime basi giuridiche
            - anche per le seguenti
            finalità:
        </p>
        <ul>
            <li>A) comunicazione e/o invio (a mezzo e-mail, sms, push notification, posta, ecc.), anche con modalità
                automatizzate, di informazioni e materiale legati alla gestione del contratto e relative al Servizio;
            </li>
            <li>B) sottoscrizione e attivazione della fornitura del Servizio e dei servizi connessi previsti nel
                contratto</li>
            <li>C) fatturazione degli importi dovuti ed eventuali servizi supplementari; </li>
            <li>D) adempimento degli obblighi normativi inclusi quelli contabili, amministrativi e fiscali;</li>
            <li>E) prevenzione frodi e gestione dei ritardi o dei mancati pagamenti;</li>
            <li>F) tutela ed eventuale recupero del credito, direttamente o attraverso soggetti terzi (Agenzie/Società
                di recupero credito) ai quali saranno comunicati i dati necessari per tali scopi; </li>
            <li>G) cessione del credito a Società autorizzate;</li>
            <li>H) conservazione ed utilizzazione dei dati contabili relativi alla puntualità dei pagamenti per
                politiche premiali e per rifiutare futuri rapporti contrattuali; </li>
            <li>I) reportistica e controllo qualità;</li>
        </ul>
        <p>2) Inoltre, i Tuoi dati personali con il tuo consenso, verranno trattati per le finalità di: I. raccolta e
            analisi di comportamenti ed abitudini nella fruizione dei contenuti web, televisivi e dei nostri servizi,
            anche attraverso cookie
            o altri identificativi elettronici (mac address, ip/id etc), motori di ricerca e di raccomandazione,
            statistiche, preferenze rilasciate dal cliente allo scopo di effettuare ricerche di mercato, migliorare i
            servizi forniti e di personalizzarli,
            nonché di ridurre il numero di ripetizioni dei messaggi pubblicitari e agevolare l’inoltro di informazioni e
            offerte commerciali.</p>
        <p>Il trattamento dei tuoi dati personali per finalità di personalizzazione e profilazione avverrà con strumenti
            e con modalità automatizzate e con strumenti di elaborazione dati che creeranno un tuo profilo commerciale e
            comportamentale
            nel web. Tale strumento di elaborazione dati mette in relazione i dati raccolti nel corso della tua
            navigazione sul Sito attraverso l’utilizzo di cookie di profilazione. Il conferimento dei Tuoi dati è
            necessario per il conseguimento
            delle finalità di cui al punto 1)</p>
        <p>Il tuo mancato, parziale o inesatto conferimento potrebbe avere come conseguenza l’impossibilità di attivare
            e fornire il servizio richiesto. In ogni caso e come nel seguito meglio precisato, potrai revocare il tuo
            consenso, anche in modo
            parziale, ad esempio acconsentendo alle sole modalità di contatto tradizionali.</p>
        <p>Per attività legate al tuo invio di sms per concorsi, voting o abbonamenti ai servizi Seven Live Web TV. I
            dati richiesti dal Titolare verranno trattati per le sole finalità di:</p>
        <ul>
            <li>a) Voto via sms ed erogazione del Servizio di invio SMS al servizio richiesto;</li>
            <li>b) gestione ed estrazioni dei dati per partecipazioni a concorsi a premi;</li>
            <li>c) gestione ed estrazioni dei dati per operazioni a premi;</li>
            <li>d) eventuali adempimenti e/o obblighi di legge;</li>
            <li>e) eventuali contestazioni e/o contenziosi;</li>
        </ul>
        <p>Il conferimento dei Tuoi dati è necessario per il conseguimento delle finalità di cui sopra. I tuoi dati
            personali saranno trattati esclusivamente da persone autorizzate al trattamento e da persone designate quali
            Responsabili del trattamento
            nel rispetto del GDPR al fine di svolgere correttamente tutte le attività di Trattamento necessarie a
            perseguire le finalità di cui alla presente Informativa. </p>
        <p>I tuoi Dati Personali potranno essere comunicati ad enti pubblici o all’autorità giudiziaria, ove richiesto
            per legge o per prevenire o reprimere la commissione di un reato e comunque a:</p>
        <ul>
            <li>chi sia legittimo destinatario di comunicazioni previste da norme di legge o regolamenti (quali, ad
                esempio, Uffici ed Autorità Pubbliche);</li>
            <li>chi sia destinatario di comunicazioni necessarie in esecuzione degli obblighi derivanti dal Servizio;
            </li>
            <li>società terze specializzate nella gestione di informazioni commerciali e relative al credito (quali, ad
                esempio, call center, centri di elaborazione dati, banche, ecc.);</li>
            <li>ocietà e/o collaboratori per la gestione di servizi amministrativi di cui ci si avvalga per adempiere ai
                propri obblighi legali o contrattuali;</li>
            <li>altri soggetti (imprese, società, persone fisiche) che collaborano alla realizzazione del Servizio.</li>
        </ul>
        <p>Inoltre, qualora decidessi di rilasciare il tuo consenso facoltativo di cui al punto 2) relativo alle
            finalità di marketing, di profilazione, di comunicazione e/o cessione a soggetti terzi, i tuoi dati
            personali verranno conservati, salvo
            revoca del consenso, per un arco di tempo non superiore a quello necessario al conseguimento delle finalità.
            I tuoi dati verranno conservati per un periodo ulteriore in relazione alle finalità di contestazioni ed
            eventuali contenziosi.</p>
        <h5>Come puoi revocare il consenso prestato?</h5>
        <p>Hai il diritto di revocare il tuo consenso
            rilasciato al Titolare in ogni momento totalmente e/o parzialmente senza pregiudicare la liceità del
            Trattamento basata sul consenso
            prestato prima della revoca. Per revocare il tuo consenso basta contattare il Titolare agli indirizzi
            pubblicati nella presente informativa.</p>
        <p>Ti ricordiamo che potrai esercitare i tuoi diritti previsti dal GDPR e in particolare di ottenere:</p>
        <ul>
            <li>la conferma che sia o meno in corso un Trattamento di Dati personali che ti riguardano e di ottenere
                l’accesso ai dati e alle seguenti informazioni (finalità del Trattamento, categorie di Dati personali,
                destinatari e/o categorie di
                destinatari a cui i dati sono stati e/o saranno comunicati, periodo di conservazione);</li>
            <li>la rettifica dei Dati personali inesatti che ti riguardano e/o l’integrazione dei Dati personali
                incompleti, anche fornendo una dichiarazione integrativa;</li>
            <li>la cancellazione dei Dati personali, nei casi previsti dal GDPR</li>
            <li>la limitazione al Trattamento nelle ipotesi previste dalla Normativa Privacy vigente;</li>
            <li>la portabilità dei dati che ti riguardano, ed in particolare di richiedere i dati personali che ti
                riguardano forniti al Titolare e/o richiedere la trasmissione diretta dei tuoi dati a un altro titolare
                del trattamento;</li>
            <li>l’opposizione al trattamento in qualsiasi momento, per motivi connessi alla tua situazione particolare,
                al trattamento dei dati personali che ti riguardano nel pieno rispetto della Normativa Privacy Vigente,
                nonché per le finalità
                relative a marketing e profilazione.</li>
        </ul>
        <p>Potrai esercitare i Tuoi diritti relativamente al Servizio Seven Live Web TV  rivolgendoti alla seguente
            casella di posta elettronica <a href="mailto:infoprivacy@7live.tv">infoprivacy&#64;7live.tv</a> allegando
            copia del documento d’identità.
            In ogni caso avrai sempre diritto di proporre reclamo all’autorità di controllo competente (Garante per la
            Protezione dei Dati personali), ai sensi dell’art. 77 GDPR, qualora ritieni che il trattamento dei tuoi dati
            sia contrario alla
            Normativa Privacy vigente.</p>
    </div>
</div>