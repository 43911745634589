import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Canale, Cliente, Live, Login, News, Ondemand, Abbonamento, Ticket, User, LoginBackoffice, UserBackoffice, Utente, Storico, Categoria, OndemandHome } from "../shared/interfacce.model";
import { Observable, Subject } from "rxjs";
import { ErrorComponent } from "../common/error/error.component";
import { NzModalService } from "ng-zorro-antd/modal";
import { environment } from "../../environments/environment.development";
import { StorageService } from "./storage.service";

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient, private modal: NzModalService) { }

  Clienti: Cliente[] = [];
  Utenti: Utente[] = [];
  Canali: Canale[] = [];
  Tickets: Ticket[] = [];
  TicketsCliente: Ticket[] = [];
  Live: Live[] = [];
  LiveHome: Live[] = [];
  Ondemand: Ondemand[] = [];
  Abbonamenti: Abbonamento[] = [];
  News: News[] = [];
  Categorie: Categoria[] = [];

  clienteChanged = new Subject<Cliente[]>();
  utentiChanged = new Subject<Utente[]>();
  canaliChanged = new Subject<Canale[]>();
  ticketsChanged = new Subject<Ticket[]>();
  ticketsClienteChanged = new Subject<Ticket[]>();
  liveChanged = new Subject<Live[]>();
  liveHomeChanged = new Subject<Live[]>();
  ondemandChanged = new Subject<Ondemand[]>();
  abbonamentiChanged = new Subject<Abbonamento[]>();
  newsChanged = new Subject<News[]>();
  categorieChanged = new Subject<Categoria[]>();

  setUtenti() {
    this.http.get<Utente[]>(environment.apiUrl + 'v1.0/utenti').subscribe(utenti => {
      this.Utenti = utenti;
      this.utentiChanged.next(this.Utenti.slice());
    })
  }
  setClienti() {
    this.http.get<Cliente[]>(environment.apiUrl + 'v1.0/clienti').subscribe(clienti => {
      this.Clienti = clienti;
      this.clienteChanged.next(this.Clienti.slice());
    })
  }
  setCanali() {
    this.http.get<Canale[]>(environment.apiUrl + 'v1.0/canali').subscribe(canali => {
      this.Canali = canali;
      this.canaliChanged.next(this.Canali.slice());
    })
  }
  setTickets() {
    this.http.get<Ticket[]>(environment.apiUrl + 'v1.0/tickets').subscribe(tickets => {
      this.Tickets = tickets;
      this.ticketsChanged.next(this.Tickets.slice());
    })
  }
  setTicketsCliente(id: number) {
    this.http.get<Ticket[]>(environment.apiUrl + 'v1.0/tickets_cliente/' + id).subscribe(tickets => {
      this.TicketsCliente = tickets;
      this.ticketsClienteChanged.next(this.TicketsCliente.slice());
    })
  }
  setLive() {
    this.http.get<Live[]>(environment.apiUrl + 'v1.0/live').subscribe(live => {
      this.Live = live;
      this.liveChanged.next(this.Live.slice());
    })
  }
  setLiveHome() {
    this.http.get<Live[]>(environment.apiUrl + 'live').subscribe(live => {
      this.LiveHome = live;
      this.liveHomeChanged.next(this.LiveHome.slice());
    })
  }
  setOndemand() {
    this.http.get<Ondemand[]>(environment.apiUrl + 'v1.0/ondemand').subscribe(ondemand => {
      this.Ondemand = ondemand;
      this.ondemandChanged.next(this.Ondemand.slice());
    })
  }
  setAbbonamenti() {
    this.http.get<Abbonamento[]>(environment.apiUrl + 'v1.0/abbonamenti').subscribe(abbonamenti => {
      this.Abbonamenti = abbonamenti;
      this.abbonamentiChanged.next(this.Abbonamenti.slice());
    })
  }
  setNews() {
    this.http.get<News[]>(environment.apiUrl + 'v1.0/news').subscribe(news => {
      this.News = news;
      this.newsChanged.next(this.News.slice());
    })
  }
  setCategorie() {
    this.http.get<Categoria[]>(environment.apiUrl + 'v1.0/categorie').subscribe(categorie => {
      this.Categorie = categorie;
      this.categorieChanged.next(this.Categorie.slice());
    })
  }

  // GET
  getData(token: string) {
    return this.http.get<LoginBackoffice>(environment.apiUrl + 'v1.0/dati_utente_backoffice', { params: { user_token: token } });
  }
  getUtenti() {
    return this.http.get<Utente[]>(environment.apiUrl + 'v1.0/utenti');
  }
  getClienti() {
    return this.http.get<Cliente[]>(environment.apiUrl + 'v1.0/clienti');
  }
  getCanali() {
    return this.http.get<Canale[]>(environment.apiUrl + 'v1.0/canali');
  }
  getTickets() {
    return this.http.get<Ticket[]>(environment.apiUrl + 'v1.0/tickets');
  }
  getLives() {
    return this.http.get<Live[]>(environment.apiUrl + 'v1.0/live');
  }
  getPalinsesto() {
    return this.http.get<any[]>(environment.apiUrl + 'v1.0/palinsesto');
  }
  getOndemands() {
    return this.http.get<Ondemand[]>(environment.apiUrl + 'v1.0/ondemand');
  }
  getAbbonamenti() {
    return this.http.get<Abbonamento[]>(environment.apiUrl + 'v1.0/abbonamenti');
  }
  getNews() {
    return this.http.get<News[]>(environment.apiUrl + 'v1.0/news');
  }
  getCategorie() {
    return this.http.get<Categoria[]>(environment.apiUrl + 'v1.0/categorie');
  }
  getPagamento(id: number) {
    return this.http.get<{ url: string }>(environment.apiUrl + 'v1.0/pagamento/' + id);
  }

  // GET BY ID
  getUtente(id: number) {
    return this.http.get<Utente>(environment.apiUrl + 'v1.0/utenti/' + id);
  }
  getCliente(id: number) {
    return this.http.get<Cliente>(environment.apiUrl + 'v1.0/clienti/' + id);
  }
  getCanale(id: number) {
    return this.http.get<Canale>(environment.apiUrl + 'v1.0/canali/' + id);
  }
  getTicket(id: number) {
    return this.http.get<Ticket>(environment.apiUrl + 'v1.0/tickets/' + id);
  }
  getLive(id: number) {
    return this.http.get<Live>(environment.apiUrl + 'v1.0/live/' + id);
  }
  getOndemand(id: number) {
    return this.http.get<Ondemand>(environment.apiUrl + 'v1.0/ondemand/' + id);
  }
  getAbbonamento(id: number) {
    return this.http.get<Abbonamento>(environment.apiUrl + 'v1.0/abbonamenti/' + id);
  }
  getNotizia(id: number) {
    return this.http.get<News>(environment.apiUrl + 'v1.0/news/' + id);
  }
  getCategoria(id: number) {
    return this.http.get<Categoria>(environment.apiUrl + 'v1.0/categorie/' + id);
  }

  getTicketsCliente(id: number) {
    return this.http.get<Ticket[]>(environment.apiUrl + 'v1.0/tickets_cliente/' + id)
  }
  getAbbonamentoCliente(id: number) {
    return this.http.get<Abbonamento>(environment.apiUrl + 'v1.0/abbonamenti_cliente/' + id)
  }
  getStoricoPagamentiCliente(id: string) {
    return this.http.get<Storico[]>(environment.apiUrl + 'v1.0/pagamenti/' + id)
  }

  // HOME NO LOGIN
  getOndemandsCanale(id: number) {
    return this.http.get<Ondemand[]>(environment.apiUrl + 'ondemand_canale/' + id);
  }
  getCanaliHome() {
    return this.http.get<Canale[]>(environment.apiUrl + 'canali');
  }
  getCanaleHome(id: number) {
    return this.http.get<Canale>(environment.apiUrl + 'canali/' + id);
  }
  getLivesHome() {
    return this.http.get<Live[]>(environment.apiUrl + 'live');
  }
  getLivesCanaleHome(id_canale: number) {
    return this.http.get<Live[]>(environment.apiUrl + 'live_canale/' + id_canale);
  }
  getOndemandsHome() {
    return this.http.get<Ondemand[]>(environment.apiUrl + 'ondemand');
  }
  getOndemandHome(id: number) {
    return this.http.get<Ondemand>(environment.apiUrl + 'ondemand/' + id);
  }
  getOndemandsCategorie() {
    return this.http.get<OndemandHome[]>(environment.apiUrl + 'ondemand_categorie');
  }
  getCategorieHome() {
    return this.http.get<Categoria[]>(environment.apiUrl + 'categorie');
  }
  getNewsHome() {
    return this.http.get<News[]>(environment.apiUrl + 'news');
  }

  // ADD
  addUtente(utente: { username: string, password: string, tipo: string, canale: number }) {
    return this.http.post(environment.apiUrl + 'v1.0/utenti', utente);
  }
  addCliente(cliente: { nome: string, cognome: string, email: string, password: string, data_nascita: string | null, luogo_nascita: string, codice_fiscale: string, telefono: string, newsletter: boolean }) {
    return this.http.post(environment.apiUrl + 'v1.0/clienti', cliente);
  }
  addCanale(canale: { nome: string, descrizione: string, icona: string, icona_file: string, copertina: string, copertina_file: string, evidenza: boolean }) {
    return this.http.post(environment.apiUrl + 'v1.0/canali', canale);
  }
  addTicket(ticket: { categoria: string, titolo: string, messaggio: string, id_cliente: number, home?: boolean }) {
    return this.http.post(environment.apiUrl + 'v1.0/tickets', ticket);
  }
  addLive(live: { id_canale: string, id_ondemand: string, posizione: number }) {
    return this.http.post<{ code: number, id: number }>(environment.apiUrl + 'v1.0/live', live);
  }
  addOndemand(ondemand: { titolo: string, descrizione: string, id_canale: string | null, id_categoria: string | null, abbonamento: boolean, copertina: string, copertina_file: string, video: string, cloudflare_id: string }) {
    return this.http.post<{ code: number, id: number }>(environment.apiUrl + 'v1.0/ondemand', ondemand);
  }
  addAbbonamento(abbonamento: { prezzo: number, id_cliente: number, stripe_id: string, stripe_sub: string, stripe_scadenza: number }) {
    return this.http.post(environment.apiUrl + 'v1.0/abbonamenti', abbonamento);
  }
  addNews(news: { titolo: string, descrizione: string, copertina: string, copertina_file: string, data: string | null }) {
    return this.http.post(environment.apiUrl + 'v1.0/news', news);
  }
  addCategoria(categoria: { titolo: string, descrizione: string, home: boolean }) {
    return this.http.post(environment.apiUrl + 'v1.0/categorie', categoria);
  }

  // UPDATE
  updateUtente(id: number, utente: { username: string, tipo: string, canale: number }) {
    return this.http.put(environment.apiUrl + 'v1.0/utenti/' + id, utente);
  }
  updateCliente(id: number, cliente: { nome: string, cognome: string, email: string, data_nascita: string | null, luogo_nascita: string, codice_fiscale: string, telefono: string, newsletter: boolean }) {
    return this.http.put(environment.apiUrl + 'v1.0/clienti/' + id, cliente);
  }
  updatePasswordCliente(id: number, password: { password: string, confirm_password: string }) {
    return this.http.put(environment.apiUrl + 'v1.0/clienti/' + id + '/password', password);
  }
  updateCanale(id: number, canale: { nome: string, descrizione: string, icona: string, icona_file: string, copertina: string, copertina_file: string, evidenza: boolean }) {
    return this.http.put(environment.apiUrl + 'v1.0/canali/' + id, canale);
  }
  updateTicket(id: number, ticket: { categoria: string, titolo: string, messaggio: string, stato: string }) {
    return this.http.put(environment.apiUrl + 'v1.0/tickets/' + id, ticket);
  }
  updateStatoTicket(id: number, stato: string) {
    return this.http.put(environment.apiUrl + 'v1.0/stato_tickets/' + id, { stato: stato });
  }
  updateLive(id: number, live: { id_canale: string, id_ondemand: string, posizione: number }) {
    return this.http.put(environment.apiUrl + 'v1.0/live/' + id, live);
  }
  updateVideoLive(id: number, live: { video: Ondemand[] }) {
    return this.http.put(environment.apiUrl + 'v1.0/live_video/' + id, live);
  }
  updateOndemand(id: number, ondemand: { titolo: string, descrizione: string, id_canale: string | null, id_categoria: string | null, copertina: string, copertina_file: string, video: string, new_video: boolean, cloudflare_id: string }) {
    return this.http.put(environment.apiUrl + 'v1.0/ondemand/' + id, ondemand);
  }
  updateNews(id: number, news: { titolo: string, descrizione: string, copertina: string, copertina_file: string, data: string | null }) {
    return this.http.put(environment.apiUrl + 'v1.0/news/' + id, news);
  }
  updateAbbonamento(id: number, stripe_scadenza: number) {
    return this.http.put(environment.apiUrl + 'v1.0/abbonamenti/' + id, { stripe_scadenza: stripe_scadenza });
  }
  updateCategoria(id: number, categoria: { titolo: string, descrizione: string, home: boolean }) {
    return this.http.put(environment.apiUrl + 'v1.0/categorie/' + id, categoria);
  }

  // DELETE  
  deleteUtente(id: number) {
    return this.http.delete(environment.apiUrl + 'v1.0/utenti/' + id);
  }
  deleteCliente(id: number) {
    return this.http.delete(environment.apiUrl + 'v1.0/clienti/' + id);
  }
  deleteCanale(id: number) {
    return this.http.delete(environment.apiUrl + 'v1.0/canali/' + id);
  }
  deleteTicket(id: number) {
    return this.http.delete(environment.apiUrl + 'v1.0/tickets/' + id);
  }
  deleteLive(id: number) {
    return this.http.delete(environment.apiUrl + 'v1.0/live/' + id);
  }
  deleteOndemand(id: number) {
    return this.http.delete(environment.apiUrl + 'v1.0/ondemand/' + id);
  }
  deleteAbbonamento(id: number) {
    return this.http.delete(environment.apiUrl + 'v1.0/abbonamenti/' + id);
  }
  deleteNews(id: number) {
    return this.http.delete(environment.apiUrl + 'v1.0/news/' + id);
  }
  deleteCategoria(id: number) {
    return this.http.delete(environment.apiUrl + 'v1.0/categorie/' + id);
  }
  deleteFile(tipo: string, categoria: string, immagine: string, video: string, id: number) {
    return this.http.delete(environment.apiUrl + 'v1.0/file/', { body: { tipo: tipo, categoria: categoria, immagine: immagine, video: video, id: id } });
  }


  sendConfermaCliente(id: number) {
    return this.http.post(environment.apiUrl + 'v1.0/conferma/' + id, {});
  }
  sospendiAbbonamento(stripe_sub: string) {
    return this.http.post(environment.apiUrl + 'v1.0/sospensione', { stripe_sub: stripe_sub });
  }
  attivaAbbonamento(stripe_sub: string, id_abbonamento: number) {
    return this.http.post(environment.apiUrl + 'v1.0/attivazione', { stripe_sub: stripe_sub, id_abbonamento: id_abbonamento });
  }
  rimozioneAbbonamento(stripe_sub: string, id_abbonamento: number) {
    return this.http.post(environment.apiUrl + 'v1.0/rimozione', { stripe_sub: stripe_sub, id_abbonamento: id_abbonamento });
  }
  conversioneVideo(id: number, tipo: string) {
    return this.http.post(environment.apiUrl + 'v1.0/conversione/' + id, { tipo: tipo });
  }

  error(message?: string) {
    this.modal.create({
      nzTitle: 'Errore',
      nzContent: ErrorComponent,
      nzData: {
        message: message
      },
      nzFooter: null,
      nzCentered: true,
      nzWidth: 750,
      nzBodyStyle: { 'padding': '0px', 'text-align': 'center' }
    });
  }

  //CHECK
  checkTokenReset(token: string) {
    return this.http.post<{ id: number }>(environment.apiUrl + 'check_token_reset_password', { token: token });
  }
  checkTokenRegistrazione(token: string) {
    return this.http.post<{ id: number, email: string, nome: string, cognome: string }>(environment.apiUrl + 'check_token_registrazione', { token: token });
  }
  checkPagamento(token: string, session: string) {
    return this.http.post<{ stripe_id: string, stripe_cus: string, stripe_scadenza: number, stripe_status: string, stripe_total: number, id_cliente: number }>(environment.apiUrl + 'v1.0/check_pagamento', { token_pagamento: token, session: session });
  }
  checkAbbonamento(stripe_sub: string) {
    return this.http.post<{ status: string, stripe_scadenza: number }>(environment.apiUrl + 'v1.0/check_abbonamento', { stripe_sub: stripe_sub });
  }
  checkVideo(video: string) {
    return this.http.post<{ check: boolean }>(environment.apiUrl + 'v1.0/check_video', { video: video });
  }


  // LOGIN
  login(email: string, password: string) {
    return this.http.post<Login>(environment.apiUrl + 'login', { email: email, password: password });
  }
  loginBackoffice(username: string, password: string) {
    return this.http.post<LoginBackoffice>(environment.apiUrl + 'login_backoffice', { username: username, password: password });
  }
  registrazione(utente: { nome: string, cognome: string, email: string, password: string, confirm_password: string, telefono: string, data_nascita: string | null, newsletter: boolean, privacy: boolean }) {
    return this.http.post(environment.apiUrl + 'registrazione', utente);
  }
  richiestaReset(email: string) {
    return this.http.post(environment.apiUrl + 'reset', { email: email });
  }
  reset(password: string, confirm_password: string, id: number) {
    return this.http.post(environment.apiUrl + 'reset_password', { password: password, confirm_password: confirm_password, id: id });
  }
  conferma(id: number) {
    return this.http.put(environment.apiUrl + 'conferma/' + id, {});
  }
  contatti(contatto: { nome_cognome: string, email: string, azienda: string, telefono: string, messaggio: string }) {
    return this.http.post(environment.apiUrl + 'contatti', contatto);
  }
  autorizzazione() {
    return this.http.get<boolean>(environment.apiUrl + 'autorizzazione');
  }
  autorizzazioneBackoffice() {
    return this.http.get<boolean>(environment.apiUrl + 'autorizzazione_backoffice');
  }

}

@Injectable({
  providedIn: 'root'
})
export class ApiInterceptor implements HttpInterceptor {

  constructor(private storageService: StorageService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {    

    const userToken = this.storageService.getToken();
    const user: User = this.storageService.getUser();

    const userTokenBackoffice = this.storageService.getTokenBackoffice();
    const userBackoffice: UserBackoffice = this.storageService.getUserBackoffice();

    const modifiedReq = req.clone({
      headers: req.headers.set('Authorization', `Bearer ${userToken} ${userTokenBackoffice} ${user.id} ${userBackoffice.id}`),
    });
    return next.handle(modifiedReq);
  }
}