<ng-template #utentiTitle>
    <h3 class="text-white mb-0 text-xl font-semibold">Aggiungi Utente</h3>
</ng-template>    
<nz-card [nzTitle]="utentiTitle" [nzActions]="[actionClose, actionAdd]">        
    <form [formGroup]="formUtente" (ngSubmit)="onSubmit()" id="formUtente" class="flex flex-col gap-4">
        <nz-alert [nzType]="alertType" nzShowIcon [nzMessage]="alertMessage" *ngIf="alertVisible"></nz-alert>
        <div class="flex lg:flex-row flex-col gap-4">
            <div class="lg:w-1/2 w-full">
                <nz-form-item>
                    <nz-form-label nzRequired>Username</nz-form-label>
                    <nz-form-control>
                        <input nz-input nzSize="large" formControlName="username" required />                 
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div class="lg:w-1/2 w-full">
                <nz-form-item>
                    <nz-form-label nzRequired>Password</nz-form-label>
                    <nz-form-control>
                        <nz-input-group [nzSuffix]="passwordTemplate" nzSize="large">
                            <input [type]="hide ? 'text' : 'password'" nz-input formControlName="password" required />
                        </nz-input-group>
                        <ng-template #passwordTemplate>
                            <span nz-icon [nzType]="hide ? 'eye-invisible' : 'eye'" (click)="hide = !hide"></span>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>            
        <div class="flex lg:flex-row flex-col gap-4">
            <div [ngClass]="{'lg:w-1/2': formUtente.get('tipo')?.value == 'Operatore', 'w-full': formUtente.get('tipo')?.value != 'Operatore'}">                    
                <nz-form-item>
                    <nz-form-label nzRequired>Tipo</nz-form-label>
                    <nz-form-control>
                        <nz-select nzShowSearch nzAllowClear formControlName="tipo" nzSize="large" class="w-full" (ngModelChange)="onTipo($event)" required>
                            <nz-option nzValue="Amministratore" nzLabel="Amministratore"></nz-option>
                            <nz-option nzValue="Operatore" nzLabel="Operatore"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div class="lg:w-1/2 w-full" *ngIf="formUtente.get('tipo')?.value == 'Operatore'">                    
                <nz-form-item >
                    <nz-form-label nzRequired >Canale</nz-form-label>
                    <nz-form-control>
                        <nz-select nzShowSearch nzAllowClear formControlName="canale" nzSize="large" class="w-full" (ngModelChange)="onTipo($event)" required>
                            <nz-option *ngFor="let canale of canali" [nzValue]="canale.id" [nzLabel]="canale.nome"></nz-option>                        
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>                    
            </div>
        </div>                            
    </form>
</nz-card>    
<ng-template #actionClose>
    <button nz-button nzSize="large" type="button" (click)="onAnnulla()">Annulla</button>        
</ng-template>
<ng-template #actionAdd>
    <button nz-button nzSize="large" [nzLoading]="loading" type="submit" class="home-button" form="formUtente">Aggiungi</button>        
</ng-template>    