import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, Input, NgModule } from '@angular/core';
import Dashboard from '@uppy/dashboard';
import { Uppy } from '@uppy/core';
import ProgressBar from '@uppy/progress-bar';
import StatusBar from '@uppy/status-bar';
import DragDrop from '@uppy/drag-drop';
class UppyAngularWrapper {
  onMount(defaultOptions, plugin) {
    this.options = {
      ...defaultOptions,
      ...this.props
    };
    this.uppy.use(plugin, this.options);
    this.plugin = this.uppy.getPlugin(this.options.id);
  }
  handleChanges(changes, plugin) {
    // Without the last part of this conditional, it tries to uninstall before the plugin is mounted
    if (changes['uppy'] && this.uppy !== changes['uppy'].previousValue && changes['uppy'].previousValue !== undefined) {
      this.uninstall(changes['uppy'].previousValue);
      // @ts-expect-error The options correspond to the plugin, I swear
      this.uppy.use(plugin, this.options);
    }
    this.options = {
      ...this.options,
      ...this.props
    };
    this.plugin = this.uppy.getPlugin(this.options.id);
    if (changes['props'] && this.props !== changes['props'].previousValue && changes['props'].previousValue !== undefined) {
      this.plugin.setOptions({
        ...this.options
      });
    }
  }
  uninstall(uppy = this.uppy) {
    uppy.removePlugin(this.plugin);
  }
}
class DashboardComponent extends UppyAngularWrapper {
  constructor(el) {
    super();
    this.el = el;
    this.uppy = new Uppy();
    this.props = {};
  }
  ngOnInit() {
    this.onMount({
      id: 'angular:Dashboard',
      inline: true,
      target: this.el.nativeElement
    }, Dashboard);
  }
  ngOnChanges(changes) {
    this.handleChanges(changes, Dashboard);
  }
  ngOnDestroy() {
    this.uninstall();
  }
  static {
    this.ɵfac = function DashboardComponent_Factory(t) {
      return new (t || DashboardComponent)(i0.ɵɵdirectiveInject(i0.ElementRef));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DashboardComponent,
      selectors: [["uppy-dashboard"]],
      inputs: {
        uppy: "uppy",
        props: "props"
      },
      features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵNgOnChangesFeature],
      decls: 0,
      vars: 0,
      template: function DashboardComponent_Template(rf, ctx) {},
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DashboardComponent, [{
    type: Component,
    args: [{
      selector: 'uppy-dashboard',
      template: '',
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], () => [{
    type: i0.ElementRef
  }], {
    uppy: [{
      type: Input
    }],
    props: [{
      type: Input
    }]
  });
})();
const COMPONENTS$4 = [DashboardComponent];
class UppyAngularDashboardModule {
  static {
    this.ɵfac = function UppyAngularDashboardModule_Factory(t) {
      return new (t || UppyAngularDashboardModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: UppyAngularDashboardModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UppyAngularDashboardModule, [{
    type: NgModule,
    args: [{
      declarations: COMPONENTS$4,
      exports: COMPONENTS$4
    }]
  }], null, null);
})();
class DashboardModalComponent extends UppyAngularWrapper {
  constructor(el) {
    super();
    this.el = el;
    this.uppy = new Uppy();
    this.props = {};
    this.open = false;
  }
  ngOnInit() {
    this.onMount({
      id: 'angular:DashboardModal',
      inline: false,
      target: this.el.nativeElement
    }, Dashboard);
  }
  ngOnChanges(changes) {
    this.handleChanges(changes, Dashboard);
    // Handle dashboard-modal specific changes
    if (changes['open'] && this.open !== changes['open'].previousValue) {
      if (this.open && !changes['open'].previousValue) {
        this.plugin.openModal();
      }
      if (!this.open && changes['open'].previousValue) {
        this.plugin.closeModal();
      }
    }
  }
  ngOnDestroy() {
    this.uninstall();
  }
  static {
    this.ɵfac = function DashboardModalComponent_Factory(t) {
      return new (t || DashboardModalComponent)(i0.ɵɵdirectiveInject(i0.ElementRef));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DashboardModalComponent,
      selectors: [["uppy-dashboard-modal"]],
      inputs: {
        uppy: "uppy",
        props: "props",
        open: "open"
      },
      features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵNgOnChangesFeature],
      decls: 0,
      vars: 0,
      template: function DashboardModalComponent_Template(rf, ctx) {},
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DashboardModalComponent, [{
    type: Component,
    args: [{
      selector: 'uppy-dashboard-modal',
      template: '',
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], () => [{
    type: i0.ElementRef
  }], {
    uppy: [{
      type: Input
    }],
    props: [{
      type: Input
    }],
    open: [{
      type: Input
    }]
  });
})();
const COMPONENTS$3 = [DashboardModalComponent];
class UppyAngularDashboardModalModule {
  static {
    this.ɵfac = function UppyAngularDashboardModalModule_Factory(t) {
      return new (t || UppyAngularDashboardModalModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: UppyAngularDashboardModalModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UppyAngularDashboardModalModule, [{
    type: NgModule,
    args: [{
      declarations: COMPONENTS$3,
      exports: COMPONENTS$3
    }]
  }], null, null);
})();
class ProgressBarComponent extends UppyAngularWrapper {
  constructor(el) {
    super();
    this.el = el;
    this.uppy = new Uppy();
    this.props = {};
  }
  ngOnInit() {
    this.onMount({
      id: 'angular:ProgressBar',
      target: this.el.nativeElement
    }, ProgressBar);
  }
  ngOnChanges(changes) {
    this.handleChanges(changes, ProgressBar);
  }
  ngOnDestroy() {
    this.uninstall();
  }
  static {
    this.ɵfac = function ProgressBarComponent_Factory(t) {
      return new (t || ProgressBarComponent)(i0.ɵɵdirectiveInject(i0.ElementRef));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: ProgressBarComponent,
      selectors: [["uppy-progress-bar"]],
      inputs: {
        uppy: "uppy",
        props: "props"
      },
      features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵNgOnChangesFeature],
      decls: 0,
      vars: 0,
      template: function ProgressBarComponent_Template(rf, ctx) {},
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ProgressBarComponent, [{
    type: Component,
    args: [{
      selector: 'uppy-progress-bar',
      template: '',
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], () => [{
    type: i0.ElementRef
  }], {
    uppy: [{
      type: Input
    }],
    props: [{
      type: Input
    }]
  });
})();
const COMPONENTS$2 = [ProgressBarComponent];
class UppyAngularProgressBarModule {
  static {
    this.ɵfac = function UppyAngularProgressBarModule_Factory(t) {
      return new (t || UppyAngularProgressBarModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: UppyAngularProgressBarModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UppyAngularProgressBarModule, [{
    type: NgModule,
    args: [{
      declarations: COMPONENTS$2,
      exports: COMPONENTS$2
    }]
  }], null, null);
})();
class StatusBarComponent extends UppyAngularWrapper {
  constructor(el) {
    super();
    this.el = el;
    this.uppy = new Uppy();
    this.props = {};
  }
  ngOnInit() {
    this.onMount({
      id: 'angular:StatusBar',
      target: this.el.nativeElement
    }, StatusBar);
  }
  ngOnChanges(changes) {
    this.handleChanges(changes, StatusBar);
  }
  ngOnDestroy() {
    this.uninstall();
  }
  static {
    this.ɵfac = function StatusBarComponent_Factory(t) {
      return new (t || StatusBarComponent)(i0.ɵɵdirectiveInject(i0.ElementRef));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: StatusBarComponent,
      selectors: [["uppy-status-bar"]],
      inputs: {
        uppy: "uppy",
        props: "props"
      },
      features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵNgOnChangesFeature],
      decls: 0,
      vars: 0,
      template: function StatusBarComponent_Template(rf, ctx) {},
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(StatusBarComponent, [{
    type: Component,
    args: [{
      selector: 'uppy-status-bar',
      template: '',
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], () => [{
    type: i0.ElementRef
  }], {
    uppy: [{
      type: Input
    }],
    props: [{
      type: Input
    }]
  });
})();
const COMPONENTS$1 = [StatusBarComponent];
class UppyAngularStatusBarModule {
  static {
    this.ɵfac = function UppyAngularStatusBarModule_Factory(t) {
      return new (t || UppyAngularStatusBarModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: UppyAngularStatusBarModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UppyAngularStatusBarModule, [{
    type: NgModule,
    args: [{
      declarations: COMPONENTS$1,
      exports: COMPONENTS$1
    }]
  }], null, null);
})();
class DragDropComponent extends UppyAngularWrapper {
  constructor(el) {
    super();
    this.el = el;
    this.uppy = new Uppy();
    this.props = {};
  }
  ngOnInit() {
    this.onMount({
      id: 'angular:DragDrop',
      target: this.el.nativeElement
    }, DragDrop);
  }
  ngOnChanges(changes) {
    this.handleChanges(changes, DragDrop);
  }
  ngOnDestroy() {
    this.uninstall();
  }
  static {
    this.ɵfac = function DragDropComponent_Factory(t) {
      return new (t || DragDropComponent)(i0.ɵɵdirectiveInject(i0.ElementRef));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: DragDropComponent,
      selectors: [["uppy-drag-drop"]],
      inputs: {
        uppy: "uppy",
        props: "props"
      },
      features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵNgOnChangesFeature],
      decls: 0,
      vars: 0,
      template: function DragDropComponent_Template(rf, ctx) {},
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DragDropComponent, [{
    type: Component,
    args: [{
      selector: 'uppy-drag-drop',
      template: '',
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], () => [{
    type: i0.ElementRef
  }], {
    uppy: [{
      type: Input
    }],
    props: [{
      type: Input
    }]
  });
})();
const COMPONENTS = [DragDropComponent];
class UppyAngularDragDropModule {
  static {
    this.ɵfac = function UppyAngularDragDropModule_Factory(t) {
      return new (t || UppyAngularDragDropModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: UppyAngularDragDropModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UppyAngularDragDropModule, [{
    type: NgModule,
    args: [{
      declarations: COMPONENTS,
      exports: COMPONENTS
    }]
  }], null, null);
})();

/*
 * Public API Surface of @uppy/angular
 */

/**
 * Generated bundle index. Do not edit.
 */

export { DashboardComponent, DashboardModalComponent, DragDropComponent, ProgressBarComponent, StatusBarComponent, UppyAngularDashboardModalModule, UppyAngularDashboardModule, UppyAngularDragDropModule, UppyAngularProgressBarModule, UppyAngularStatusBarModule };
