<ng-template #clienteTitle>        
    <div class="flex lg:flex-row flex-col gap-4 items-center justify-between">
        <div class="lg:w-2/3 w-full">
            <h3 class="text-white mb-0 font-semibold text-xl">Clienti</h3>
        </div>
        <div class="lg:w-1/3 w-full">
            <button nz-button nzType="primary" nzSize="large" (click)="onAdd()" class="home-button w-full">Aggiungi cliente</button>
        </div>
    </div>        
    <div class="flex lg:flex-row flex-col gap-4 mt-4">            
        <div class="lg:w-1/2 w-full">
            <input nz-input placeholder="Cerca Cognome o Nome cliente" nzSize="large" [(ngModel)]="clienteFilter" />
        </div>              
        <div class="lg:w-1/2 w-full">
            <input nz-input placeholder="Cerca Email cliente" nzSize="large" [(ngModel)]="emailFilter" />
        </div>                                   
    </div>
</ng-template>
<nz-card [nzTitle]="clienteTitle">    
    <nz-table #clienteTable nzShowPagination class="tabella"
        [nzTotal]="(clienti | searchNomeCognomeCliente: clienteFilter | searchEmailCliente: emailFilter).length"
        nzShowSizeChanger [nzPageSize]="10"
        [nzPageSizeOptions]="[5, 10, 25, 50, 100, 150, 200, 250, 300, 350, 400, 500, 750, 1000]"
        [nzData]="clienti | searchNomeCognomeCliente: clienteFilter | searchEmailCliente: emailFilter"
        [nzBordered]="true" [nzNoResult]="'Nessun cliente trovato'">
        <thead>
            <tr>
                <th [nzSortFn]="onOrdinamentoCliente">Cognome e Nome</th>
                <th [nzSortFn]="onOrdinamentoEmail" class="text-start">Email</th>                        
                <th [nzSortFn]="onOrdinamentoStato" class="text-center">Stato</th>                        
                <th class="!text-center">Azioni</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let cliente of clienteTable.data| searchNomeCognomeCliente: clienteFilter | searchEmailCliente: emailFilter">
                <td>{{cliente.cognome}} {{cliente.nome}}</td>
                <td class="text-start">{{cliente.email}}</td>                        
                <td class="text-center">{{cliente.stato}}</td>                        
                <td class="text-center">
                    <nz-button-group>                                
                        <button nz-button nz-dropdown [nzDropdownMenu]="menu" nzPlacement="bottomRight">
                            <span nz-icon nzType="menu"></span>
                        </button>
                  </nz-button-group>
                  <nz-dropdown-menu #menu="nzDropdownMenu">
                    <ul nz-menu>
                      <li nz-menu-item class="!p-4" (click)="onEdit(cliente.id)">Modifica cliente</li>                              
                      <li nz-menu-item class="!p-4" (click)="onEditPassword(cliente.id)">Modifica password cliente</li>                              
                      <li nz-menu-item class="!p-4" (click)="onConferma(cliente.id)" *ngIf="cliente.stato != 'attivo'">Re-invia conferma email</li>                              
                      <li nz-menu-item class="!p-4" (click)="onElimina(cliente.id)">Elimina cliente</li>
                    </ul>
                  </nz-dropdown-menu>
                </td>
            </tr>
        </tbody>
    </nz-table>                
</nz-card>