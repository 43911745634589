<div class="ondemands">
    <app-on-demand [abbonamentoScaduto]="true"></app-on-demand>
</div>
<!-- <div class="blur">
    <div class="avviso bg-dark rounded">
        <h3 class="text-white text-center">{{user?.id ? 'Sembra che tu non abbia nessun abbonamento attivo...' : 'Per visualizzare i nostri video On Demand accedi o registrati prima.'}}</h3>
        <p class="text-white text-center mt-2">{{user?.id ? 'Abbonati ora per ottenere accesso a tantissimi contenuti On Demand!' : "Dopo aver effettuato la registrazione o l'accesso, abbonati a Seven Live Web TV per poter accedere ai video On Demand."}}</p>        
        <button class="bottone mt-3" nzType="primary" nz-button [routerLink]="user?.id ? '/profilo/abbonamento' : '/login'">{{user?.id ? 'Abbonati ora': 'Login'}}</button>
        <nz-divider class="text-white" nzText="oppure"></nz-divider>
        <button class="bottone" nzType="default" nz-button routerLink="home">Indietro</button>
    </div>
</div> -->
