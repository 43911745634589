
<form [formGroup]="formCliente" (ngSubmit)="onSubmit()" class="flex flex-col gap-4">

  <h4 class="font-semibold text-xl mb-4">Aggiorna i tuoi dati</h4>  

  <nz-alert [nzType]="alertType" nzShowIcon [nzMessage]="alertMessage" *ngIf="alertVisible"></nz-alert>
  
  <div class="flex lg:flex-row flex-col gap-4">
    <div class="lg:w-1/2 w-full forms-inputs">
      <span>* Cognome</span> 
      <input nz-input formControlName="cognome">
    </div>
    <div class="lg:w-1/2 w-full forms-inputs">
      <span>* Nome</span>
      <input nz-input formControlName="nome">
    </div>
  </div>
  <div class="flex lg:flex-row flex-col gap-4">
    <div class="lg:w-1/2 w-full forms-inputs flex flex-col">
      <span>Data di nascita <i>(opzionale)</i></span>
      <nz-date-picker name="data_nascita" formControlName="data_nascita" nzFormat="dd/MM/yyyy" [nzDisabledDate]="disabledDate"></nz-date-picker>            
    </div>
    <div class="lg:w-1/2 w-full forms-inputs">
      <span>Luogo di nascita <i>(opzionale)</i></span>
      <input nz-input formControlName="luogo_nascita">
    </div>
  </div>        
  <div class="forms-inputs w-full">
    <span>* Email</span>
    <input nz-input type="text" type="email" formControlName="email">          
  </div>        
  <div class="flex lg:flex-row flex-col gap-4">
    <div class="lg:w-1/2 w-full forms-inputs">
      <span>Telefono <i>(opzionale)</i></span>
      <input nz-input formControlName="telefono">
    </div>          
    <div class="lg:w-1/2 w-full forms-inputs">
      <span>Codice fiscale <i>(opzionale)</i></span>
      <input nz-input formControlName="codice_fiscale">
    </div>
  </div>
  <h4 class="font-semibold text-xl my-4">Aggiorna la tua password</h4>
  <div class="flex lg:flex-row flex-col gap-4">
    <div class="lg:w-1/2 w-full forms-inputs">        
      <span>* Nuova password</span>
      <nz-input-group [nzSuffix]="suffixTemplate">
        <input [type]="hide ? 'text' : 'password'" nz-input formControlName="password" placeholder="Nuova password" autocomplete />
      </nz-input-group>
      <ng-template #suffixTemplate> <span nz-icon [nzType]="hide ? 'eye-invisible' : 'eye'" (click)="hide = !hide"></span></ng-template>            
    </div>
    <div class="lg:w-1/2 w-full forms-inputs">
      <span>* Conferma password</span>
      <nz-input-group [nzSuffix]="suffixTemplateConfirm">
        <input [type]="hide_confirm ? 'text' : 'password'" nz-input formControlName="confirm_password" placeholder="Conferma password" autocomplete />
      </nz-input-group>
      <ng-template #suffixTemplateConfirm> <span nz-icon [nzType]="hide_confirm ? 'eye-invisible' : 'eye'" (click)="hide_confirm = !hide_confirm"></span></ng-template>            
    </div>
  </div>     
      
  <label nz-checkbox formControlName="newsletter" class="checkbox">Accetto di ricevere mail dalla NewsLetter</label>
  
  <div class="flex lg:flex-row flex-col gap-4 mt-4">
    <div class="lg:w-1/2 w-full">
      <button class="bottone !bg-danger w-full" nz-popconfirm nzPopconfirmTitle="Sei sicuro di voler eliminare il tuo profilo? Eliminerai anche i tuoi ticket e il tuo abbonamento!" (nzOnConfirm)="onElimina()" nz-button nzSize="large" nzType="primary" type="button" [nzLoading]="loading">ELIMINA PROFILO</button>
    </div>          
    <div class="lg:w-1/2 w-full">
      <button class="bottone w-full" nzType="primary" nz-button type="submit" nzSize="large" [nzLoading]="loading">SALVA MODIFICHE</button>
    </div>          
  </div>      
</form>