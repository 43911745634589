import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { User } from '../../shared/interfacce.model';


@Component({
  selector: 'app-conferma',
  templateUrl: './conferma.component.html',
  styleUrls: ['./conferma.component.css']
})
export class ConfermaComponent implements OnInit {

  constructor(private apiService: ApiService, private router: Router, private route: ActivatedRoute) { }

  error: boolean = false;  
  token: string = '';

  user?: User;

  ngOnInit(): void {

    // Recupero il token dall'url
    this.route.queryParams.subscribe(params => {
      this.token = params['token'];

      // Se ho un token procedo
      if (this.token) {

        // Controllo che il token contenga i dati
        this.apiService.checkTokenRegistrazione(this.token).subscribe({
          next: (data) => {          
            // se il token non ha i dati esco
            if (!data.id)
              this.router.navigate(['/login'])
            else 
              this.user = data            
          },
          error: (err) => this.router.navigate(['/login']),
          complete: () => {

              // Confermo l'account dell'utente
              this.apiService.conferma(this.user!.id).subscribe({
                next: (data) => { },
                error: (err) => this.error = true,
                complete: () => this.error = false
              })
          }
        })
                
      } else {
        this.router.navigate(['/login']);
      }
    })

  }  

}
