import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '../../../../services/api.service';
import { Cliente } from '../../../../shared/interfacce.model';

@Component({
  selector: 'app-aggiungi-ticket-backoffice',
  templateUrl: './aggiungi-ticket-backoffice.component.html',
  styleUrls: ['./aggiungi-ticket-backoffice.component.css']
})
export class AggiungiTicketBackofficeComponent implements OnInit {

  constructor(private apiService: ApiService, private router: Router) { }

  formTicket!: FormGroup;

  clienti: Cliente[] = [];
  filteredClienti: Cliente[] = [];

  loading: boolean = false;
  alertMessage: string = '';
  alertType: "success" | "info" | "warning" | "error" = 'success';
  alertVisible: boolean = false;

  ngOnInit(): void {

    // Creo il form con i controlli
    this.formTicket = new FormGroup({
      categoria: new FormControl(null),
      titolo: new FormControl(null),
      messaggio: new FormControl(null),
      cliente: new FormControl(null)
    })

    // Recupero i clienti
    this.apiService.getClienti().subscribe({
      next: (data) => this.clienti = [...data],
      error: (err) => this.onError("Clienti non caricati correttamente, ricaricare la pagina o aspettare qualche secondo!"),
      complete: () => this.filteredClienti = this.clienti.slice(0, 10)
    })

  }

  onClose() {
    this.router.navigate(['/backoffice/tickets']);
  }

  onSeleziona(ricerca: any) {
    this.filteredClienti = this.onFiltro(ricerca).slice(0, 10)
  }

  onFiltro(name: string): Cliente[] {
    // Filtro i clienti per nome o cognome
    const filterValue = name.toLowerCase();
    return this.clienti.filter(function (cliente) {
      const nome_cognome = cliente.nome + ' ' + cliente.cognome;
      const cognome_nome = cliente.cognome + ' ' + cliente.nome;
      return nome_cognome.toLocaleLowerCase().includes(filterValue.toLocaleLowerCase()) || cognome_nome.toLocaleLowerCase().includes(filterValue.toLocaleLowerCase())
    });
  }

  onError(message: string) {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    this.alertMessage = message;
    this.alertVisible = true;
    this.alertType = 'error';
    this.loading = false;
  }

  onSubmit() {

    // Formo l'oggetto per la richiesta dell'api
    let ticket = {
      categoria: this.formTicket.get('categoria')?.value,
      titolo: this.formTicket.get('titolo')?.value,
      messaggio: this.formTicket.get('messaggio')?.value,
      id_cliente: this.formTicket.get('cliente')?.value.id
    }

    this.loading = true;

    // Aggiungo il ticket al db
    this.apiService.addTicket(ticket).subscribe({
      next: (data) => this.apiService.setTickets(),
      error: (err) => this.onError(err.error.message),
      complete: () => {
        this.loading = false;
        this.router.navigate(['/backoffice/tickets']);
      }
    })

  }

}
