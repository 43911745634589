import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '../../../../services/api.service';
import { Canale } from '../../../../shared/interfacce.model';

@Component({
  selector: 'app-aggiungi-utente-backoffice',
  templateUrl: './aggiungi-utente-backoffice.component.html',
  styleUrls: ['./aggiungi-utente-backoffice.component.css']
})
export class AggiungiUtenteBackofficeComponent implements OnInit {

  constructor(private apiService: ApiService, private router: Router) { }

  formUtente!: FormGroup;

  hide: boolean = false;
  canali: Canale[] = [];

  loading: boolean = false;
  alertMessage: string = '';
  alertType: "success" | "info" | "warning" | "error" = 'success';
  alertVisible: boolean = false;

  ngOnInit(): void {

    // Creo il form con i controlli
    this.formUtente = new FormGroup({
      username: new FormControl(null),
      password: new FormControl(null),
      tipo: new FormControl(null),
      canale: new FormControl(null)
    })

    // Recupero i canali
    this.apiService.getCanali().subscribe({
      next: (data) => this.canali = [...data],
      error: (err) => this.onError("Canali non caricati correttamente, ricaricare la pagina o aspettare qualche secondo!"),
      complete: () => { }
    })

  }

  onAnnulla() {
    this.router.navigate(['/backoffice/utenti']);
  }

  onTipo(tipo: string) {
    // Se selezionato 'Operatore' come tipo abilito la scelta del canale
    if (tipo == 'Operatore') {
      this.formUtente.get('canale')?.setValidators(Validators.required);
      this.formUtente.get('canale')?.updateValueAndValidity();
    } else {
      this.formUtente.get('canale')?.clearValidators();
      this.formUtente.get('canale')?.updateValueAndValidity();
    }
  }

  onError(message: string) {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    this.alertMessage = message;
    this.alertVisible = true;
    this.alertType = 'error';
    this.loading = false;
  }

  onSubmit() {

    // Formo l'oggetto per la richiesta dell'api
    let utente = {
      username: this.formUtente.get('username')?.value,
      password: this.formUtente.get('password')?.value,
      tipo: this.formUtente.get('tipo')?.value,
      canale: this.formUtente.get('canale')?.value
    }

    this.loading = true;

    // Aggiungo l'utente al db
    this.apiService.addUtente(utente).subscribe({
      next: (data) => this.apiService.setUtenti(),
      error: (err) => this.onError(err.error.message),
      complete: () => {
        this.loading = false;
        this.router.navigate(['/backoffice/utenti']);
      }
    })
  }

}
