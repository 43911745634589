<div class="lg:px-container px-8">

  <!-- LOGO MOBILE -->
  <div class="lg:hidden flex justify-center py-8">
    <img src="../../../assets/images/Logo bianco.png" height="40px" alt="Logo 7Live">
  </div>
  <!-- LOGO MOBILE -->

</div>

<!-- LIVE -->
<div class="liveContainer">
  <div class="gradiente"></div>  
  <div class="lg:px-container px-8 home-player lg:absolute lg:top-[40%] lg:left-[50%] w-full lg:translate-x-[-50%] lg:translate-y-[-40%]">
    <h3 class="text-white font-bold lg:text-5xl text-3xl my-8">In Evidenza</h3>    
    <!-- <app-player-live-home [id]="0"></app-player-live-home> -->
    <app-player-evidenza></app-player-evidenza>    
  </div>
  
</div>
<!-- LIVE -->

<div class="lg:px-container px-8">
  <!-- EVIDENZA -->
  <!-- <div class="evidenzaContainer">
    <div class="mb-3">
      <h3 class="text-white font-bold lg:text-5xl text-3xl my-8">In evidenza</h3>
      <app-player-evidenza></app-player-evidenza>
    </div>
  </div> -->
  <!-- EVIDENZA -->

  <!-- LOADER -->  
  <app-video-loading class="flex items-center justify-center my-8" *ngIf="loading"></app-video-loading>  
  <!-- LOADER -->

  <!-- CANALI IN EVIDENZA -->
  <div class="canali-evidenza mt-8" *ngIf="canali_evidenza.length > 0 && !loading" [@fadeInOut]>    
    <h3 class="text-white font-bold lg:text-5xl text-3xl my-8">Canali in primo piano</h3>              
    <app-galleria-canali [canali]="canali_evidenza" [evidenza]="true" class="relative"></app-galleria-canali>          
  </div>
  <!-- CANALI IN EVIDENZA -->

  <!-- TUTTI I CANALI -->
  <div class="canali" *ngIf="!loading" [@fadeInOut]>    
    <h3 class="text-white font-bold lg:text-5xl text-3xl my-8">Tutti i canali</h3>        
    <h3 class="text-white flex items-center justify-center lg:text-2xl text-xl my-8" *ngIf="canali.length <= 0">Nessun canale caricato</h3>              
    <app-galleria-canali [canali]="canali" class="relative" *ngIf="canali.length > 0"></app-galleria-canali>        
  </div>
  <!-- TUTTI I CANALI -->

  <!-- CATEGORIE ON DEMAND -->
  <div class="categorie-ondemand" *ngIf="!loading" [@fadeInOut]>
    <div class="ondemands" *ngFor="let ondemand of ondemands">
      <ng-container *ngIf="ondemand.video.length > 0" >        
        <h3 class="text-white font-bold lg:text-5xl text-3xl my-8">{{ondemand.categoria == 'Generale' ? 'Guarda anche' : ondemand.categoria }}</h3>        
        <app-galleria-video [ondemands_home]="ondemand"></app-galleria-video>        
      </ng-container>
    </div>
  </div>
  <!-- CATEGORIE ON DEMAND -->

</div>

<!-- BANNER INFO -->
<div class="info lg:my-16 my-8 relative lg:px-container px-8 lg:pe-0 flex lg:flex-row justify-center flex-col gap-4">  
  <div class="lg:w-1/2 w-full px-8 py-8 lg:my-auto mb-8 flex flex-col gap-4">      
    <h2 class="text-white text-3xl">Guarda tutto ciò che desideri, <br> quando lo vuoi tu</h2>
    <ng-container *ngIf="abbonamento">
      <p class="text-xl">Visita l'area On Demand per guardare tutti i nostri video disponibili!</p>
      <button class="bottone mt-4 w-fit" nzType="primary" nz-button [routerLink]="'/ondemand'">ON DEMAND</button>
    </ng-container>
    <ng-container *ngIf="!abbonamento">
      <p>Abbonati ora a Seven Live Web TV premendo il pulsante sottostante o accedendo alla tua area personale!</p>
      <button class="bottone mt-4 w-fit" nzType="primary" nz-button [routerLink]="user?.id ? '/profilo/abbonamento' : '/login'">ABBONATI</button>      
      <!-- <img class="d-block my-4" src="assets/images/home/GetItOnGooglePlay_Badge_Web_color_Italian.png" alt="Scarica l'app Android"> -->
    </ng-container>
    <a href="https://play.google.com/store/apps/details?id=com.sevenlive.app">
      <img class="block my-4 w-52" src="../../../assets/images/GetItOnGooglePlay_Badge_Web_color_Italian.png" alt="Scarica l'app Android">
    </a>
  </div>
  <div class="lg:w-1/2 w-full sfondo lg:bg-contain bg-cover bg-no-repeat lg:bg-right relative lg:h-full h-1/2"> </div>  
</div>
<!-- BANNER INFO -->

<!-- NEWS -->
<div class="lg:px-container px-8" *ngIf="notizie.length > 0">  
  <h3 class="text-white font-bold lg:text-5xl text-3xl lg:my-16 my-8 block">News</h3>  
  <app-notizia *ngFor="let notizia of notizie.slice(0,5); let i = index" [notizia]="notizia" [notizie]="notizie" [index]="i"></app-notizia>
  <button routerLink="/news" *ngIf="notizie.length > 5" class="bottone mt-4 block mx-auto" nzType="primary" nz-button>Vedi tutte</button>
</div>
<!-- NEWS -->