import { Component, inject } from '@angular/core';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ErrorComponent {

  constructor() { }  

  readonly #modal = inject(NzModalRef)
  readonly nzModalData = inject(NZ_MODAL_DATA);

  onClose() {
    this.#modal.close(true);
  }

}