import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../../../services/api.service';
import { Canale, Utente } from '../../../../shared/interfacce.model';

@Component({
  selector: 'app-modifica-utente-backoffice',
  templateUrl: './modifica-utente-backoffice.component.html',
  styleUrls: ['./modifica-utente-backoffice.component.css']
})
export class ModificaUtenteBackofficeComponent implements OnInit {

  constructor(private apiService: ApiService, private router: Router, private route: ActivatedRoute) { }

  formUtente!: FormGroup;

  hide: boolean = false;
  id: number = 0;

  utente?: Utente;
  canali: Canale[] = [];

  loading: boolean = false;
  alertMessage: string = '';
  alertType: "success" | "info" | "warning" | "error" = 'success';
  alertVisible: boolean = false;

  ngOnInit(): void {

    // Creo il form con i controlli
    this.formUtente = new FormGroup({
      username: new FormControl(null),
      password: new FormControl(null),
      tipo: new FormControl(null),
      canale: new FormControl(null)
    })

    // Recupero i parametri passati nell'url
    this.route.params.subscribe(params => {
      this.id = params['id'];

      // Recupero l'utente
      this.apiService.getUtente(this.id).subscribe({
        next: (data) => this.utente = data,
        error: (err) => this.router.navigate(['/backoffice/utenti']),
        complete: () => {

          // Recupero i canali
          this.apiService.getCanali().subscribe({
            next: (data) => this.canali = [...data],
            error: (err) => this.onError("Canali non caricati correttamente, ricaricare la pagina o aspettare qualche secondo!"),
            complete: () => {

              // Setto i dati dell'utente nel form
              this.formUtente.setValue({
                username: this.utente?.username,
                password: '',
                tipo: this.utente?.tipo,
                canale: this.utente?.id_canale
              })
            }
          })

        }
      })

    });

  }

  onTipo(tipo: string) {
    // Se selezionato 'Operatore' come tipo abilito la scelta del canale
    if (tipo == 'Operatore') {
      this.formUtente.get('canale')?.setValidators(Validators.required);
      this.formUtente.get('canale')?.updateValueAndValidity();
    } else {
      this.formUtente.get('canale')?.clearValidators();
      this.formUtente.get('canale')?.updateValueAndValidity();
    }
  }

  onAnnulla() {
    this.router.navigate(['/backoffice/utenti']);
  }

  onError(message: string) {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    this.alertMessage = message;
    this.alertVisible = true;
    this.alertType = 'error';
    this.loading = false;
  }

  onSubmit() {
    // Formo l'oggetto per la richiesta dell'api
    let utente = {
      username: this.formUtente.get('username')?.value,
      password: this.formUtente.get('password')?.value,
      tipo: this.formUtente.get('tipo')?.value,
      canale: this.formUtente.get('canale')?.value
    }

    this.loading = true;

    // Aggiorno l'utente nel db
    this.apiService.updateUtente(this.id, utente).subscribe({
      next: (data) => this.apiService.setUtenti(),
      error: (err) => this.onError(err.error.message),
      complete: () => {
        this.loading = false;
        this.router.navigate(['/backoffice/utenti']);
      }
    })

  }

}
