<ng-template #ticketsTitle>
    <h3 class="text-white mb-0 font-semibold text-xl">Modifica Ticket</h3>
</ng-template>    
<nz-card [nzTitle]="ticketsTitle" [nzActions]="[actionClose, actionEdit]">        
    <form [formGroup]="formTicket" (ngSubmit)="onSubmit()" id="formTicket" class="flex flex-col gap-4">
        <nz-alert [nzType]="alertType" nzShowIcon [nzMessage]="alertMessage" *ngIf="alertVisible"></nz-alert>
        <div class="flex lg:flex-row flex-col gap-4">
            <div class="lg:w-1/2 w-full">
                <nz-form-item>
                    <nz-form-label nzRequired>Categoria</nz-form-label>
                    <nz-form-control>
                        <nz-select nzShowSearch nzAllowClear formControlName="categoria" nzSize="large" class="w-full" required>
                            <nz-option nzValue="Profilo" nzLabel="Profilo"></nz-option>
                            <nz-option nzValue="Abbonamento" nzLabel="Abbonamento"></nz-option>
                            <nz-option nzValue="Live" nzLabel="Live"></nz-option>
                            <nz-option nzValue="On Demand" nzLabel="On Demand"></nz-option>
                            <nz-option nzValue="Contatti" nzLabel="Contatti"></nz-option>
                            <nz-option nzValue="Altro" nzLabel="Altro"></nz-option>
                        </nz-select>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div class="lg:w-1/2 w-full">
                <nz-form-item>
                    <nz-form-label nzRequired>Titolo</nz-form-label>
                    <nz-form-control>
                        <input nz-input nzSize="large" formControlName="titolo" required />                                     
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>            
        <div class="w-full">                
            <nz-form-item>
                <nz-form-label nzRequired>Messaggio</nz-form-label>
                <nz-form-control>
                    <textarea nz-input nzSize="large" formControlName="messaggio" required></textarea>
                </nz-form-control>
            </nz-form-item>                
        </div>      
        <div class="w-full">                
            <nz-form-item>
                <nz-form-label nzRequired>Stato</nz-form-label>
                <nz-form-control>
                    <nz-select nzShowSearch nzAllowClear formControlName="stato" nzSize="large" class="w-full" required>
                        <nz-option nzValue="Aperto" nzLabel="Aperto"></nz-option>                        
                        <nz-option nzValue="In Elaborazione" nzLabel="In Elaborazione"></nz-option>                        
                        <nz-option nzValue="Chiuso" nzLabel="Chiuso"></nz-option>                        
                    </nz-select> 
                </nz-form-control>
            </nz-form-item>                                     
        </div>          
    </form>
</nz-card>    
<ng-template #actionClose>
    <button nz-button nzSize="large" type="button" (click)="onClose()">Annulla</button>        
</ng-template>
<ng-template #actionEdit>
    <button nz-button nzSize="large" [nzLoading]="loading" type="submit" class="home-button" form="formTicket">Modifica</button>        
</ng-template>