import { defineCustomElement } from '../chunks/vidstack-CRlI3Mh7.js';
import { MediaAudioLayoutElement } from './templates/vidstack-audio-layout.js';
import { MediaVideoLayoutElement } from './templates/vidstack-video-layout.js';
import '../chunks/vidstack-Ca3C5Xan.js';
import '../chunks/vidstack-Cpte_fRf.js';
import '../chunks/vidstack-DWQEfrPg.js';
import '@floating-ui/dom';
import 'lit-html';
import '../chunks/vidstack-7xep0lg7.js';
import 'lit-html/directives/if-defined.js';
import 'lit-html/directives/unsafe-svg.js';
import 'lit-html/async-directive.js';
import '../chunks/vidstack-lwuXewh7.js';
import '../chunks/vidstack-BOTZD4tC.js';
import 'lit-html/directives/ref.js';
import '../chunks/vidstack-A9j--j6J.js';
import '../chunks/vidstack-DwhHIY5e.js';
import '../chunks/vidstack-CwTj4H1w.js';
import 'lit-html/directives/keyed.js';

defineCustomElement(MediaAudioLayoutElement);
defineCustomElement(MediaVideoLayoutElement);
