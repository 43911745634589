<form [formGroup]="formTicket" (ngSubmit)="onSubmit()" class="flex flex-col gap-4">

  <h4 class="font-semibold text-xl mb-4">C'è qualche problema? Faccelo sapere</h4>

  <nz-alert [nzType]="alertType" nzShowIcon [nzMessage]="alertMessage" *ngIf="alertVisible"></nz-alert>

  <div class="flex lg:flex-row flex-col gap-4">
    <div class="lg:w-1/2 w-full forms-inputs flex flex-col">
      <span>* Categoria</span>
      <nz-select formControlName="categoria" [nzSize]="'large'" class="w-full">
        <nz-option nzLabel="Profilo" nzValue="Profilo"></nz-option>
        <nz-option nzLabel="Abbonamento" nzValue="Abbonamento"></nz-option>
        <nz-option nzLabel="Live" nzValue="Live"></nz-option>
        <nz-option nzLabel="On Demand" nzValue="On Demand"></nz-option>
        <nz-option nzLabel="Contatti" nzValue="Contatti"></nz-option>
        <nz-option nzLabel="Altro" nzValue="Altro"></nz-option>
      </nz-select>
    </div>
    <div class="lg:w-1/2 w-full forms-inputs">
      <span>* Titolo</span>
      <input nz-input  formControlName="titolo">
    </div>
  </div>        
        
  <div class="w-full forms-inputs">
    <span>* Messaggio</span>
    <textarea nz-input formControlName="messaggio"></textarea>
  </div>     

  <div class="mt-4 text-end">
    <button class="bottone lg:w-1/4 w-full" nzType="primary" nz-button type="submit" nzSize="large" [nzLoading]="loading" [disabled]="!formTicket.valid">INVIA</button>
  </div>          
</form>

<div class="border border-dashed my-8"></div>
  
<h4 class="font-semibold text-xl mb-4">I tuoi ticket</h4>  
<p *ngIf="tickets.length <= 0" class="text-center my-4 text-muted">Non hai associato nessun ticket...</p>

<nz-table #ticketsTable *ngIf="tickets.length > 0" [nzData]="tickets" nzShowPagination [nzTotal]="(tickets).length"
  [nzPageSize]="5" [nzPageSizeOptions]="[5, 10, 20, 30, 50, 100]" nzShowSizeChanger class="p-0">
  <thead>
    <tr>
      <th>Titolo</th>
      <th>Data</th>
      <th>Stato</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of ticketsTable.data">
      <td [nzTooltipTitle]="'Messaggio: ' + data.messaggio" nz-tooltip nzTooltipPlacement="top">{{ data.titolo}}
      </td>
      <td>{{ data.data_creazione | date:'dd/MM/yyyy' }}</td>
      <td>{{ data.stato }}</td>
    </tr>
  </tbody>
</nz-table>  