<nz-card [nzActions]="[actionClose, actionAdd]">    
    <form [formGroup]="formCanale" (ngSubmit)="onSubmit()" id="formCanale" class="flex flex-col gap-4">        
        <nz-alert [nzType]="alertType" nzShowIcon [nzMessage]="alertMessage" *ngIf="alertVisible"></nz-alert>
        <nz-form-item>
            <nz-form-label nzRequired>Nome</nz-form-label>
            <nz-form-control>
                <input nz-input nzSize="large" formControlName="nome" required />
            </nz-form-control>
        </nz-form-item>        
        
        <nz-form-item class="flex-column mb-2">
            <nz-form-label class="text-start">Descrizione</nz-form-label>
            <nz-form-control>
                <textarea nz-input nzSize="large" formControlName="descrizione"></textarea>
            </nz-form-control>
        </nz-form-item>     

        <label nz-checkbox formControlName="evidenza" class="checkbox flex justify-center" nzTooltipTitle="Se selezionato, il canale verrà inserito in Home Page nella lista dei canli in evidenza!" nz-tooltip nzTooltipPlacement="top">Canale in evidenza</label>                     
        <nz-tabset>
            <nz-tab nzTitle="Icona">
                <nz-upload [(nzFileList)]="fileIconaList" [nzShowButton]="fileIconaList.length <= 0" [nzBeforeUpload]="beforeIconaUpload" nzAccept="image/*" [nzRemove]="removeIconaUpload">
                    <button nzSize="large" nz-button type="button" class="home-button">
                        <span class="align-middle" nz-icon nzType="upload"></span>
                        Carica icona
                    </button>
                </nz-upload>   
                <img [src]="icona" alt="Icona" class="mt-4 w-full text-center" *ngIf="icona.length > 0">
            </nz-tab>
            <nz-tab nzTitle="Copertina">
                <nz-upload [(nzFileList)]="fileCopertinaList" [nzShowButton]="fileCopertinaList.length <= 0" [nzBeforeUpload]="beforeCopertinaUpload" nzAccept="image/*" [nzRemove]="removeCopertinaUpload">
                    <button nzSize="large" nz-button type="button" class="home-button">
                        <span class="align-middle" nz-icon nzType="upload"></span>
                        Carica copertina
                    </button>
                </nz-upload>                            
                <img [src]="copertina" alt="Copertina" class="mt-4 w-full text-center" *ngIf="copertina.length > 0">
            </nz-tab>                            
        </nz-tabset>        
    </form>      
</nz-card>    
<ng-template #actionClose>
    <button nz-button nzSize="large" type="button" (click)="onAnnulla()">Annulla</button>
</ng-template>
<ng-template #actionAdd>
    <button nz-button nzSize="large" type="submit" class="home-button" form="formCanale" [nzLoading]="loading">Aggiungi</button>                              
</ng-template>                            
