<div class="bg-image conferma flex items-center lg:flex-row">    
  <div class="lg:w-1/2 lg:flex hidden"></div>
  <div class="lg:w-1/2 w-full h-full lg:px-16 px-8 flex items-center conferma bg-light">
      <div class="flex flex-col items-center gap-4 py-8">
        
        <ng-container *ngIf="!error">
          <video class="mix-blend-multiply w-52 h-52" loop autoplay muted>
            <source src="../../../assets/videos/success.mp4" type="video/mp4">
          </video>

          <h3 class="text-xl text-center">La tua mail è stata confermata con successo, ora puoi accedere alla tua area personale</h3>
        </ng-container>      

        <ng-container *ngIf="error">

          <video class="mix-blend-multiply w-52 h-52" loop autoplay muted>
            <source src="../../../assets/videos/error.mp4" type="video/mp4">
          </video>

          <h3 class="text-xl text-center">Un'errore è stato riscontrato nella conferma della tua email. Riprova tra qualche minuto oppure contattaci a <a class="hover:underline hover:text-black font-semibold" href="mailto:webtv@7live.tv">webtv&#64;7live.tv</a></h3>
        </ng-container>                      
        
        <button nz-button type="button" nzType="primary" class="bottone lg:w-2/3 w-full mt-4" routerLink="/login">{{error ? 'Torna al Login' : 'Accedi' }}</button>        
      
    </div>

  </div>
</div>