import { Component, OnInit } from '@angular/core';
import { StorageService } from '../../services/storage.service';

@Component({
  selector: 'app-cookies',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.css']
})
export class CookiesComponent implements OnInit {

  constructor(private storageService: StorageService) { }

  cookies: boolean = false;
  dismissed: boolean = false;

  ngOnInit(): void {
    this.cookies = this.storageService.getCookie().length > 0 ? true : false    
  }

  dismiss(){
    this.dismissed = true;
  }

  accept(type: string){    
    this.storageService.setCookie(type);
    this.dismiss();
  }

  decline(){
    this.storageService.clearCookie()    
    this.dismiss();
  }

}
