import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AbbonamentoGuard } from './guard/abbonamento.guard';
import { AuthGuard } from './guard/auth.guard';
import { AuthGuardBackOffice } from './guard/auth-backoffice.guard';
import { operatoreGuard } from './guard/operatore.guard';

import { HeaderComponent } from './common/header/header.component';
import { HomeComponent } from './pages/home/home.component';
import { NewsComponent } from './pages/news/news.component';
import { OnDemandComponent } from './pages/on-demand/on-demand.component';
import { CanaleComponent } from './pages/canale/canale.component';
import { LoginComponent } from './pages/login/login.component';
import { RegistrazioneComponent } from './pages/registrazione/registrazione.component';
import { ResetComponent } from './pages/reset/reset.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { CondizioniComponent } from './pages/condizioni/condizioni.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { ContattaciComponent } from './pages/contattaci/contattaci.component';
import { ConfermaComponent } from './pages/conferma/conferma.component';
import { LiveComponent } from './pages/live/live.component';
import { NessunAbbonamentoComponent } from './common/nessun-abbonamento/nessun-abbonamento.component';

import { ProfiloComponent } from './pages/profilo/profilo.component';
import { GestisciProfiloComponent } from './pages/profilo/gestisci-profilo/gestisci-profilo.component';
import { AbbonamentiProfiloComponent } from './pages/profilo/abbonamenti-profilo/abbonamenti-profilo.component';
import { TicketProfiloComponent } from './pages/profilo/ticket-profilo/ticket-profilo.component';
import { PagamentoProfiloComponent } from './pages/profilo/pagamento-profilo/pagamento-profilo.component';
import { AnnulloPagamentoComponent } from './pages/profilo/annullo-pagamento/annullo-pagamento.component';

import { BackofficeComponent } from './pages/backoffice/backoffice.component';
import { LoginBackofficeComponent } from './pages/backoffice/login-backoffice/login-backoffice.component';
import { HeaderBackofficeComponent } from './pages/backoffice/header-backoffice/header-backoffice.component';
import { CanaliBackofficeComponent } from './pages/backoffice/canali-backoffice/canali-backoffice.component';
import { HomepageBackofficeComponent } from './pages/backoffice/homepage-backoffice/homepage-backoffice.component';
import { AggiungiCanaleBackofficeComponent } from './pages/backoffice/canali-backoffice/aggiungi-canale-backoffice/aggiungi-canale-backoffice.component';
import { ModificaCanaleBackofficeComponent } from './pages/backoffice/canali-backoffice/modifica-canale-backoffice/modifica-canale-backoffice.component';
import { LiveBackofficeComponent } from './pages/backoffice/live-backoffice/live-backoffice.component';
import { AggiungiLiveBackofficeComponent } from './pages/backoffice/live-backoffice/aggiungi-live-backoffice/aggiungi-live-backoffice.component';
import { ModificaLiveBackofficeComponent } from './pages/backoffice/live-backoffice/modifica-live-backoffice/modifica-live-backoffice.component';
import { OndemandBackofficeComponent } from './pages/backoffice/ondemand-backoffice/ondemand-backoffice.component';
import { AggiungiOndemandBackofficeComponent } from './pages/backoffice/ondemand-backoffice/aggiungi-ondemand-backoffice/aggiungi-ondemand-backoffice.component';
import { ModificaOndemandBackofficeComponent } from './pages/backoffice/ondemand-backoffice/modifica-ondemand-backoffice/modifica-ondemand-backoffice.component';
import { CategorieBackofficeComponent } from './pages/backoffice/categorie-backoffice/categorie-backoffice.component';
import { AggiungiCategoriaBackofficeComponent } from './pages/backoffice/categorie-backoffice/aggiungi-categoria-backoffice/aggiungi-categoria-backoffice.component';
import { ModificaCategoriaBackofficeComponent } from './pages/backoffice/categorie-backoffice/modifica-categoria-backoffice/modifica-categoria-backoffice.component';
import { ClientiBackofficeComponent } from './pages/backoffice/clienti-backoffice/clienti-backoffice.component';
import { AggiungiClienteBackofficeComponent } from './pages/backoffice/clienti-backoffice/aggiungi-cliente-backoffice/aggiungi-cliente-backoffice.component';
import { ModificaClienteBackofficeComponent } from './pages/backoffice/clienti-backoffice/modifica-cliente-backoffice/modifica-cliente-backoffice.component';
import { ModificaPasswordClienteBackofficeComponent } from './pages/backoffice/clienti-backoffice/modifica-password-cliente-backoffice/modifica-password-cliente-backoffice.component';
import { AbbonamentiBackofficeComponent } from './pages/backoffice/abbonamenti-backoffice/abbonamenti-backoffice.component';
import { TicketsBackofficeComponent } from './pages/backoffice/tickets-backoffice/tickets-backoffice.component';
import { AggiungiTicketBackofficeComponent } from './pages/backoffice/tickets-backoffice/aggiungi-ticket-backoffice/aggiungi-ticket-backoffice.component';
import { ModificaTicketBackofficeComponent } from './pages/backoffice/tickets-backoffice/modifica-ticket-backoffice/modifica-ticket-backoffice.component';
import { NewsBackofficeComponent } from './pages/backoffice/news-backoffice/news-backoffice.component';
import { AggiungiNewsBackofficeComponent } from './pages/backoffice/news-backoffice/aggiungi-news-backoffice/aggiungi-news-backoffice.component';
import { ModificaNewsBackofficeComponent } from './pages/backoffice/news-backoffice/modifica-news-backoffice/modifica-news-backoffice.component';
import { UtentiBackofficeComponent } from './pages/backoffice/utenti-backoffice/utenti-backoffice.component';
import { AggiungiUtenteBackofficeComponent } from './pages/backoffice/utenti-backoffice/aggiungi-utente-backoffice/aggiungi-utente-backoffice.component';
import { ModificaUtenteBackofficeComponent } from './pages/backoffice/utenti-backoffice/modifica-utente-backoffice/modifica-utente-backoffice.component';

const routes: Routes = [
  {
    path: '', component: HeaderComponent, children: [
      { path: '', pathMatch: 'full', redirectTo: '/home' },
      { path: 'home', component: HomeComponent, data: { title: 'Home' } },
      { path: 'news', component: NewsComponent, data: { title: 'News' } },
      { path: 'live', component: LiveComponent, data: { title: 'Live' } },
      { path: 'ondemand', component: OnDemandComponent, data: { title: 'Video On Demand' } },
      { path: 'ondemand/:id', component: OnDemandComponent, canActivate: [AbbonamentoGuard], data: { title: 'On Demand' } },
      { path: 'abbonamento', component: NessunAbbonamentoComponent, data: { title: 'No abbonamento' } },
      { path: 'canali/:id', component: CanaleComponent, data: { title: 'Canale' } },
      {
        path: 'profilo', component: ProfiloComponent, canActivate: [AuthGuard], children: [
          { path: '', pathMatch: 'full', component: GestisciProfiloComponent, data: { title: 'Profilo' } },
          { path: 'abbonamento', component: AbbonamentiProfiloComponent, data: { title: 'Abbonamento' } },
          { path: 'ticket', component: TicketProfiloComponent, data: { title: 'Tickets' } },
          { path: 'pagamento', component: PagamentoProfiloComponent, data: { title: 'Pagamento' } },
          { path: 'annullo', component: AnnulloPagamentoComponent, data: { title: 'Annullo pagamento' } },
        ]
      },
      { path: 'login', component: LoginComponent, data: { title: 'Login' } },
      { path: 'registrazione', component: RegistrazioneComponent, data: { title: 'Registrazione' } },
      { path: 'conferma', component: ConfermaComponent, data: { title: 'Conferma registrazione' } },
      { path: 'reset', component: ResetComponent, data: { title: 'Richiesta reset password' } },
      { path: 'reset-password', component: ResetPasswordComponent, data: { title: 'Reset password' } },
      { path: 'condizioni', component: CondizioniComponent, data: { title: 'Condizioni generali di fornitura' } },
      { path: 'privacy', component: PrivacyComponent, data: { title: 'Informativa privacy' } },
      { path: 'contattaci', component: ContattaciComponent, data: { title: 'Contattaci' } },
    ]
  },
  {
    path: 'backoffice', component: BackofficeComponent, children: [
      { path: 'login', pathMatch: 'full', component: LoginBackofficeComponent, data: { title: 'Login Backoffice' } },
      {
        path: '', component: HeaderBackofficeComponent, canActivate: [AuthGuardBackOffice], children: [
          { path: '', pathMatch: 'full', redirectTo: 'home' },
          { path: 'home', component: HomepageBackofficeComponent, data: { title: 'Dashboard backoffice' } },
          { path: 'canali', component: CanaliBackofficeComponent, data: { title: 'Canali backoffice' } },
          { path: 'canali/add', component: AggiungiCanaleBackofficeComponent, canActivate: [operatoreGuard], data: { title: 'Aggiungi canale backoffice' } },
          { path: 'canali/:id', component: ModificaCanaleBackofficeComponent, data: { title: 'Modifica canale backoffice' } },
          { path: 'live', component: LiveBackofficeComponent, data: { title: 'Live backoffice' } },
          { path: 'live/add', component: AggiungiLiveBackofficeComponent, data: { title: 'Aggiungi live backoffice' } },
          { path: 'live/:id', component: ModificaLiveBackofficeComponent, pathMatch: 'full', data: { title: 'Modifica live backoffice' } },
          { path: 'ondemand', component: OndemandBackofficeComponent, data: { title: 'Ondemand backoffice' } },
          { path: 'ondemand/add', component: AggiungiOndemandBackofficeComponent, data: { title: 'Aggiungi ondemand backoffice' } },
          { path: 'ondemand/:id', component: ModificaOndemandBackofficeComponent, data: { title: 'Modifica ondemand backoffice' } },
          { path: 'categorie', component: CategorieBackofficeComponent, data: { title: 'Categorie backoffice' } },
          { path: 'categorie/add', component: AggiungiCategoriaBackofficeComponent, canActivate: [operatoreGuard], data: { title: 'Aggiungi categoria backoffice' } },
          { path: 'categorie/:id', component: ModificaCategoriaBackofficeComponent, canActivate: [operatoreGuard], data: { title: 'Modifica categoria backoffice' } },
          { path: 'clienti', component: ClientiBackofficeComponent, canActivate: [operatoreGuard], data: { title: 'Clienti backoffice' } },
          { path: 'clienti/add', component: AggiungiClienteBackofficeComponent, canActivate: [operatoreGuard], data: { title: 'Aggiungi cliente backoffice' } },
          { path: 'clienti/:id', component: ModificaClienteBackofficeComponent, canActivate: [operatoreGuard], data: { title: 'Modifica cliente backoffice' } },
          { path: 'clienti/:id/password', component: ModificaPasswordClienteBackofficeComponent, canActivate: [operatoreGuard], data: { title: 'Modifica password cliente backoffice' } },
          { path: 'abbonamenti', component: AbbonamentiBackofficeComponent, canActivate: [operatoreGuard], data: { title: 'Abbonamenti backoffice' } },
          { path: 'tickets', component: TicketsBackofficeComponent, canActivate: [operatoreGuard], data: { title: 'Tickets backoffice' } },
          { path: 'tickets/add', component: AggiungiTicketBackofficeComponent, canActivate: [operatoreGuard], data: { title: 'Aggiungi ticket backoffice' } },
          { path: 'tickets/:id', component: ModificaTicketBackofficeComponent, canActivate: [operatoreGuard], data: { title: 'Modifica ticket backoffice' } },
          { path: 'news', component: NewsBackofficeComponent, canActivate: [operatoreGuard], data: { title: 'News backoffice' } },
          { path: 'news/add', component: AggiungiNewsBackofficeComponent, canActivate: [operatoreGuard], data: { title: 'Aggiungi news backoffice' } },
          { path: 'news/:id', component: ModificaNewsBackofficeComponent, canActivate: [operatoreGuard], data: { title: 'Modifica news backoffice' } },
          { path: 'utenti', component: UtentiBackofficeComponent, canActivate: [operatoreGuard], data: { title: 'Utenti backoffice' } },
          { path: 'utenti/add', component: AggiungiUtenteBackofficeComponent, canActivate: [operatoreGuard], data: { title: 'Aggiungi utente backoficce' } },
          { path: 'utenti/:id', component: ModificaUtenteBackofficeComponent, canActivate: [operatoreGuard], data: { title: 'Modifica utente backoffice' } },
        ]
      }
    ]
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: "top" })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
