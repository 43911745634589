<div class="flex lg:flex-row flex-col w-full">
    <div class="lg:w-2/3 w-full">
        <ng-container *ngFor="let live of lives; let i = index">
            <!-- <div [id]="'target-' + live.id"  *ngIf="live.selected"></div> -->
            <media-player oncontextmenu="return false;" view-type="video" stream-type="live" log-level="warn" muted crossorigin playsinline autoplay loop preload="auto" [storage]="'7live-' + live.titolo" [title]="live.titolo" [src]="live.cloudflare_video_url" *ngIf="live.selected">
                <media-provider>
                    <media-poster class="absolute inset-0 block h-full w-full rounded-md opacity-0 transition-opacity data-[visible]:opacity-100 [&>img]:h-full [&>img]:w-full [&>img]:object-cover"  [src]="live.cloudflare_image_url"></media-poster>
                </media-provider>
                <media-video-layout></media-video-layout>
            </media-player>
        </ng-container>
    </div>
    <div class="lg:w-1/3 w-full ps-0 lista lg:block hidden">
        <ul class="player-list">
            <p>In evidenza:</p>

            <div *ngIf="!carica" [@fadeInOut]>
                <li *ngFor="let live of lives;" (click)="onVideoChange(live)" [ngClass]="{'selected': live.selected}">
                    <div class="flex lg:flex-row flex-col gap-4 items-center">
                        <div class="lg:w-1/3 w-full my-auto">
                            <img #icona
                                [src]="live.icona ? url + 'immagine/' + live.icona : '../../../assets/images/Logo bianco.png'"
                                (error)="icona.src = '../../../assets/images/Logo bianco.png'" class="watermark"
                                alt="Icona">
                        </div>
                        <div class="lg:w-2/3 w-full my-auto" [attr.title]="live.canale">
                            <p class="font-semibold">{{ live.canale }}</p>
                        </div>
                    </div>
                </li>
                <li *ngIf="lives.length <= 0" [@fadeInOut]>Nessun live presente</li>
            </div>
            <li *ngIf="carica" class="text-center">
                <app-video-loading></app-video-loading>
            </li>
        </ul>
    </div>
</div>

<div class="lives-list lg:hidden" *ngIf="lives.length > 0">
    <div class="playerlist-icon" *ngFor="let live of lives" (click)="onVideoChange(live)" [ngClass]="{'selected-mobile': live.selected}">
        <img #icona_mobile [src]="live.icona ? url + 'immagine/' + live.icona : '../../../assets/images/Logo bianco.png'" (error)="icona_mobile.src = '../../../assets/images/Logo bianco.png'" class="watermark" alt="Icona">
    </div>
</div>