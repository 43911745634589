<div class="login flex items-center lg:flex-row bg-image">              
    <div class="lg:w-1/2 w-full lg:flex hidden"></div>
    <div class="lg:w-1/2 w-full h-full flex items-center lg:px-16 p-8 bg-light login">
        <div class="form mx-auto">
            <h3 class="text-4xl font-bold mb-4">Login</h3>
            <p class="text-muted mb-4">Accedi alla tua Area personale, gestisci i tuoi dati e il tuo abbonamento a Seven Live Web TV, per poter visionare i nostri fantastici video On Demand!</p>            
            <form [formGroup]="formLogin" (ngSubmit)="onLogin()" class="flex flex-col gap-4">
                <nz-alert [nzType]="alertType" nzShowIcon [nzMessage]="alertMessage" *ngIf="alertVisible"></nz-alert>
                <div class="forms-inputs w-full">
                    <span>* Email</span>
                    <input nz-input autocomplete="off" type="text" type="email" formControlName="email">
                </div>
                <div class="forms-inputs w-full">
                    <span>* Password</span>
                    <nz-input-group [nzSuffix]="suffixTemplate">
                        <input [type]="hide ? 'text' : 'password'" nz-input formControlName="password" autocomplete />
                    </nz-input-group>
                    <ng-template #suffixTemplate> <span nz-icon [nzType]="hide ? 'eye-invisible' : 'eye'" (click)="hide = !hide" class="cursor-pointer"></span></ng-template>
                </div>                
                <p class="text-right cursor-pointer hover:underline mt-4" routerLink="/reset">Hai dimenticato la password? Clicca qui!</p>                
                <div class="mt-4 text-center">
                    <button class="lg:w-2/3 w-full bottone m-0" nzType="primary" nz-button type="submit" nzSize="large" [nzLoading]="loading" [disabled]="!formLogin.valid">Accedi</button>
                    <nz-divider class="text-black" nzText="oppure"></nz-divider>
                    <button class="lg:w-2/3 w-full bottone-secondario" nz-button type="button" routerLink="/registrazione">Registrati</button>
                </div>                
            </form>
                    
        </div>
    </div>    
</div>