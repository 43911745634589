import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { EliminaUtenteBackofficeComponent } from './elimina-utente-backoffice/elimina-utente-backoffice.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Utente } from '../../../shared/interfacce.model';
import { ApiService } from '../../../services/api.service';

@Component({
  selector: 'app-utenti-backoffice',
  templateUrl: './utenti-backoffice.component.html',
  styleUrls: ['./utenti-backoffice.component.css']
})
export class UtentiBackofficeComponent implements OnInit {

  utenti: Utente[] = [];

  usernameFilter: any = '';
  tipoFilter: any = '';

  constructor(private apiService: ApiService, private router: Router, private modal: NzModalService, private viewContainerRef: ViewContainerRef) { }

  ngOnInit(): void {

    // Recupero gli utenti
    this.apiService.setUtenti();
    this.apiService.utentiChanged.subscribe({
      next: (data) => this.utenti = [...data],
      error: (err) => this.apiService.error(err.error.message),
      complete: () => { }
    });
  }

  onAdd() {
    this.router.navigate(['/backoffice/utenti/add']);
  }

  onEdit(id: number) {
    this.router.navigate(['/backoffice/utenti/' + id])
  }

  onElimina(id: number) {

    // Formo il modal per l'eliminazione dell'utente
    this.modal.create({
      nzTitle: 'Conferma',
      nzContent: EliminaUtenteBackofficeComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzData: {
        id_utente: id
      },
      nzFooter: null,
      nzCentered: true,
      nzWidth: 750,
      nzBodyStyle: { 'padding': '0px' }
    });
  }

  onOrdinamentoUsername(a: Utente, b: Utente) {
    return a.username.localeCompare(b.username);
  }

  onOrdinamentoTipo(a: Utente, b: Utente) {
    return a.tipo.localeCompare(b.tipo);
  }


}
