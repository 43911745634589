<div class="box rounded-lg" [class.hidden]="dismissed || cookies">
    <div class="x absolute" (click)="dismiss()">
        <i class="bi bi-x"></i>
    </div>
  <div class="container mx-auto p-4">    
    <h5 class="text-white font-semibold">Abbiamo a cuore la tua privacy</h5>
    <p class="text-sm my-2">Usiamo i cookies per migliorare la tua esperienza.<br> Cliccando "Accetta", consenti dei cookies secondo la nostra <a class="text-white" routerLink="/privacy">Privacy policy.</a></p>    
    <div class="flex lg:flex-row flex-col gap-1 mt-4">      
      <div class="btn-cookie" (click)="accept('all')">Accetta tutto</div>      
      <div class="btn-cookie" (click)="accept('basic')">Solo essenziali</div>            
      <div class="btn-cookie-delete" (click)="decline()">Rifiuta</div>      
    </div>
  </div>
</div>
