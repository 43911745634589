<ng-template #newsTitle>
    <h3 class="text-white mb-0 text-xl font-semibold">Modifica News</h3>
</ng-template>    
<nz-card [nzTitle]="newsTitle" [nzActions]="[actionClose, actionAdd]">        
    <form [formGroup]="formNews" (ngSubmit)="onSubmit()" id="formNews" class="flex flex-col gap-4">                        
        <nz-alert [nzType]="alertType" nzShowIcon [nzMessage]="alertMessage" *ngIf="alertVisible"></nz-alert>
        <div class="flex lg:flex-row flex-col gap-4">
            <div class="lg:w-1/2 w-full">
                <nz-form-item>
                    <nz-form-label nzRequired>Titolo</nz-form-label>
                    <nz-form-control>
                        <input nz-input nzSize="large" formControlName="titolo" required />
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div class="lg:w-1/2 w-full">
                <nz-form-item>
                    <nz-form-label nzRequired>Data</nz-form-label>
                    <nz-form-control>
                        <nz-date-picker formControlName="data" [nzMode]="'date'" nzSize="large" [nzFormat]="'dd/MM/yyyy'" class="w-full"></nz-date-picker>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>            
        <div class="w-full">
            <nz-form-item>
                <nz-form-label nzRequired class="text-start">Descrizione</nz-form-label>
                <nz-form-control>
                    <textarea nz-input nzSize="large" formControlName="descrizione" required></textarea>
                </nz-form-control>
            </nz-form-item>                                    
        </div>            
        <div class="w-full">                
            <nz-upload [(nzFileList)]="imageList" [nzShowButton]="imageList.length <= 0" [nzBeforeUpload]="beforeImageUpload" nzAccept="image/*" [nzRemove]="removeImageUpload" class="w-full">
                <button nzSize="large" nz-button type="button" class="home-button">
                  <span class="align-middle" nz-icon nzType="upload"></span>
                  Carica immagine di copertina
                </button>
            </nz-upload>                            
            <img [src]="copertina" alt="Copertina" class="mt-4 w-full" *ngIf="copertina.length > 0">                                    
        </div>          
    </form>
</nz-card>    
<ng-template #actionClose>
    <button nz-button nzSize="large" type="button" (click)="onClose()">Annulla</button>        
</ng-template>
<ng-template #actionAdd>
    <button nz-button nzSize="large" [nzLoading]="loading" type="submit" class="home-button" form="formNews">Modifica</button>        
</ng-template>    