<ng-template #canaliTitle>        
    <div class="flex lg:flex-row flex-col gap-4 items-center justify-between">
        <div class="lg:w-2/3 w-full">
            <h3 class="text-white mb-0 text-xl font-semibold">Canali</h3>
        </div>
        <div class="lg:w-1/3 w-full" *ngIf="user?.tipo == 'Amministratore'">
            <button nz-button nzType="primary" nzSize="large" (click)="onAdd()" class="home-button w-full">Aggiungi canale</button>
        </div>
    </div>        
    <div class="flex lg:flex-row flex-col gap-4 items-center mt-4">            
        <div class="lg:w-1/2 w-full">
            <input nz-input placeholder="Cerca Nome canale" nzSize="large" [(ngModel)]="titoloFilter" />
        </div>
        <div class="lg:w-1/2 w-full">
            <input nz-input placeholder="Cerca Descrizione canale" nzSize="large" [(ngModel)]="descrizioneFilter" />
        </div>                            
    </div>
</ng-template>
<nz-card [nzTitle]="canaliTitle" class="w-full">    
    <nz-table #canaliTable nzShowPagination class="tabella"
        [nzTotal]="(canali | searchTitoloCanale: titoloFilter | searchDescrizioneCanale: descrizioneFilter).length"
        nzShowSizeChanger [nzPageSize]="10"
        [nzPageSizeOptions]="[5, 10, 25, 50, 100, 150, 200, 250, 300, 350, 400, 500, 750, 1000]"
        [nzData]="canali | searchTitoloCanale: titoloFilter | searchDescrizioneCanale: descrizioneFilter"
        [nzBordered]="true" [nzNoResult]="'Nessun canale trovato'">
        <thead>
            <tr>
                <th [nzSortFn]="onOrdinamentoTitolo">Titolo</th>
                <th [nzSortFn]="onOrdinamentoDescrizione" class="text-start">Descrizione</th>                        
                <th class="!text-center">Azioni</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let canale of canaliTable.data | searchTitoloCanale: titoloFilter | searchDescrizioneCanale: descrizioneFilter">
                <td>{{canale.nome}}</td>
                <td class="text-start">{{canale.descrizione}}</td>                        
                <td class="text-center">
                    <nz-button-group>                                
                        <button nz-button nz-dropdown [nzDropdownMenu]="menu" nzPlacement="bottomRight">
                            <span nz-icon nzType="menu"></span>
                        </button>
                  </nz-button-group>
                  <nz-dropdown-menu #menu="nzDropdownMenu">
                    <ul nz-menu>
                      <li nz-menu-item class="!p-4" (click)="onEdit(canale.id)">Modifica canale</li>
                      <li nz-menu-item class="!p-4" (click)="onEliminaImmagine('icona', canale.icona, canale.id)" *ngIf="canale.icona">Elimina icona canale</li>
                      <li nz-menu-item class="!p-4" (click)="onEliminaImmagine('copertina', canale.copertina, canale.id)" *ngIf="canale.copertina">Elimina copertina canale</li>
                      <li nz-menu-item class="!p-4" (click)="onEliminaCanale(canale.id)" *ngIf="this.user?.tipo == 'Amministratore'">Elimina canale</li>
                    </ul>
                  </nz-dropdown-menu>
                </td>
            </tr>
        </tbody>
    </nz-table>                
</nz-card>