import { Component, Input } from '@angular/core';
import { News } from '../../shared/interfacce.model';

@Component({
  selector: 'app-notizia',
  templateUrl: './notizia.component.html',
  styleUrls: ['./notizia.component.css']
})
export class NotiziaComponent {

  constructor() { }

  @Input() notizia!: News
  @Input() index!: number;
  @Input() notizie: News[] = []

  onExpand(news: News){
    news.espandi = !news.espandi;
  }
}
