import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Ondemand, OndemandHome } from '../../shared/interfacce.model';

@Component({
  selector: 'app-galleria-video',
  templateUrl: './galleria-video.component.html',
  styleUrls: ['./galleria-video.component.css']
})
export class GalleriaVideoComponent {
  
  @Input() ondemands_home?: OndemandHome;
  @Input() ondemands: Ondemand[] = [];

  constructor(private router: Router) { }  

  onOndemand(id: number) {
    this.router.navigate(['/ondemand/' + btoa("id_ondemand=" + id)]);
  }
}
