<ng-container *ngIf="user?.tipo == 'Amministratore'">

    <div class="flex lg:flex-row flex-col gap-4 home">
        <div class="lg:w-1/2 w-full">
            <ng-template #canaliTitle>
                <h5 class="card-title">Canali <i class="bi bi-tv"></i></h5>
            </ng-template>
            <nz-card [nzTitle]="canaliTitle">
                <h6 class="card-subtitle mb-2 text-muted">Gestisci i canali</h6>
                <p class="card-text">Visualizza e gestisci i canali. Puoi crearne di nuovi o cancellare quelli esistenti.</p>                
                <a nz-button routerLink="/backoffice/canali" nzType="primary" nzSize="large" class="mt-auto home-button w-full">Vai ai Canali</a>
            </nz-card>            
        </div>
        <div class="lg:w-1/2 w-full">
            <ng-template #categorieTitle>
                <h5 class="card-title">Categorie <i class="bi bi-bookmark"></i></h5>
            </ng-template>
            <nz-card [nzTitle]="categorieTitle">
                <h6 class="card-subtitle mb-2 text-muted">Gestisci le categorie</h6>
                <p class="card-text">Visualizza e gestisci le categorie dei video. Puoi crearne di nuove o cancellare quelle esistenti.</p>
                <a nz-button routerLink="/backoffice/categorie" nzType="primary" nzSize="large" class="mt-auto home-button w-full">Vai alle categorie</a>
            </nz-card>               
        </div>

    </div>    
    <div class="flex lg:flex-row flex-col gap-4 mt-4 home">
        <div class="lg:w-1/2 w-full">
            <ng-template #ondemandTitle>
                <h5 class="card-title">On Demand <i class="bi bi-person-video"></i></h5>
            </ng-template>
            <nz-card [nzTitle]="ondemandTitle">
                <h6 class="card-subtitle mb-2 text-muted">Gestisci i video On Demand</h6>
                <p class="card-text">Visualizza e gestisci i video On Demand. Puoi crearne di nuove o cancellare quelle esistenti.</p>
                <a nz-button routerLink="/backoffice/ondemand" nzType="primary" nzSize="large" class="mt-auto home-button w-full">Vai agli On Demand</a>
            </nz-card>                
        </div>
        <div class="lg:w-1/2 w-full">
            <ng-template #liveTitle>
                <h5 class="card-title">Live <i class="bi bi-camera-reels-fill"></i></h5>
            </ng-template>
            <nz-card [nzTitle]="liveTitle">
                <h6 class="card-subtitle mb-2 text-muted">Gestisci i video live</h6>
                <p class="card-text">Visualizza e gestisci i video live. Puoi crearne di nuove o cancellare quelle esistenti.</p>
                <a nz-button routerLink="/backoffice/live" nzType="primary" nzSize="large" class="mt-auto home-button w-full">Vai ai Live</a>
            </nz-card>                 
        </div>
    </div>    
    <div class="flex lg:flex-row flex-col gap-4 mt-4 home">
        <div class="lg:w-1/2 w-full">
            <ng-template #clientiTitle>
                <h5 class="card-title">Clienti <i class="bi bi-person"></i></h5>
            </ng-template>
            <nz-card [nzTitle]="clientiTitle">
                <h6 class="card-subtitle mb-2 text-muted">Gestisci i clienti</h6>
                <p class="card-text">Visualizza e modifica le informazioni relative ai clienti.</p>
                <a nz-button routerLink="/backoffice/clienti" nzType="primary" nzSize="large" class="mt-auto home-button w-full">Vai ai Clienti</a>
            </nz-card>
        </div>
        <div class="lg:w-1/2 w-full">
            <ng-template #abbonamentiTitle>
                <h5 class="card-title">Abbonamenti <i class="bi bi-credit-card-fill"></i></h5>
            </ng-template>
            <nz-card [nzTitle]="abbonamentiTitle">
                <h6 class="card-subtitle mb-2 text-muted">Gestisci gli abboanamenti</h6>
                <p class="card-text">Visualizza e gestisci gli abbonamenti. Puoi crearne di nuovi o cancellare quelli esistenti.</p>
                <a nz-button routerLink="/backoffice/abbonamenti" nzType="primary" nzSize="large" class="mt-auto home-button w-full">Vai agli Abbonamenti</a>
            </nz-card>
        </div>
    </div>
    <div class="flex lg:flex-row flex-col gap-4 mt-4 home">
        <div class="lg:w-1/3 w-full">
            <ng-template #ticketTitle>
                <h5 class="card-title">Ticket <i class="bi bi-ticket-perforated"></i></h5>
            </ng-template>
            <nz-card [nzTitle]="ticketTitle">
                <h6 class="card-subtitle mb-2 text-muted">Gestisci i Ticket</h6>
                <p class="card-text">Visualizza lo storico di tutti i ticket emessi o crea un nuovo ticket.</p>
                <a nz-button routerLink="/backoffice/tickets" nzType="primary" nzSize="large" class="mt-auto home-button w-full">Vai ai Tickets </a>
            </nz-card>               
        </div>
        <div class="lg:w-1/3 w-full">
            <ng-template #newsTitle>
                <h5 class="card-title">News <i class="bi bi-newspaper"></i></h5>
            </ng-template>
            <nz-card [nzTitle]="newsTitle">
                <h6 class="card-subtitle mb-2 text-muted">Gestisci le news</h6>
                <p class="card-text">Visualizza e gestisci le news. Puoi crearne di nuove o cancellare quelle esistenti.</p>
                <a nz-button routerLink="/backoffice/news" nzType="primary" nzSize="large" class="mt-auto home-button w-full">Vai alle News</a>
            </nz-card>                
        </div>
        <div class="lg:w-1/3 w-full">
            <ng-template #utentiTitle>
                <h5 class="card-title">Utenti <i class="bi bi-person-circle"></i></h5>
            </ng-template>
            <nz-card [nzTitle]="utentiTitle">
                <h6 class="card-subtitle mb-2 text-muted">Gestisci gli utenti</h6>
                <p class="card-text">Visualizza e gestisci gli utenti. Puoi crearne di nuovi o cancellare quelli esistenti.</p>
                <a nz-button routerLink="/backoffice/utenti" nzType="primary" nzSize="large" class="mt-auto home-button w-full">Vai agli Utenti</a>
            </nz-card>                
        </div>
    </div>    
</ng-container>


<ng-container *ngIf="user?.tipo != 'Amministratore'">
    <div class="flex lg:flex-row flex-col gap-4 home">
        <div class="w-full">
            <ng-template #canaleTitle>
                <h5 class="card-title">Canale <i class="bi bi-tv"></i></h5>
            </ng-template>
            <nz-card [nzTitle]="canaleTitle">
                <h6 class="card-subtitle mb-2 text-muted">Gestisci il tuo canale</h6>
                <p class="card-text">Visualizza e gestisci il tuo canale.</p>
                <a nz-button [routerLink]="'/backoffice/canali/' + user?.id_canale" nzType="primary" nzSize="large" class="mt-auto home-button w-full">Vai al tuo canale</a>
            </nz-card>               
        </div>        
    </div>
    <div class="flex lg:flex-row flex-col gap-4 mt-4 home">
        <div class="lg:w-1/2 w-full">
            <ng-template #liveCanaleTitle>
                <h5 class="card-title">Live <i class="bi bi-camera-reels-fill"></i></h5>
            </ng-template>
            <nz-card [nzTitle]="liveCanaleTitle">
                <h6 class="card-subtitle mb-2 text-muted">Gestisci i video live</h6>
                <p class="card-text">Visualizza e gestisci i video live. Puoi crearne di nuove o cancellare quelle esistenti.</p>
                <a nz-button routerLink="/backoffice/live" nzType="primary" nzSize="large" class="mt-auto home-button w-full">Vai ai Live</a>
            </nz-card>               
        </div>
        <div class="lg:w-1/2 w-full">
            <ng-template #ondemandCanaleTitle>
                <h5 class="card-title">On Demand <i class="bi bi-person-video"></i></h5>
            </ng-template>
            <nz-card [nzTitle]="ondemandCanaleTitle">
                <h6 class="card-subtitle mb-2 text-muted">Gestisci i video On Demand</h6>
                <p class="card-text">Visualizza e gestisci i video On Demand. Puoi crearne di nuove o cancellare quelle esistenti.</p>
                <a nz-button routerLink="/backoffice/ondemand" nzType="primary" nzSize="large" class="mt-auto home-button w-full">Vai agli On Demand</a>
            </nz-card>               
        </div>
    </div>    
</ng-container>