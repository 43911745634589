import { Component, OnInit } from '@angular/core';
import { StorageService } from '../../../services/storage.service';
import { UserBackoffice } from '../../../shared/interfacce.model';

@Component({
  selector: 'app-homepage-backoffice',
  templateUrl: './homepage-backoffice.component.html',
  styleUrls: ['./homepage-backoffice.component.css']
})
export class HomepageBackofficeComponent implements OnInit {

  constructor(private storageService: StorageService) {}

  user?: UserBackoffice;

  ngOnInit(): void {
    
    // Recupero l'utente loggato nel backoffice
    this.user = this.storageService.getUserBackoffice();   
  }
}
