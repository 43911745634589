
<div class="bg-image reset flex items-center lg:flex-row">                  
    <div class="lg:w-1/2 w-full lg:flex hidden"></div>
    <div class="lg:w-1/2 w-full h-full lg:px-16 p-8 flex items-center bg-light reset">
        <div class="form w-full">
                    
            <h3 class="text-4xl font-bold mb-4">Richiesta reset</h3>
            <p class="text-muted mb-4">Inserisci la tua email per richiedere il reset della password!</p>            
            <form [formGroup]="formReset" (ngSubmit)="onSubmit()" class="flex flex-col gap-4">
                <nz-alert [nzType]="alertType" nzShowIcon [nzMessage]="alertMessage" *ngIf="alertVisible"></nz-alert>      
                <div class="w-full forms-inputs"> 
                    <span>* Email</span> 
                    <input nz-input autocomplete="off" type="text" type="email" formControlName="email">
                </div>
                <div class="mt-4 text-center">
                    <button class="lg:w-2/3 w-full bottone m-0" nzType="primary" nz-button type="submit" [nzLoading]="loading" [disabled]="!formReset.valid">Recupera</button>
                    <nz-divider class="text-black" nzText="oppure"></nz-divider>
                    <button class="lg:w-2/3 w-full bottone-secondario" nz-button type="button" routerLink="/login">Torna al Login</button>
                </div>                        
            </form>
        </div>
    </div>    
</div>