<div class="bg-image contattaci flex items-center lg:flex-row">    
    <div class="lg:w-1/2 lg:flex hidden"></div>
    <div class="lg:w-1/2 w-full h-full lg:px-16 p-8 flex items-center bg-light contattaci">
        <div class="form mx-auto">
            <h3 class="text-4xl font-bold mb-4">Contattaci</h3>
            <p class="text-muted mb-8">Contattaci inserendo i tuoi dati nel form qua sottostante oppure scrivici a <a href="mailto:webtv@7live.tv">webtv&#64;7live.tv</a>!</p>
            
            <form [formGroup]="formContatti" (ngSubmit)="onLogin()" class="flex flex-col gap-4">
                <nz-alert [nzType]="alertType" nzShowIcon [nzMessage]="alertMessage" *ngIf="alertVisible"></nz-alert>
                <div class="flex lg:flex-row flex-col gap-4">
                    <div class="lg:w-1/2 w-full forms-inputs">
                        <span>*Nome e Cognome</span>
                        <input nz-input autocomplete="off" type="text" formControlName="nominativo">
                    </div>                                
                    <div class="lg:w-1/2 w-full forms-inputs">
                        <span>*Email</span>
                        <input nz-input autocomplete="off" type="email" formControlName="email">                                    
                    </div>                            
                </div>
                <div class="flex lg:flex-row flex-col gap-4">
                    <div class="lg:w-1/2 w-full forms-inputs">
                        <span>Azienda</span>
                        <input nz-input autocomplete="off" type="text" formControlName="azienda">
                    </div>                                
                    <div class="lg:w-1/2 w-full forms-inputs">                                    
                        <span>*Telefono</span>
                        <input nz-input autocomplete="off" type="tel" formControlName="telefono">
                    </div>
                </div>                                                    
                <div class="w-full forms-inputs">                                    
                    <span>Messaggio</span>
                    <textarea nz-input autocomplete="off" formControlName="messaggio" rows="6"></textarea>                                    
                </div>                        
                <div class="mt-4 text-center">
                    <button class="lg:w-2/3 w-full bottone m-0" nzType="primary" nzSize="large" nz-button type="submit" [nzLoading]="loading" [disabled]="!formContatti.valid">Contattaci</button>
                </div>                        
            </form>
        </div>
    </div>
</div>