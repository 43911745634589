import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent {
  
  constructor(private router: Router, private titleService: Title, @Inject(DOCUMENT) private document: Document) { }

  ngOnInit(): void {

    this.router.events.pipe(filter((event) => event instanceof NavigationEnd), map(() => {
      let route: ActivatedRoute = this.router.routerState.root;
      let routeTitle = '';
      while (route!.firstChild) {
        route = route.firstChild;
      }
      if (route.snapshot.data['title']) {
        routeTitle = route!.snapshot.data['title'];
      }
      return routeTitle;
    })).subscribe((title: string) => {
      if (title) {
        this.titleService.setTitle(`Seven Live Web TV - ${title}`);
      }
    });

    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        if (this.router.url.indexOf('backoffice') >= 0) {
          const head = this.document.getElementsByTagName('head')[0];
          const style = this.document.createElement('link');
          style.rel = 'stylesheet';
          style.href = `assets/css/black-theme.css`; //<--add assets

          head.appendChild(style);          

        }
      }
    });

  }

}
