<div class="lg:px-container px-8">

  <!-- LOGO MOBILE -->
  <div class="flex justify-center lg:py-4 py-8">
    <img class="lg:hidden relative z-10" src="../../../assets/images/Logo bianco.png" height="40px" alt="Logo 7live">
  </div>
  <!-- LOGO MOBILE -->

  <!-- BANNER E TITOLO -->  
  <div class="banner" [style.background-image]="copertina_url" *ngIf="copertina_url.length >0 " [@fadeInOut]>
    <div class="sfumatura"></div>
  </div>
  <h3 class="text-white font-bold lg:text-5xl text-3xl mt-8 z-10 relative testo">Canale {{canale?.nome | titlecase}}</h3>
  <p class="text-white font-semibold text-xl mt-4 testo z-10 relative">{{canale?.descrizione}}</p>  
  <!-- BANNER E TITOLO -->

  <!-- NO VIDEO -->  
  <h3 class="mt-3 d-flex justify-content-center align-items-center" *ngIf="ondemands.length <= 0 && !loading" [@fadeInOut]>Questo canale non ha video</h3>  
  <!-- NO VIDEO -->

  <!-- ONDEMANDS -->
  <div class="ondemands mb-8" *ngIf="ondemands.length > 0" [@fadeInOut]>

    <!-- EVIDENZA -->    
    <h3 class="text-white font-bold lg:text-3xl text-2xl my-8 mt-16 z-10 relative testo">Video in evidenza</h3>        
    <app-galleria-video [ondemands]="ondemands.slice(0,10)"></app-galleria-video>    
    <!-- EVIDENZA -->

    <!-- TUTTI -->    
    <h3 class="text-white font-bold lg:text-3xl text-2xl my-8 z-10 relative testo">Tutti i video</h3>    
    <div class="grid lg:grid-cols-3 md:grid-cols-2 gap-4">
      <div *ngFor="let ondemand of ondemands.slice(0,ondemandLimit);">
        <app-video-thumbnail [ondemand]="ondemand" (click)="onOndemand(ondemand.id)"></app-video-thumbnail>
      </div>
    </div>
    <!-- TUTTI -->

    <!-- BOTTONI -->
    <div class="flex flex-row mt-4">      
      
      <div [ngClass]="{'w-1/2 pe-0': ondemandLimit > 9, 'w-full': ondemandLimit <= 9}" *ngIf="ondemandLimit < ondemands.length">
        <nz-divider class="text-white ondemand-divider" [nzOrientation]="ondemandLimit <= 9 ? 'center' : 'right'" [nzText]="text">
          <ng-template #text>
            <div class="text-white button-divider" (click)="moreOndemands(6)">
              <span class="align-middle" nz-icon nzType="down" nzTheme="outline"></span>
            </div>          
          </ng-template>
        </nz-divider>
      </div>
      <div [ngClass]="{'w-1/2 ps-0': ondemandLimit > 9 && ondemandLimit < ondemands.length, 'w-full': ondemandLimit > ondemands.length}" *ngIf="ondemandLimit > 9">
        <nz-divider class="text-white ondemand-divider" [nzOrientation]="ondemandLimit > ondemands.length ? 'center' : 'left'" [nzText]="text">
          <ng-template #text>
            <div class="text-white button-divider" (click)="moreOndemands(-6)">
              <span class="align-middle" nz-icon nzType="up" nzTheme="outline"></span>
            </div>          
          </ng-template>
        </nz-divider>
      </div>

    </div>
    <!-- BOTTONI -->
  </div>
  <!-- ONDEMANDS -->
</div>