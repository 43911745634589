<!-- CANALI -->
<div class="canali">
  
  <!-- SLIDER -->
  <app-slider [loop]="false" [navigation]="false" [grabCursor]="true"
    [breakpoints]="{'0.00': {slidesPerView: 1, spaceBetween: 10}, '@0.75': {slidesPerView: 1.5, spaceBetween: 10}, '@1.00': { slidesPerView: 2.5, spaceBetween: 10 }, '@1.50': { slidesPerView: 3.5, spaceBetween: 10 }}"
    [sliderLength]="canali.length">
    <swiper-slide [lazy]="true" *ngFor="let canale of canali;" class="h-auto">
      <app-canale-thumbnail [canale]="canale" [routerLink]="'/canali/' + canale.id"></app-canale-thumbnail>
    </swiper-slide>
  </app-slider>
  <!-- SLIDER -->

  
</div>
<!-- CANALI -->